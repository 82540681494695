import {createAction} from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actionsTypes from 'app/constants/ReleaseItemConstants';
import {KINOKASSA_API_URL} from 'app/constants/SettingsConstants';

export const resetSeances = createAction(
	actionsTypes.RESET_SEANCES,
);

const getSeancesRequest = createAction(
	actionsTypes.GET_SEANCES_REQUEST,
);

const getSeancesSuccess = createAction(
	actionsTypes.GET_SEANCES_SUCCESS,
	payload => (payload),
);

const getSeancesFail = createAction(
	actionsTypes.GET_SEANCES_FAIL,
	error => ({error}),
);

export const getSeances = (releaseId, date, cityId) => async (dispatch) => {
	dispatch(getSeancesRequest());

	try {
		if (releaseId && cityId && date) {
			const seances = await httpRequestPromise(`
				${KINOKASSA_API_URL}/api/v2/release/${releaseId}/seances?city_id=${cityId}&date=${date}
			`, 'GET');

			dispatch(getSeancesSuccess({seances}));
		}
	} catch (error) {
		dispatch(getSeancesFail(error));
	}
};
