import React, {useState, useCallback} from 'react';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import CitiesListSelectPopup from 'app/components/CitySelect/CitiesListSelectPopup';
import {arrowIconDownGray, locationIcon} from 'app/constants/IconsConstants';
import classNames from 'classnames';

interface IProps {
	isDesktopView: boolean,
	showPopup: (content: {popupContentNode: JSX.Element}) => void,
	hasCurrentCityId: boolean,
	currentCityTitle: string,
}

const CitySelectComponent = ({
	isDesktopView,
	showPopup,
	hasCurrentCityId,
	currentCityTitle = '',
}: IProps) => {
	const [isCityCheckWrapperOpen, setIsOpen] = useState(!hasCurrentCityId);
	const cityCheckWrapperClassName = classNames(
		'city-select__city-check',
		'city-check-wrapper',
		{
			'city-select__city-check--mobile': !isDesktopView,
			'city-select__city-check--open': isCityCheckWrapperOpen
		},
	);

	const closeCityCheckWrapper = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleClick = useCallback(() => {
		showPopup({
			popupContentNode: <CitiesListSelectPopup className="city-select__popup-content" isOpen={true} />
		});

		if (isCityCheckWrapperOpen) {
			closeCityCheckWrapper();
		}
	}, []);

	return (
		<div className="city-select">
			<KeydownEnterButton
				className="city-select__btn-toggle"
				onClick={handleClick}
			>
				{isDesktopView ? (
					<div className="city-select__current-city-info">
						<div className="city-select__title">{i18n.t('CinemaAtCity')}</div>
						<div className="city-select__current-city">{currentCityTitle}</div>
					</div>
				) : (
					<div className="city-select__current-city city-select__current-city--mobile">
						{currentCityTitle}
					</div>
				)}
				<span className="city-select__icon">
					{isDesktopView ? arrowIconDownGray : locationIcon}
				</span>
			</KeydownEnterButton>
			<div className={cityCheckWrapperClassName}>
				<h4 className="city-check-wrapper__question-title">
					{i18n.t('YourCity')} <span>{currentCityTitle}</span>?
				</h4>
				<div className="city-check-wrapper__buttons-section">
					<KeydownEnterButton
						className="custom-button"
						onClick={closeCityCheckWrapper}
					>
						{i18n.t('Correct')}
					</KeydownEnterButton>
					<KeydownEnterButton
						className="custom-button custom-button--secondary city-check-wrapper__btn-other-city"
						onClick={handleClick}
					>
						{i18n.t('Another', {count: 1})}
					</KeydownEnterButton>
				</div>
			</div>
		</div>
	);
};

export default CitySelectComponent;
