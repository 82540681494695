import React from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import CitySearchInput from 'app/components/CitySelect/CitySearchInputComponent';
import {closeIcon} from 'app/constants/IconsConstants';
import {getBusinessDay} from 'app/selectors/AppSelectors';
import { Moment } from 'moment';

interface IProps {
	citiesData: Array<{ id: number, title: string, region: string }>,
	isMonitor: boolean,
	hidePopup: () => void,
	setCityFilter: (value: string) => void,
	saveNewCurrentCityId: (value: number) => void,
	history: ReturnType<typeof useHistory>,
	setSelectedDay: (value: Moment) => void,
}

const CitiesListSelectComponent = ({
	citiesData,
	isMonitor,
	hidePopup,
	setCityFilter,
	saveNewCurrentCityId,
	setSelectedDay,
	history: {push, location}
}: IProps & RouteComponentProps) => {
	const handleHideButtonClick = () => {
		setCityFilter('');
		hidePopup();
	};

	const handleClick = ({currentTarget}) => {
		const newCityId = Number(currentTarget.dataset.cityId);

		saveNewCurrentCityId(newCityId);
		hidePopup();
		setSelectedDay(getBusinessDay());

		if (location.pathname !== '/') {
			push(`/${!isMonitor ? newCityId : ''}`);
		}
	};

	return (
		<div className="cities-list-select">
			<div className="cities-list-select__header">
				<h1 className="text text--size-16">{i18n.t('ChooseYourCity')}</h1>
				<KeydownEnterButton
					className="cities-list-select__hide-btn"
					onClick={handleHideButtonClick}
				>
					{closeIcon}
				</KeydownEnterButton>
			</div>
			<CitySearchInput placeholder={i18n.t('Location')} setFilter={setCityFilter} />
			<div className="cities-list-select__list">
				{citiesData.map(({id, title, region}) => (
					<KeydownEnterLink
						key={id}
						tabIndex={0}
						type="plain"
						data-city-id={id}
						onClick={handleClick}
						className="cities-list-select__city-item"
					>
						<>
							<span>{title}</span>
							{region
								? (
									<span className="cities-list-select__city-item--region text text--size-14">
										{region}
									</span>
								) : null}
						</>
					</KeydownEnterLink>
				))}
			</div>
		</div>
	);
};

export default withRouter(CitiesListSelectComponent);
