import React, {lazy} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {setYaReachGoal, loadComponent} from 'app/selectors/Helpers';
import {MENU_HEIGHT} from 'app/constants/AppConstants';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import LoginButton from 'app/containers/LoginButtonContainer';

const Feedback = loadComponent(lazy(() => import('app/containers/FeedbackContainer')));

class MenuComponent extends React.Component {
	static propTypes = {
		isMobile: PropTypes.bool,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired
		}).isRequired,
		links: PropTypes.arrayOf(PropTypes.object).isRequired,
		feedback: PropTypes.bool.isRequired
	}

	state = {
		subLinks: []
	}

	componentDidMount() {
		this.setState({
			subLinks: this.getSubLinks(this.props.links)
		});

		this.menuList.addEventListener('wheel', this.preventWheel);
	}

	componentDidUpdate(prevProps) {
		const {location, links} = this.props;

		if (location.pathname !== prevProps.location.pathname) {
			this.setState({
				subLinks: this.getSubLinks(links)
			});
		}
	}

	componentWillUnmount = () => {
		this.menuList.removeEventListener('wheel', this.preventWheel);
	}

	getSubLinks = (links) => _.reduce(links, (memo, link) => {
		if (link.sub && _.some(link.sub, subLink => subLink.href === this.props.location.pathname)) {
			memo.push(...link.sub);
		}

		return memo;
	}, []);

	preventWheel = (event) => {
		if (event.pageY < this.menuList.getBoundingClientRect().top + window.scrollY + MENU_HEIGHT) {
			event.preventDefault();
		}
	}

	handleClick = (event) => {
		const link = event.target.getAttribute('href');
		const {isCustomCinemaLink, linkTitle} = event.target.dataset;

		if (link === '/') {
			setYaReachGoal('afisha-click', null, false);
		} else if (link === '/cinema') {
			setYaReachGoal('cinema-click', null, false);
		}

		if (isCustomCinemaLink) {
			setYaReachGoal('open-section', {section: linkTitle});
		}
	};

	handleMouseWheel = (event) => {
		const {scrollWidth, clientWidth} = this.menuList;

		if (
			scrollWidth > clientWidth &&
			event.pageY <= this.menuList.getBoundingClientRect().top + window.scrollY + MENU_HEIGHT
		) {
			const {currentTarget, deltaX, deltaY} = event;

			currentTarget.scrollLeft += deltaX || deltaY;
		}
	}

	render() {
		const {links, feedback, isMobile} = this.props;

		return (
			<nav className="menu menu--navigation">
				<div
					className="container menu-container"
					onWheel={this.handleMouseWheel}
					ref={(menuList) => { this.menuList = menuList; }}
				>
					{links.map((link, index) =>
						<KeydownEnterLink
							type="navLink"
							key={index}
							to={link.href}
							exact
							activeClassName="menu__item--active"
							className="menu__item"
							data-is-custom-cinema-link={link.isCustomCinemaLink}
							data-link-title={link.sub && link.sub.length ? link.sub[0].title : link.title}
							onClick={this.handleClick}
						>
							{link.title}

							{link.sub && link.sub.length && !isMobile ?
								<div className="menu-sub">
									{link.sub.map((subLink, subIndex) =>
										<KeydownEnterLink
											type="navLink"
											key={subIndex}
											to={subLink.href}
											exact
											activeClassName="menu-sub__item--active"
											className="menu-sub__item"
											data-is-custom-cinema-link={link.isCustomCinemaLink}
											data-link-title={subLink.title}
										>
											{subLink.title}
										</KeydownEnterLink>,
									)}
								</div>
								: null}
						</KeydownEnterLink>,
					)}
					{!isMobile && <LoginButton />}
					{!isMobile && feedback && <Feedback />}
				</div>
				{isMobile && !!this.state.subLinks.length && (
					<div className="menu-sub-mobile">
						{this.state.subLinks.map((subLink, subIndex) =>
							<KeydownEnterLink
								type="navLink"
								key={subIndex}
								to={subLink.href}
								exact
								activeClassName="menu-sub-mobile__item--active"
								className="menu-sub-mobile__item"
							>
								{subLink.title}
							</KeydownEnterLink>,
						)}
					</div>
				)}
			</nav>
		);
	}
}

export default withRouter(MenuComponent);
