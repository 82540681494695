import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import { Cinema } from 'app/types/common';
import { SyntheticEvent, useMemo } from 'react';

interface IProps {
	cinema: Cinema,
	addresses: {address: string | null, place: string | null, maps: string | null},
	isSeveralCinemasInCity: boolean,
	onClick: (event: SyntheticEvent) => void,
	hasTitle?: boolean,
}

function Address({
	cinema: {address = '', city = ''},
	addresses: {place = '', maps},
	isSeveralCinemasInCity,
	onClick,
	hasTitle = false,
}: IProps) {
	const cinemaMapsLink = useMemo(() => {
		let link = '';

		if (maps) {
			link = maps;
		} else {
			link = encodeURI(`https://yandex.ru/maps/?text=${[city, address].join(' ')}`);
		}

		return link;
	}, [maps, city, address]);

	return (
		<div className="address">
			{hasTitle ? <div className="text text--size-12 text--gray">{i18n.t('Address')}:</div> : null}
			{!isSeveralCinemasInCity && city
				? (
					<KeydownEnterLink
						type="plain"
						className="text text--link-accent text--size-12"
						href={cinemaMapsLink}
						target="_blank"
						rel="noopener noreferrer"
						data-icon="maps"
						onClick={onClick}
					>
						<>
							<span>{`${city}${!!place ? ` ${place}` : ''}, ${address}`}</span>
						</>
					</KeydownEnterLink>
				)
				: <span className="text text--link-accent text--size-12">{city}</span>
			}
		</div>
	);
}

export default Address;