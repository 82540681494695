import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/AppConstants';
import { IReleasesSoonReducer } from 'app/types/releasesSoonReducer';

const initialState: IReleasesSoonReducer = {
	countries: [],
	formats: [],
	genres: [],
	all_qualifiers: [],
	releasesSoon: [],
	error: {
		code: null
	},
	isFetching: false,
};

export default handleActions({
	[actions.GET_RELEASES_SOON_REQUEST]: state => ({
		...state,
		isFetching: true,
		error: {}
	}),

	[actions.GET_RELEASES_SOON_SUCCESS]: (state, action) => ({
		...state,
		...action.payload,
		isFetching: false,
		error: {}
	}),

	[actions.GET_RELEASES_SOON_FAIL]: (state, action) => ({
		...state,
		isFetching: false,
		error: action.payload.error
	})

}, initialState);
