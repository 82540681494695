import {createAction} from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actions from 'app/constants/PromoConstants';
import {KINOKASSA_API_URL} from 'app/constants/SettingsConstants';

const setPromoIsFetching = createAction(
	actions.SET_PROMO_IS_FETCHING,
);

const getPromosSuccess = createAction(
	actions.GET_PROMOS_SUCCESS,
	payload => (payload),
);

const getPromosFail = createAction(
	actions.GET_PROMOS_FAIL,
	error => ({error}),
);

const getPromoItemSuccess = createAction(
	actions.GET_PROMO_ITEM_SUCCESS,
	promoItem => ({promoItem}),
);

export const resetPromos = createAction(
	actions.RESET_PROMOS,
);

export const getPromos = (cinemaId, cityId) => async dispatch => {
	const id = cinemaId || cityId;
	dispatch(setPromoIsFetching());

	try {
		const promos = await httpRequestPromise(
			`${KINOKASSA_API_URL}/api/v2/promo?${cinemaId ? 'cinema' : 'city'}_id=${id}`,
			'GET'
		);

		dispatch(getPromosSuccess(promos));
	} catch (error) {
		dispatch(getPromosFail(error));
	}
};

export const getPromoItem = (promoId) => async dispatch => {
	dispatch(setPromoIsFetching());

	try {
		const response = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/promo/${promoId}`, 'GET');

		dispatch(getPromoItemSuccess(response));
	} catch (error) {
		dispatch(getPromosFail(error));
	}
};
