import { getBusinessDay, getSelectedDay } from "app/selectors/AppSelectors";
import { Moment } from "moment";
import classnames from 'classnames';
import { FILTER_PANEL_ELEMENTS } from "../types";
import { useRef } from "react";
import { formatMoment } from "app/selectors/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getActiveElementId } from "app/modules/filters/redux/selectors";
import { updateSelectedDay } from 'app/actions/AppActions';
import { setActiveElementId } from "../redux/actions";

interface IProps {
	seanceDates?: string[];
	isPickerDisabled?: boolean;
	onDateChange?: (date: string) => void;
}

export const DatePicker = ({
	seanceDates,
	isPickerDisabled,
	onDateChange,
}: IProps) => {
	const dispatch = useDispatch();

	const selectedDay = useSelector(getSelectedDay)
	const activeElementId = useSelector(getActiveElementId)

	const ref = useRef<HTMLDivElement | null>(null);
	const currentBusinessDayRef = useRef(getBusinessDay());
	const nextBusinessDayRef = useRef(getBusinessDay(1));

	const getDateClasses = (day: Moment | null, isSelected?: boolean, isCalendarButton?: boolean) => classnames(
		"date-picker__date-element",
		isCalendarButton && "date-picker___calendar-button",
		!isPickerDisabled && {
			"date-picker__date-element--disabled": !isCalendarButton && day && !seanceDates?.includes(formatMoment(day)),
			"date-picker__date-element--selected": isSelected || day?.isSame(selectedDay, "day") && activeElementId !== FILTER_PANEL_ELEMENTS.CALENDAR
		}
	);

	const handleTodayTommorowClick = (day: Moment) => {
		dispatch(setActiveElementId());

		if (day.isSame(selectedDay)) return;

		dispatch(updateSelectedDay(day));
		onDateChange?.(formatMoment(day));
	};

	const isPopupActive = activeElementId === FILTER_PANEL_ELEMENTS.CALENDAR;
	const isCalendarButtonActive = selectedDay?.isAfter(nextBusinessDayRef.current);

	const handleSelectDayClick = () => {
		dispatch(setActiveElementId(isPopupActive ? null: FILTER_PANEL_ELEMENTS.CALENDAR));
	};

	return (
		<div
			ref={ref}
			className={classnames("date-picker", {
				"date-picker--disabled": isPickerDisabled
			})}
			data-filter-id={FILTER_PANEL_ELEMENTS.CALENDAR}
		>
			<div
				className={getDateClasses(currentBusinessDayRef.current)}
				onClick={() => handleTodayTommorowClick(currentBusinessDayRef.current)}
			>
				<span>{i18n.t("Today")}</span>
			</div>
			<div
				className={getDateClasses(nextBusinessDayRef.current)}
				onClick={() => handleTodayTommorowClick(nextBusinessDayRef.current)}
			>
				<span>{i18n.t("Tommorow")}</span>
			</div>
			<div
				className={getDateClasses(
					null,
					isCalendarButtonActive || isPopupActive,
					true
				)}
				onClick={handleSelectDayClick}
			>
				<span className='text-with-placeholder'>
					<span className='text-with-placeholder__text'>{isCalendarButtonActive ? selectedDay?.format("DD MMM") : i18n.t("SelectDay")}</span>
					<span className='text-with-placeholder__placeholder'>{i18n.t("SelectDay")}</span>
				</span>
			</div>
		</div>
	)
}