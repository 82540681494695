import {connect} from 'react-redux';
import {getCurrentCityTitle, getCurrentCityId, getCurrentCinemas} from 'app/selectors/AppSelectors';
import {showPopup, hidePopup} from 'app/actions/AppActions';
import CitySelectComponent from 'app/components/CitySelect/CitySelectComponent';
import KinokassaCitySelect from 'app/components/CitySelectNew/CitySelectComponent';

const mapStateToProps = state => ({
	currentCityTitle: getCurrentCityTitle(state),
	hasCurrentCityId: !!getCurrentCityId(state),
	cinemasForCurrentCity: getCurrentCinemas(state)
});

export default {
	Default: connect(mapStateToProps, {showPopup, hidePopup})(CitySelectComponent),
	Kinokassa: connect(mapStateToProps, {showPopup, hidePopup})(KinokassaCitySelect)
}
