import * as style from 'css/release-poster.module.scss';
import { memo } from 'react';
import cn from 'classnames';
import Flex from 'app/ui/Flex/Flex';
import { getContrastColorForAcccent } from 'app/selectors/Helpers';
import { Qualifiers } from 'app/types/releases';
import { useSelector } from 'react-redux';
import { getWithNewDesign } from 'app/selectors/AppSelectors';
import ReleaseQualifiersWrapper from 'app/components/ReleaseQualifiersWrapper';

interface IProps {
	poster: string;
	isSoon?: boolean;
	withBadges?: boolean;
	withProCultureBadge?: boolean;
	isShowOnSale?: boolean;
	isShowAgeRating?: boolean;
	ageRating?: string;
	qualifiers?: Qualifiers[];
	isAfisha?: boolean;
	isReleasePage?: boolean;
}

function ReleasePoster({
	poster,
	isSoon = false,
	withProCultureBadge,
	isShowOnSale = false,
	isShowAgeRating = false,
	ageRating = '',
	qualifiers,
	isAfisha,
	isReleasePage,
	withBadges,
}: IProps) {
	const withNewDesign = useSelector(getWithNewDesign);
	const gradientClass = `gradient_${_.random(1, 8)}`;
	const onSaleLabelTextColor = withNewDesign ? getContrastColorForAcccent() : '';
	const posterClass = cn(['release-poster', style.releasePoster, gradientClass], {
		[style.releaseItemKinokassa]: withNewDesign && isReleasePage,
		[style.releasePosterSoon]: isSoon,
		[style.releasePosterOld]: !withNewDesign && !isSoon,
	});

	return (
		<Flex classes={posterClass} justifyContent="center" alignItems="center">
			{isAfisha ? <div className="releases-item__poster-height" /> : null}
			<div className={`${style.posterWrapper}`}>
				{poster && <img
					src={poster}
					data-src={poster}
					data-name="poster"
					className={cn('lazyload', [style.posterImg], {
						[style.afishaPosterImg]: isAfisha,
						[style.releaseItemPosterImg]: !isAfisha
					})}
					alt={i18n.t('ReleasePoster')}
				/>}
			</div>
			{isShowAgeRating ? <div className="release__age text text--size-15">{ageRating}</div> : null}
			{withBadges
				? (
					<ReleaseQualifiersWrapper
						classes='releases-item__badge text text--size-12 releases-item__badge--desktop'
						qualifiers={qualifiers || []}
						withProCultureBadge={withProCultureBadge}
					/>
				) : null}
			{isShowOnSale
				? (
					<div className={`releases-item__sale ${style.posterOnSaleLabel}`} style={{color: onSaleLabelTextColor}}>
						{i18n.t('TicketsAtSold').toUpperCase()}
					</div>
				) : null}
		</Flex>
	)
}

export default memo(ReleasePoster);