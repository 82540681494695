import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentAfishaSelector } from 'app/selectors/AfishaSelectors';
import { getAllReleasesSoon } from 'app/selectors/ReleasesSoonSelectors';
import NoSeancesComponent from 'app/components/NoSeancesComponent';
import AfishaErrorContainer from 'app/containers/errors/AfishaErrorContainer';
import ReleaseItemComponent from 'app/components/ReleaseItemComponent';
import SpinnerComponent from 'app/components/SpinnerComponent';
import GosuslugiAfishaComponent from 'app/components/additionalModules/GosuslugiAfishaComponent';
import Menu from 'app/containers/MenuContainer';
import AfishaAdditionalSections from 'app/components/afisha/AfishaAdditionalSections';
import { gosuslugiOnAfishaData } from 'app/constants/AppConstants';
import { SOCHI_CINEMA_ID } from 'app/constants/CinemaConstants';

const DefaultAfishaViewComponent = ({
	page,
	isProCulture,
	isFetching,
	isImax,
	isVip,
	isNotMovie,
	seanceDates,
	handleChangeDate,
}: {
	page: string;
	isFetching: boolean;
	isProCulture: boolean | undefined;
	isImax: boolean | undefined;
	isVip: boolean | undefined;
	isNotMovie: boolean | undefined;
	seanceDates: string[];
	handleChangeDate: (date: string) => void;
}) => {
	const { pathname } = useLocation();

	const afisha = useSelector(getCurrentAfishaSelector(isProCulture));
	const hasReleasesSoon= useSelector(getAllReleasesSoon).length;
	const isEnabledGosuslugiOnAfisha = !_.isEmpty(gosuslugiOnAfishaData) && !isImax && !isVip && !isProCulture && !isNotMovie;

	return (
		<AfishaErrorContainer>
			<div className="page">
				<Menu.Calendar
					isPlanetarium={false}
					seanceDates={seanceDates}
					onClick={handleChangeDate}
					hasReleasesScrollButton={!!hasReleasesSoon && !isProCulture && !isNotMovie}
				/>
				<div className="releases-list">
					{isEnabledGosuslugiOnAfisha && window.kinositeSettings.cinemaId !== SOCHI_CINEMA_ID && (
						<GosuslugiAfishaComponent pathname={pathname} />
					)}
					{!isFetching ?
						<div className="container releases-container">
							{afisha.length ?
								afisha.map((release, index) =>
									<ReleaseItemComponent release={release} key={index} page={page} isImax={isImax} isVip={isVip} />,
								) : (
									<NoSeancesComponent />
								)
							}
						</div>
						:
						<SpinnerComponent />
					}
				</div>
				{isEnabledGosuslugiOnAfisha && window.kinositeSettings.cinemaId === SOCHI_CINEMA_ID && (
					<GosuslugiAfishaComponent pathname={pathname} />
				)}
				{!(isVip || isImax || isProCulture || isNotMovie) && <AfishaAdditionalSections page={page} />}
			</div>
		</AfishaErrorContainer>
	)
}

export default DefaultAfishaViewComponent;