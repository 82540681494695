import { NoSeancesReason } from 'app/types/seances';
import React from 'react';

const NoSeancesComponent = ({type = NoSeancesReason.DEFAULT} : {type?: NoSeancesReason}) => {
	let message = i18n.t('SeancesForTodayAreMissing');
	let image = "/img/no-seanses.svg";

	switch (type) {
		case NoSeancesReason.NO_SEANCES_FOR_SELECTED_CINEMA:
			message = i18n.t('SeancesForSelectedCinemaAreMissing');
			break;
		case NoSeancesReason.NO_SEANCES_FOR_SELECTED_FILTERS:
			message = i18n.t('SeancesForCurrentFiltersAreMissing');
			break;
		case NoSeancesReason.NO_SEANCES_SOON:
				message = i18n.t('SeancesAreMissing');
				break;
	}

	return (
		<div className="no-seanses">
			<img src={image} alt={message} />
			<p className="no-seanses__title">{message}</p>
		</div>
	)
};

export default NoSeancesComponent;
