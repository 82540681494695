import classnames from "classnames";
import { FilterValueType, IFilterValue, IFiltersState, MULTISELECTABLE_FILTERS } from "../types";
import { FilterValue } from "app/modules/filters/components/FilterValue";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDataForFilterValuesList } from "app/selectors/AppSelectors";
import { IReduxState } from "app/types/state";
import { updateFilterValue, applyFilters, setActiveElementId } from "../redux/actions";

interface IProps {
	filterKey: keyof IFiltersState["selectedValues"];
	valueType?: FilterValueType;
	withApplyButton?: boolean;
	hasNeedRowFlexDirection?: boolean;
}

export const FilterValuesList = ({
	filterKey,
	valueType = FilterValueType.LOCAL,
	withApplyButton,
	hasNeedRowFlexDirection,
}: IProps) => {
	const dispatch = useDispatch();

	const {values, selectedValues, allButtonTitle} = useSelector((state: IReduxState) => (
		getDataForFilterValuesList(state, filterKey, valueType)
	), shallowEqual);

	const handleAddAllClick = () => {
		dispatch(updateFilterValue(filterKey, null, valueType))
	};

	const handleValueClick = (value: IFilterValue) => {
		dispatch(updateFilterValue(filterKey, value, valueType, values.length))
	}

	const handleApplyClick = () => {
		batch(() => {
			dispatch(applyFilters([filterKey], FilterValueType.LOCAL));
			dispatch(setActiveElementId());
		})
	}

	if (!values.length) return null;

	const isAllSelected = !selectedValues

	return (
		<div className="filter-values-list-wrapper">
			<div className={classnames("filter-values-list", {
				"filter-values-list--column": hasNeedRowFlexDirection
			})}>
				{
					MULTISELECTABLE_FILTERS.includes(filterKey as any) && (
						<FilterValue
							onClick={handleAddAllClick}
							value={null}
							isActive={isAllSelected}
							title={allButtonTitle}
							size="normal"
						/>
					)
				}
				{
					values.map((value) => (
						<FilterValue
							key={value.id}
							onClick={handleValueClick}
							value={value}
							isActive={!!selectedValues?.[value.id]}
							title={value.title}
							size="normal"
						/>
					))
				}
			</div>
			{withApplyButton && <button className="filters-apply-button" onClick={handleApplyClick}>{i18n.t("Apply")}</button>}
		</div>
	)
}