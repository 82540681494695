import { IInput } from "app/types/refund";
import cn from "classnames";
import { ChangeEvent, memo } from "react";

type Props = IInput<string> & { id?: string, className?: string, isDisabled?: boolean };

function Input({
	name,
	value,
	onChange,
	type,
	label,
	id,
	isDisabled,
	validators = {},
	placeholder,
	autoFocus,
	required,
	maxLength,
	className,
}: Props) {
	const { errors, hasWarnings, isSuccess } = validators;
	const hasError = errors.hasOwnProperty(name) && errors[name].length;
	const classnames = cn('input input-refund', className, {
		'input--error input-refund--error': hasError,
		'input--warning input-refund--warning': hasWarnings,
		'input--success input-refund--success': isSuccess,
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChange(event);
	};

	return (
		<div className={classnames}>
			{label && id && (
				<label className="input__label input-refund__label" htmlFor={id}>
					{label}&nbsp;{required && <span>*</span>}
				</label>
			)}
			<div className="input__field-wrap input-refund__field-wrap">
				<input
					className="input__field input-refund__field"
					type={type}
					name={name}
					placeholder={placeholder}
					id={id}
					disabled={isDisabled || isSuccess}
					onChange={handleChange}
					value={value}
					autoFocus={autoFocus}
					required={required}
					maxLength={maxLength}
				/>
			</div>
			{hasError && errors[name].map(({ message }, key) => (
				<div key={`${key}${name}`} className="input__message input-refund__message">
					{message}
				</div>
			))}
		</div>
	);
}

export default memo(Input);
