import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/AppConstants';
import { IAfishaReducer } from 'app/types/afishaReducer';

const initialState: IAfishaReducer = {
	afisha: null,
	countries: [],
	formats: [],
	genres: [],
	persons: [],
	calendar: {
		seance_dates: []
	},
	cinemas: [],
	discounts: [],
	seanceDisplaySettings: {
		buy: false,
		format: false,
		hall: false,
		price: false,
		vip_badge: false
	},
	error: {
		code: null
	},
	isAfishaFetching: true,
	isAfishaInfoFetching: true,
	all_qualifiers: []
};

export default handleActions({
	[actions.GET_AFISHA_REQUEST]: state => ({
		...state,
		isAfishaFetching: true,
		afisha: null,
		error: {
			code: null
		}
	}),

	[actions.GET_AFISHA_SUCCESS]: (state, action) => ({
		...state,
		...action.payload,
		isAfishaFetching: false
	}),

	[actions.GET_AFISHA_INFO_REQUEST]: state => ({
		...state,
		isAfishaInfoFetching: true,
		error: {
			code: null
		}
	}),

	[actions.GET_AFISHA_INFO_SUCCESS]: (state, action) => ({
		...state,
		...action.payload,
		isAfishaInfoFetching: false
	}),

	[actions.SET_SEANCE_DISPLAY_SETTINGS]: (state, action) => ({
		...state,
		...action.payload
	}),

	[actions.SET_AFISHA_ERROR]: (state, {payload: {code}}) => ({
		...state,
		isAfishaInfoFetching: false,
		error: {
			...state.error,
			code
		}
	}),

	[actions.SET_QUALIFIERS]: (state, {payload}) => ({
		...state,
		all_qualifiers: payload
	})

}, initialState);
