import React from 'react';
import PropTypes from 'prop-types';

const withScroll = (Component) => {
	const WrappedComponent = ({targetElementId, children, ...props}) => {
		const handleClick = () => {
			const targetElement = document.getElementById(targetElementId);

			if (targetElement) {
				window.scrollTo({
					top: targetElement.offsetTop,
					behavior: 'smooth'
				});
			}
		};

		return (
			<Component
				onClick={handleClick}
				{...props}
			>
				{children}
			</Component>
		);
	};

	WrappedComponent.propTypes = {
		targetElementId: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired
	};

	return WrappedComponent;
};

withScroll.propTypes = {
	Component: PropTypes.element
};

export default withScroll;
