import {createAction} from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actionsTypes from 'app/constants/AppConstants';
import {KINOKASSA_API_URL} from 'app/constants/SettingsConstants';

export const setAdvertisements = createAction(
	actionsTypes.SET_ADVERTISEMENTS,
	payload => (payload),
);

export const resetAdvertisements = createAction(
	actionsTypes.RESET_ADVERTISEMENTS,
);

export const getAdvertisements = (cinemaId, cityId) => async dispatch => {
	try {
		const advertisements = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/banners?${
			cinemaId ? `cinema_id=${cinemaId}` : `city_id=${cityId}`
		}`, 'GET');

		dispatch(setAdvertisements({advertisements}));
	} catch (error) {
		dispatch(setAdvertisements({advertisements: []}));
	}
};
