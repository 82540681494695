import { FILTER_PANEL_ELEMENTS, FilterValueType } from "../types";
import { ToggleWithText } from "app/modules/filters/components/ToggleWithText";
import { FilterValuesList } from "app/modules/filters/components/FilterValuesList";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentMobileFilterPopupElements } from "app/selectors/AppSelectors";
import { getPushkinCardLocalFilterValue } from "app/modules/filters/redux/selectors";
import { applyFilters, updateFilterValue, setActiveElementId } from "../redux/actions";
import { useCallback } from "react";

export const AllFiltersPopup = () => {
	const dispatch = useDispatch();

	const filters = useSelector(getCurrentMobileFilterPopupElements)
	const hasNeedPushkinCard = useSelector(getPushkinCardLocalFilterValue)

	const handleApplyClick = () => {
		dispatch(applyFilters(filters, FilterValueType.LOCAL));
		dispatch(setActiveElementId());
	}

	const updatePushkinCardFilter = useCallback(() => {
		dispatch(updateFilterValue(FILTER_PANEL_ELEMENTS.PUSHKIN_CARD, {id: !hasNeedPushkinCard}, FilterValueType.LOCAL));
	}, [])

	return (
		<div className="mobile-filters-popup">
			<div className="mobile-filters-popup__filters-list">
				{
					filters.map((filterKey) => {
						let component: React.ReactNode;
						let title: string;

						switch (filterKey) {
							case FILTER_PANEL_ELEMENTS.PUSHKIN_CARD:
								component = (
									<ToggleWithText
										title={i18n.t("PushkinCard")}
										isActive={hasNeedPushkinCard}
										onChange={updatePushkinCardFilter}
									/>
								);
								title = i18n.t("Various");
								break;
							default:
								component = (
									<FilterValuesList
										filterKey={filterKey as any}
										valueType={FilterValueType.LOCAL}
									/>
								)
								title = i18n.t(`FiltersTitles.${filterKey}`);
						}

						return (
							<div key={filterKey} className="mobile-filters-popup__filter">
								<div className="mobile-filters-popup__filter-title">{title}</div>
								{component}
							</div>
						)
					})
				}
			</div>
			<button className="filters-apply-button" onClick={handleApplyClick}>{i18n.t("Apply")}</button>
		</div>
	)
}