import { createSelector } from 'reselect';
import { IReduxState } from 'app/types/state';

export const getCustomPages = (state: IReduxState) => state.menuReducer.customPages;
export const getPageContent = (state: IReduxState) => state.menuReducer.pageContent;
export const getIsPageFetching = (state: IReduxState) => state.menuReducer.isFetching;

export const getCustomPagesRoutes = createSelector(
	getCustomPages,
	pages => _.flatten(pages.map(page => page.sub && page.sub.length > 1 ? page.sub : page)),
);
