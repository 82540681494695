import { ConnectedProps, connect } from 'react-redux';
import { hidePopup } from 'app/actions/AppActions';
import { IReduxState } from 'app/types/state';
import { getPopupOptions } from 'app/selectors/AppSelectors';
import PopupComponent from 'app/components/PopupComponent';

const mapStateToProps = (state: IReduxState) => ({
	...(getPopupOptions(state))
})

const connector = connect(mapStateToProps,  {hidePopup});

export type PopupComponentProps = ConnectedProps<typeof connector> & {
	className?: string;
	children?: React.ReactNode;
}

export default connector(PopupComponent);
