import React, { useEffect } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import { scrollToTop, setYaReachGoal } from 'app/selectors/Helpers';
import { News } from 'app/types/common';

type Props = RouteComponentProps<{cinemaId: string}> & {
	news: News[],
	prefix: string,
	page: string,
};

const NewsListComponent = ({
	news,
	prefix,
	match: {
		params,
	},
	page,
}: Props) => {
	useEffect(() => {
		scrollToTop();
	}, []);

	const handleAnalyticsClick = () => {
		setYaReachGoal('open-news', {section: page});
	};

	return (
		<div className="news-list">
			{news.map(({id, title, description, image, created_date_time: createdDateTime}) => {
				const link = params.cinemaId ? `${prefix}/cinemas/${params.cinemaId}/news/${id}` : `${prefix}/news/${id}`;

				return (
					<div className="news-item" key={id}>
						<div className="news-item__column-left">
							<div
								className="
							news-item__created
							news-item__row
							news-item__row--mobile"
							>
								{moment(createdDateTime).format(
									`D MMMM${moment(createdDateTime).isSame(moment(), 'year') ? '' : ' YYYY'} в H:mm`,
								)}
							</div>
							<KeydownEnterLink
								to={link}
								className="news-item__title news-item__row news-item__row--mobile"
								onClick={handleAnalyticsClick}
							>
								{title}
							</KeydownEnterLink>
							<div
								className={`
								news-item__image-wrapper
								gradient_${_.random(1, 8)}
							`}
							>
								<Link
									tabIndex={-1}
									to={link}
									className="news-item__image"
									onClick={handleAnalyticsClick}
								>
									{image?.small && <img
										src={image.small}
										className="promo-item__poster-img"
										alt={i18n.t('NewsPoster')}
									/>}
								</Link>
							</div>
						</div>
						<div className="news-item__column-right">
							<div
								className="
							news-item__created
							news-item__row
							news-item__row--desktop"
							>
								{moment(createdDateTime).format(
									`D MMMM${moment(createdDateTime).isSame(moment(), 'year') ? '' : ' YYYY'} в H:mm`,
								)}
							</div>
							<KeydownEnterLink
								to={link}
								className="news-item__title news-item__row news-item__row--desktop"
								onClick={handleAnalyticsClick}
							>
								{title}
							</KeydownEnterLink>
							<div className="news-item__description news-item__row">
								{description}
							</div>
							<KeydownEnterLink
								to={link}
								className="news-item__more news-item__row"
								onClick={handleAnalyticsClick}
							>
								{`${i18n.t('ReadNext')}...`}
							</KeydownEnterLink>
						</div>
						<div className="news-item__hr" />
					</div>
				);
			})}
		</div>
	);
};

export default withRouter(NewsListComponent);
