import { ReduxTypes } from 'app/store/redux';
import { ISeance } from 'app/types/seances';
import * as types from 'app/constants/ReleaseItemConstants';
import * as actions from 'app/actions/ReleaseItemActions';

type ActionsTypes = ReturnType<ReduxTypes.InferValueTypes<typeof actions>>;

export interface IReleaseItemReducer {
	seances: ISeance[];
	error: Record<string, never>;
	isFetching: boolean;
}

const initialState: IReleaseItemReducer = {
	seances: [],
	error: {},
	isFetching: false,
};

export default function releaseItemReducer(
	state = initialState,
	action: ActionsTypes,
): IReleaseItemReducer {
	switch (action.type) {
		case types.GET_SEANCES_REQUEST:
			return {
				...state,
				isFetching: true,
				error: {},
			};
		case types.GET_SEANCES_SUCCESS:
			return {
				...state,
				...action.payload,
				isFetching: false,
			};
		case types.GET_SEANCES_FAIL:
			return {
				...state,
				isFetching: false,
				error: action.payload.error,
			};
		case types.RESET_SEANCES: () => initialState

		default:
			return state;
	}
}
