import {connect} from 'react-redux';
import { getReleaseSelector, getSeanceDates } from 'app/selectors/ReleaseSelectors';
import ReleasePageComponent from 'app/components/ReleasePageComponent';
import ReleasePage from 'app/components/release_page/ReleasePage';
import { getCurrentCityId } from 'app/selectors/AppSelectors';

const mapStateToProps = (state) => ({
	release: getReleaseSelector(state),
	seanceDates: getSeanceDates(state),
	cityId: getCurrentCityId(state),
});

export default {
	Main: connect(mapStateToProps)(ReleasePageComponent),
	Kinokassa: connect(mapStateToProps)(ReleasePage),
};