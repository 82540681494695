import React from "react";
import Calendar from "app/modules/filters/components/Calendar";
import { Moment } from "moment";
import { FILTER_PANEL_ELEMENTS, FilterPanelElementsType, FilterValueType } from "app/modules/filters/types";
import { AllFiltersPopup } from "app/modules/filters/components/AllFiltersPopup";
import { FilterValuesList } from "app/modules/filters/components/FilterValuesList";

export const getCurrentPopup = (
	activeElementId: FilterPanelElementsType | null,
	seanceDates?: string[],
	onDateChange?: (date: string) => void,
) => {
	let content: React.ReactNode | null = null;
	let title: string | null = null;

	switch (activeElementId) {
		case FILTER_PANEL_ELEMENTS.CALENDAR:
		case FILTER_PANEL_ELEMENTS.CALENDAR_MOBILE:
			content = <Calendar seanceDates={seanceDates} onDateChange={onDateChange}/>;
			title = i18n.t("Calendar");
			break;
		case FILTER_PANEL_ELEMENTS.MOBILE_ALL_FILTERS:
			content = <AllFiltersPopup/>;
			title = i18n.t("Filters")
			break;
		case FILTER_PANEL_ELEMENTS.SORTING:
			content = (
				<FilterValuesList
					filterKey={activeElementId}
					valueType={FilterValueType.MAIN}
					hasNeedRowFlexDirection
				/>
			);
			break;
		case FILTER_PANEL_ELEMENTS.AGE:
		case FILTER_PANEL_ELEMENTS.GENRE:
		case FILTER_PANEL_ELEMENTS.FEATURES:
		case FILTER_PANEL_ELEMENTS.CINEMA:
			content = (
				<FilterValuesList
					filterKey={activeElementId}
					valueType={FilterValueType.LOCAL}
					withApplyButton
				/>
			)

			if (activeElementId === FILTER_PANEL_ELEMENTS.GENRE) {
				title = i18n.t("Genres");
			}
			break;
	}

	return {
		content,
		title
	}
};

export const getMonthByDay = (relatieDay: Moment | null, targetDay: Moment | null) => {
	if (!relatieDay || !targetDay) return -1;

	return targetDay.month() - relatieDay.month() + (targetDay.year() - relatieDay.year()) * 12
};