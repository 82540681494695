import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Textarea extends PureComponent {
	static propTypes = {
		classes:     PropTypes.string,
		value:       PropTypes.string,
		name:        PropTypes.string,
		placeholder: PropTypes.string,
		isDisabled:  PropTypes.bool,
		rows:        PropTypes.number,
		maxLength:   PropTypes.number,
		wrap:        PropTypes.string,
		autoFocus:   PropTypes.bool,
		onChange:    PropTypes.func.isRequired,
		onKeyPress:  PropTypes.func,
		width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		isValid:     PropTypes.bool
	};

	static defaultProps ={
		classes:     '',
		value:       '',
		name:        '',
		placeholder: '',
		rows:        1,
		maxLength:   null,
		wrap:        'soft',
		autoFocus:   false,
		isDisabled:  false,
		width:       'initial',
		isValid:     true
	};

	handleChange = (event) => {
		const {onChange, name} = this.props;
		const {value} = event.currentTarget;

		if (name) {
			onChange({name, value}, event);
		} else {
			onChange(value, event);
		}
	};

	handleKeyPress = (event) => {
		const {onKeyPress} = this.props;
		const {value} = event.currentTarget;

		if (onKeyPress) {
			onKeyPress(value, event);
		}
	};

	render() {
		const {placeholder, value, rows, maxLength, wrap, classes, isDisabled, autoFocus, width, isValid} = this.props;
		const textareaClass = classNames({
			'textarea': true,
			'textarea--disabled': isDisabled,
			'textarea--has-error': !isValid
		}, classes);

		return (
			<textarea
				ref={el => { this.textareaRef = el; }}
				className={textareaClass}
				style={{width}}
				placeholder={placeholder}
				value={value}
				rows={rows}
				maxLength={maxLength}
				wrap={wrap}
				onChange={isDisabled ? null : this.handleChange}
				onKeyPress={isDisabled ? null : this.handleKeyPress}
				disabled={isDisabled}
				autoFocus={autoFocus}
			/>
		);
	}
}
