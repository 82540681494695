import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/FeedbackConstants';

const initialState = {
	error: {}
};

export default handleActions({
	[actions.POST_FEEDBACK_FAIL]: (state, action) => ({
		...state,
		error: action.payload.error
	})
}, initialState);
