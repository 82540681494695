import {connect} from 'react-redux';
import SeanceItemComponent from 'app/components/SeanceItemComponent';
import {getSeanceDisplaySettings} from 'app/selectors/AfishaSelectors';
import {getCurrencySymbol, getIsMonitor} from 'app/selectors/AppSelectors';

const mapStateToProps = state => ({
	seanceDisplaySettings: getSeanceDisplaySettings(state),
	currencySymbol: getCurrencySymbol(state),
	isMonitor: getIsMonitor(state)
});

export default connect(mapStateToProps)(SeanceItemComponent);
