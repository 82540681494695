import { useState } from "react";
import classNames from 'classnames';
import { ILawDocumentLink } from "./helpers";
import * as styles from "css/LawDocuments.module.scss";

const LawDocumentsPopup = ({
	lawDocsLinks,
	initialActiveLink
}: {
	lawDocsLinks: ILawDocumentLink[] | null;
	initialActiveLink: ILawDocumentLink;
}) => {
	const [activeLink, setActiveLink] = useState(initialActiveLink);

	return (
		<div className={`${styles.lawDocumentsPopup} popup__content popup--flex`}>
			<div className={`${styles.lawDocumentsPopupMenu}`}>
				{
					lawDocsLinks?.map((link) => {
						return (
							<a
								onClick={() => setActiveLink(link)}
								key={link.menuControlTitle}
								className={classNames(`${styles.lawDocumentsPopupMenuControl}`, {
									[`${styles.lawDocumentsPopupMenuControlActive}`]: activeLink === link
								})}
							>
								{link.menuControlTitle}
							</a>
						)
					})
				}
			</div>
			{activeLink.Component}
		</div>
	)
};

export {LawDocumentsPopup}
