import { IReduxState } from 'app/types/state';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { getAfishaErrorCode } from 'app/selectors/AfishaSelectors';
import { getCurrentCityTitle, getAddresses } from 'app/selectors/AppSelectors';
import { setAfishaError } from 'app/actions/AfishaActions';

const mapStateToProps = (state: IReduxState) => ({
	errorCode: getAfishaErrorCode(state),
	currentCityTitle: getCurrentCityTitle(state),
	address: getAddresses(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	setError: (errorCode) => setAfishaError(errorCode),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
