import React from 'react';
import PropTypes from 'prop-types';

const KeydownEnterButtonComponent = ({
	onClick,
	children,
	isDisabled = false,
	...buttonProps
}) => {
	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			onClick(event);
		}
	};

	return (
		<button
			onClick={onClick}
			disabled={isDisabled}
			onKeyDown={handleKeyDown}
			{...buttonProps}
		>
			{children}
		</button>
	);
};

KeydownEnterButtonComponent.propTypes = {
	onClick: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	isDisabled: PropTypes.bool
};

export default KeydownEnterButtonComponent;
