import React, { memo } from "react";
import getReleaseQualifiersContants from "app/constants/ReleaseConstants";
import { getContrastColorForAcccent, getReleaseAllQualifiersMap } from "app/selectors/Helpers";
import { Qualifiers } from "app/types/releases";
import cn from "classnames";
import * as style from 'css/release-badge.module.scss';
import {getIsMonitor, getWithNewDesign} from "app/selectors/AppSelectors";
import {useSelector} from "react-redux";

interface IProps {
	qualifiers: Qualifiers[],
	isReleasePage?: boolean;
}

const ReleaseQualifiers = ({qualifiers, isReleasePage}: IProps) => {
	const isMonitor = useSelector(getIsMonitor);
	const withNewDesign = useSelector(getWithNewDesign);

	const releaseQualifiersContants = getReleaseQualifiersContants();
	const colorText = !isReleasePage && !isMonitor && withNewDesign ? getContrastColorForAcccent() : '';
	const classes = cn('text text--size-12', {
		'releases-item__premiere': !isReleasePage,
		[`${style.releaseBadge} ${style.badgeOpacityAccent}`]: isReleasePage,
	});

	const allQualifiers = getReleaseAllQualifiersMap(qualifiers);

	return (
		<>
			{Object.keys(releaseQualifiersContants).map(key => allQualifiers[key]
				? <div key={key} className={classes} style={{color: colorText}}>
					{releaseQualifiersContants[key]}
				</div>
			: null)}
		</>
	);
}

export default memo(ReleaseQualifiers);
