import { connect } from 'react-redux';
import FooterComponent from 'app/components/FooterComponent';

import { setSemiblind, setFontSize, showPopup } from 'app/actions/AppActions';
import {
	getCinema,
	getMenuLinks,
	getApps,
	getSocials,
	getFooterPhones,
	getIsSemiblind,
	getAddresses,
	getIsSeveralCinemasInCity,
	prepareSeveralCinemasFooterLinks,
	getCurrentCityTitle,
	getCurrentCinemaId,
	getContactsPhones,
	getCinemaOpeningHours,
	getCinemaEmail,
	getAppsForMonitor,
	getIsMonitor,
	getIsCharlie,
	getRoutePrefix, getIsArman3D,
	getIsKinokassa,
	getKinokassaMenuLinks,
} from 'app/selectors/AppSelectors';
import Footer from 'app/components/footer/Footer';

const mapStateToProps = state => {
	const isMonitor = getIsMonitor(state);
	const isCharlie = getIsCharlie(state);
	const isKinokassa = getIsKinokassa(state);
	const cinema = getCinema(state);

	const selectAppLinks = isKinokassa ? cinema.app : getApps(state);
	const apps = isMonitor || isCharlie ? getAppsForMonitor(state) : selectAppLinks;

	return {
		cinema,
		links: !isKinokassa ? getMenuLinks(state) : getKinokassaMenuLinks(state),
		apps,
		phones: getFooterPhones(state),
		phonesWithInfo: getContactsPhones(state),
		socials: getSocials(state),
		isSemiblind: getIsSemiblind(state),
		addresses: getAddresses(state),
		isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
		severalCinemasFooterLinks: prepareSeveralCinemasFooterLinks(state),
		currentCity: getCurrentCityTitle(state),
		cinemaID: getCurrentCinemaId(state),
		cinemaOpeningHours: getCinemaOpeningHours(state),
		cinemaEmail: getCinemaEmail(state),
		isMonitor,
		isCharlie,
		prefix: getRoutePrefix(state),
		isArman3D: getIsArman3D(state),
		isKinokassa: getIsKinokassa(state),
	};
};

export type FooterProps = ReturnType<typeof mapStateToProps> & {
	setSemiblind(flag: boolean): void,
	setFontSize(size: number): void,
	showPopup(): void,
};

export default {
	Main: connect(mapStateToProps, {setSemiblind, setFontSize, showPopup})(FooterComponent),
	Kinokassa: connect(mapStateToProps, {setSemiblind, setFontSize, showPopup})(Footer),
};
