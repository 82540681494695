import { connect } from 'react-redux';
import ResendTickets from 'app/components/refund/index';
import { IReduxState } from 'app/types/state';
import { ReduxTypes } from 'app/store/redux';
import {
	getEmailResendStatus,
	getIsEmailResendFetching,
} from 'app/selectors/emailResendSelectors';
import { resendTicket, findTickets, setEmailResendStatus } from 'app/actions/EmailResendActions';

const mapStateToProps = (state: IReduxState) => ({
	isFetching: getIsEmailResendFetching(state),
	resendStatus: getEmailResendStatus(state),
});

const mapDispatchToProps = {
	resendTicket,
	findTickets,
	setEmailResendStatus,
};

export type ResendEmailProps = ReturnType<typeof mapStateToProps> & {
	findTickets: (email: string) => ReduxTypes.ThunkResult<void>,
	resendTicket: (email: string) => ReduxTypes.ThunkResult<void>,
	setEmailResendStatus: (value: string) => string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendTickets);
