import cn from "classnames";
import * as styles from "css/Flex.module.scss";
import { CSSProperties } from "react";

interface IProps {
	element?: string;
	children?: any;
	props?: any;
	className?: string;
	direction?: 'row' | 'column';
	justifyContent?:
		| 'flex-start'
		| 'flex-end'
		| 'center'
		| 'space-between'
		| 'space-around'
		| 'initial'
		| 'inherit';
	wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
	alignItems?:
		| 'stretch'
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'baseline'
		| 'initial'
		| 'inherit';
	gap?: string;
	classes?: string;
	width?: string;
	height?: string;
	styles?: CSSProperties;
}

function Flex({
	element,
	children,
	props,
	classes,
	direction,
	justifyContent,
	wrap,
	alignItems,
	gap,
	width,
	height
}: IProps) {
	function capitalize(str?: string) {
		if (str) {
			const words = str.split('-');

			return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
		}
	}
	
	const style = {'--flex-width': width, '--flex-height': height, '--flex-gap': gap};

	const className = cn(styles['flex'], classes, {
		[styles[`justifyContent${capitalize(justifyContent)}`]]: justifyContent,
		[styles[`alignItems${capitalize(alignItems)}`]]: alignItems,
		[styles[`direction${capitalize(direction)}`]]: direction,
		[styles[`wrap${capitalize(wrap)}`]]: wrap,
		[styles['width']]: width,
		[styles['height']]: height,
		[styles['gap']]: gap,
	});

	return React.createElement(
		element ?? 'div',
		{
			className,
			style,
			...props
		},
		children
	)
}

export default Flex;
