import {createAction} from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actions from 'app/constants/FeedbackConstants';
import {KINOKASSA_API_URL} from 'app/constants/SettingsConstants';

const postFeedbackFail = createAction(
	actions.POST_FEEDBACK_FAIL,
	error => ({error}),
);

export const addFeedback = (cinemaId, data) => async (dispatch) => {
	try {
		await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/cinema/${cinemaId}/feedback`, 'POST', data);
	} catch (error) {
		dispatch(postFeedbackFail(error));
	}
};