import { FilterPanelElementsType, FilterValueType, IFiltersState, FiltersPage } from "app/modules/filters/types";
import * as actionConstants from "app/modules/filters/redux/constants";
export const setActiveElementId = (id: FilterPanelElementsType | null  = null) => ({
	type: actionConstants.SET_ACTIVE_ELEMENT_ID,
	payload: id
});

export const applyFilters = (filtersKeys: FilterPanelElementsType[], originFilter: FilterValueType) => ({
	type: actionConstants.APPLY_FILTERS,
	payload: {filtersKeys, originFilter},
});

export const updateFilterValue = (
	filterKey: keyof IFiltersState["selectedValues"],
	value: {id: any} | null,
	valueType: FilterValueType,
	maxValuesCount?: number
) => ({
	type: actionConstants.UPDATE_FILTER_VALUE,
	payload: {
		value,
		filterKey,
		valueType,
		maxValuesCount,
	},
});

export const resetFilters = (filtersKeys: FilterPanelElementsType[], type = FilterValueType.MAIN) => ({
	type: actionConstants.RESET_FILTERS,
	payload: { filtersKeys, type }
});

export const setCurrentFiltersPage = (page: FiltersPage) => ({
	type: actionConstants.SET_CURRENT_PAGE,
	payload: page
});