export const CINEMA_ID = window.kinositeSettings.cinemaId;
export const CITY_ID = window.kinositeSettings.cityId;
export const TOKEN = window.kinositeSettings.token;
export const KINOKASSA_API_URL = window.kinokassaApiUrl;

export const RAMBLER_WIDGET_KEY = window.kinositeSettings.ramblerWidgetKey;


export const BUSINESS_DAY_END = 7;

export const THIRTY_MINUTES_INTERVAL = 1800000;

export const SHARED_METRICS_ID = 94570834;
