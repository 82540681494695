import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import PromoListItem from 'app/components/PromoListItemComponent';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {arrowIcon, arrowIconWhite} from 'app/constants/IconsConstants';
import {setYaReachGoal} from 'app/selectors/Helpers';

class PromoOthersComponent extends React.PureComponent {
	static propTypes = {
		isPromoItemPage: PropTypes.bool,
		currentPromoItemID: PropTypes.string,
		promos: PropTypes.arrayOf(PropTypes.object).isRequired,
		prefix: PropTypes.string.isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				cinemaId: PropTypes.string
			})
		}),
		page: PropTypes.string
	}

	static defaultProps = {
		isPromoItemPage: false,
		currentPromoItem: {}
	};

	handleAnalyticsClick = () => {
		setYaReachGoal('choice-showall-click', {section: this.props.page}, false);
	}

	render() {
		const {isPromoItemPage, currentPromoItemID, promos, match: {params}, prefix, page} = this.props;

		return (
			<div className={`news-others releases-list--${isPromoItemPage ? 'white' : 'primary'}`}>
				<div className="container promo-container--others">
					<h2 className={`promo__title-others promo__title-others${isPromoItemPage ? '--black' : ''}`}>
						{isPromoItemPage ? i18n.t('AnotherPromosAndDiscounts') : i18n.t('PromosAndDiscounts')}
					</h2>
					<div className="promo__title-container">
						<KeydownEnterLink
							to={params.cinemaId ? `${prefix}/cinemas/${params.cinemaId}/promo` : `${prefix}/promo`}
							className={`promo__title-link promo__title-link${isPromoItemPage ? '--black' : ''}`}
							onClick={this.handleAnalyticsClick}
						>
							<span>{i18n.t('ShowAll')} </span>
						</KeydownEnterLink>
						{isPromoItemPage ? arrowIcon : arrowIconWhite}
					</div>
				</div>
				<div className="promo-list">
					<div className="promo-list-container">
						{_.filter(promos, (promoItem) => promoItem.id !== currentPromoItemID).map((item, index) => (
							index < 3 && <PromoListItem
								key={item.id}
								item={item}
								prefix={prefix}
								page={page}
								others
							/>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(PromoOthersComponent);
