import React from 'react';
import classNames from 'classnames';

interface IProps {
	banners?: boolean;
}

const SpinnerComponent = ({banners = false}: IProps) => (
	<div className={classNames('spinner', {'banners': !!banners})}>
		<div className="rect1" />
		<div className="rect2" />
		<div className="rect3" />
		<div className="rect4" />
		<div className="rect5" />
	</div>
);

export default SpinnerComponent;
