import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {setYaReachGoal} from 'app/selectors/Helpers';
import {SocialsPropTypes} from 'app/constants/AppConstants';
import {
	instagramIcon,
	facebookIcon,
	odnoklassnikiIcon,
	twitterIcon,
	vkontakteIcon,
	youtubeIcon,
	telegramIcon,
	instagramIconColor,
	facebookIconColor,
	odnoklassnikiIconColor,
	twitterIconColor,
	vkontakteIconColor,
	youtubeIconColor,
	telegramIconColor,
} from 'app/constants/IconsConstants';

const SocialLinksComponent = ({
	className: socialLinksClassName,
	linksData: {
		facebook,
		instagram,
		twitter,
		vk,
		ok,
		youtube,
		telegram
	},
	isColorIcons = false,
	showTitles = true
}) => {
	const socialLinks = useMemo(() => [
		{
			id: 'inst',
			href: instagram,
			icon: isColorIcons ? instagramIconColor : instagramIcon,
			dataIcon: 'inst',
			title: 'Instagram'
		},
		{
			id: 'fb',
			href: facebook,
			icon: isColorIcons ? facebookIconColor : facebookIcon,
			dataIcon: 'fb',
			title: 'Facebook'
		},
		{
			id: 'vk',
			href: vk,
			icon: isColorIcons ? vkontakteIconColor : vkontakteIcon,
			dataIcon: 'vk',
			title: i18n.t('Social.VK')
		},
		{
			id: 'tw',
			icon: isColorIcons ? twitterIconColor : twitterIcon,
			href: twitter,
			title: 'Twitter'
		},
		{
			id: 'odnkl',
			icon: isColorIcons ? odnoklassnikiIconColor : odnoklassnikiIcon,
			href: ok,
			title: i18n.t('Social.Odnoklassniki')
		},
		{
			id: 'ytb',
			icon: isColorIcons ? youtubeIconColor : youtubeIcon,
			href: youtube,
			title: 'Youtube'
		},
		{
			id: 'tg',
			icon: isColorIcons ? telegramIconColor : telegramIcon,
			href: telegram,
			title: 'Telegram'
		}
	]);

	const handleClick = (event) => {
		const {icon} = event.currentTarget.dataset;

		setYaReachGoal(`${icon}-click`);
	};

	const linkItemClassName = classNames(`${socialLinksClassName}-item`, {
		'social-icon--hover-opacity': !showTitles
	});

	return (
		<div className={socialLinksClassName}>
			{socialLinks.map(({id, href, icon, dataIcon, title}) => (
				href && (
					<KeydownEnterLink
						key={id}
						type="plain"
						href={href}
						className={linkItemClassName}
						target="_blank"
						rel="noopener noreferrer"
						data-icon={dataIcon || ''}
						onClick={dataIcon ? handleClick : _.noop}
					>
						{icon}
						{showTitles && <span>{title}</span>}
					</KeydownEnterLink>
				)
			))
			}
		</div>
	);
};

SocialLinksComponent.propTypes = {
	className: PropTypes.string.isRequired,
	linksData: SocialsPropTypes.isRequired,
	isColorIcons: PropTypes.bool,
	showTitles: PropTypes.bool
};

export default memo(SocialLinksComponent);
