import { checkIsProCultureRelease } from 'app/selectors/Helpers';
import { IReduxState } from 'app/types/state';
import { createSelector } from 'reselect';

export const getReleaseSelector = (state: IReduxState) => state.releaseReducer.release;
export const getReleaseQualifiers = (state: IReduxState) => state.releaseReducer.release.all_qualifiers || [];
export const getReleaseId = (state: IReduxState) => state.releaseReducer.release.id;
export const getIsFetching = (state: IReduxState) => state.releaseReducer.isFetching;
export const getSeanceDates = (state: IReduxState) => state.releaseReducer.calendar.seance_dates;
export const getCinemas = (state: IReduxState) => state.releaseReducer.cinemas;
export const getReleaseError = (state: IReduxState) => state.releaseReducer.error;

export const isProCultureReleaseItem = createSelector(
	[getReleaseSelector],
	checkIsProCultureRelease
);
