import React from 'react';
import { Filters } from 'app/modules/filters/components/Filters';
import { SelectedFiltersList } from 'app/modules/filters/components/SelectedFiltersList';
import { useSelector } from 'react-redux';
import { getWithNewDesign } from 'app/selectors/AppSelectors';

interface IProps {
	isDisabled?: boolean;
	seanceDates?: string[];
	onDateChange?: (date: string) => void;
}

function FiltersWrapper({isDisabled, seanceDates, onDateChange}: IProps) {
	const withNewDesign = useSelector(getWithNewDesign);

	return withNewDesign ? (
		<>
			<Filters
				onDateChange={(date) => onDateChange ? onDateChange(date) : _.noop}
				seanceDates={seanceDates}
				isDisabled={isDisabled}
			/>
			<SelectedFiltersList />
		</>
	) : null;
}

export default FiltersWrapper;
