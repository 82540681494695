import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/AppConstants';

const initialState = {
	advertisements: [],
	isLoaded: false
};

export default handleActions({
	[actions.SET_ADVERTISEMENTS]: (state, {payload}) => ({
		...state,
		...payload
	}),
	[actions.RESET_ADVERTISEMENTS]: state => ({
		...state,
		advertisements: initialState.advertisements
	})
}, initialState);
