/* eslint-disable react/react-in-jsx-scope */
import store from 'app/store/configureStore';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { extendMoment } from 'moment-range';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { CINEMA_ID } from 'app/constants/SettingsConstants';
import App from 'app/containers/AppContainer';
import AppErrorContainer from 'app/containers/errors/AppErrorContainer';
import initializeSentry from 'app/initializeSentry';
import React from 'react';
import { setSelectedDay } from 'app/actions/AppActions';
import { getBusinessDay } from 'app/selectors/AppSelectors';
import {SELECTED_LANGUAGE, SUPPORTED_LANGS, FALLBACK_LANG} from 'app/constants/AppConstants';

const url = new URL(window.location.href);
const referrer = url.searchParams.get('platform');
const rootNode = document.getElementById('root');
const hasActualReferrer = [
	'kinoafisha',
	'widget',
	'afisha_yakutsk',
	'telegram_msk',
	'telegram_rnd',
	'telegram_spb'
].includes(referrer);
export const SALE_SOURCE = hasActualReferrer
	? referrer
	: window.kinositeSettings.saleSource || 'widget'; // for our websites is always widget.

class AppSite {
	constructor(store) {
		this._store = store;

		document.body.dataset.cinemaId = CINEMA_ID;
		document.body.dataset.saleSource = SALE_SOURCE;

		if (process.env.NODE_ENV === 'production') {
			initializeSentry();
		}

		this.initializeI18n().then(() => {
			this.render();
		});
	}

	initializeI18n = async () => {
		window.i18n = i18next;

		await i18n
			.use(Backend)
			.init({
				lng: SELECTED_LANGUAGE,
				defaultNS: 'main',
				ns: 'main',
				pluralSeparator: '__',
				fallbackLng: FALLBACK_LANG,
				supportedLngs: Object.values(SUPPORTED_LANGS),
				debug: process.env.NODE_ENV !== 'production',
				interpolation: {
					escapeValue: false,
				},
				backend: {
					loadPath: '/common/locales/{{lng}}/{{ns}}.json'
				}
			});

		extendMoment(moment);

		moment.updateLocale(SELECTED_LANGUAGE, {
			calendar: {
				lastDay: `[${i18n.t('Yesterday')}]`,
				sameDay: `[${i18n.t('Today')}]`,
				nextDay: `[${i18n.t('Tommorow')}]`,
				lastWeek: 'D MMMM',
				nextWeek: 'D MMMM',
				sameElse: 'D MMMM'
			},
			months: {
				format: Object.values(i18n.t('Months.Long.format', {returnObjects: true})),
				standalone: Object.values(i18n.t('Months.Long.standalone', {returnObjects: true}))
			},
			monthsShort: {
				format: Object.values(i18n.t('Months.Short.format', {returnObjects: true})),
				standalone: Object.values(i18n.t('Months.Short.standalone', {returnObjects: true}))
			},
			weekdays: Object.values(i18n.t('Days.Long', {returnObjects: true})),
			weekdaysMin: Object.values(i18n.t('Days.Short', {returnObjects: true}))
		});

		this._store.dispatch(setSelectedDay(getBusinessDay()))
	};

	render() {
		ReactDOM.render(
			<Provider store={this._store}>
				<AppErrorContainer isPageNoop={false}>
					<App />
				</AppErrorContainer>
			</Provider>,
			rootNode,
		);
	}
}

window.app = new AppSite(store);
export default window.app;
