import * as actions from 'app/constants/EmailResendConstanst';
import { KINOKASSA_API_URL } from 'app/constants/SettingsConstants';
import httpRequestPromise from 'app/httpRequestPromise';
import { ReduxTypes } from 'app/store/redux';
import { EmailResendStatus } from 'app/types/emailResendReducer';
import { createAction } from 'redux-actions';

export const setEmailResendStatus = createAction(
	actions.SET_EMAIL_RESEND_STATUS,
	(status: EmailResendStatus | null) => ({status}),
);

export const setIsEmailResendFetching = createAction(
	actions.SET_IS_EMAIL_RESEND_FETCHING,
	(isFetching: boolean) => ({isFetching}),
);

export const setEmailResendCountdownStart = createAction(
	actions.SET_EMAIL_RESEND_COUNTDOWN_START,
	(countdownStart: number) => ({countdownStart}),
);

export const resetEmailResendCountdownStart = createAction(
	actions.RESET_EMAIL_RESEND_COUNTDOWN_START,
);

export const setSentToEmail = createAction(
	actions.SET_EMAIL_RESEND_SENT_TO_EMAIL,
	(sentToEmail) => ({sentToEmail}),
);

export const resetSentToEmail = createAction(
	actions.RESET_EMAIL_RESEND_SENT_TO_EMAIL,
);

type TypeOfForm = {pin_code: string, phone?: string, email?: string};
export const resendTicket = ({pin_code, email}: TypeOfForm): ReduxTypes.ThunkResult<void> => async (dispatch) => {
	try {
		dispatch(setIsEmailResendFetching(true));

		await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/sale/resend_custom_emails`, 'POST', {pin_code, email});

		dispatch(setIsEmailResendFetching(false));
		dispatch(setEmailResendStatus(EmailResendStatus.ResentSuccessfully));
	} catch (error) {
		dispatch(setIsEmailResendFetching(false));
	}
};

export const findTickets = ({pin_code, phone, email}: TypeOfForm): ReduxTypes.ThunkResult<void> => async (dispatch) => {
	try {
		dispatch(setIsEmailResendFetching(true));

		const path = phone ? 'confirm_by_phone' : 'resend_by_pin_code';
		const data = {
			pin_code,
			[phone ? 'phone' : 'email']: phone ?? email,
		};

		await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/sale/${path}`, 'POST', {...data});

		if (email) {
			dispatch(setEmailResendStatus(EmailResendStatus.ResentSuccessfully));
		} else {
			dispatch(setEmailResendStatus(EmailResendStatus.Found));
		}
	} catch (error) {
		dispatch(setEmailResendStatus(EmailResendStatus.NotFound));
	} finally {
		dispatch(setIsEmailResendFetching(false));
	}
};
