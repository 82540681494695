export interface ISeance {
	id: string,
	cinema_id: number,
	hall: {
		id: number,
		title: string,
		is_vip: boolean,
	} | null,
	start_date: string,
	start_date_time: string,
	price: {
		min: number | null,
		max: number | null,
	},
	is_allowed_online_sale: boolean,
	formats: Array<{
		id: number,
		title: string,
	}>,
	free_places_count: number | null,
};

export const enum NoSeancesReason {
	NO_SEANCES_FOR_SELECTED_CINEMA = "NO_SEANCES_FOR_SELECTED_CINEMA",
	NO_SEANCES_FOR_SELECTED_FILTERS = "NO_SEANCES_FOR_SELECTED_FILTERS",
	NO_SEANCES_SOON = "NO_SEANCES_SOON",
	DEFAULT = "DEFAULT"
}