import * as Sentry from '@sentry/browser';

export default function initializeSentry() {
	Sentry.init({
		dsn: 'https://1707a81731f4463692bab6b0ba577f08@sentry.kinoplan.tech/59',
		ignoreErrors: [
			'POS_PREFIX_100',
			'Loading chunk'
		]
	});
}