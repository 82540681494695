import React, {useState} from 'react';
import classNames from 'classnames';
import { searchIcon } from "app/constants/IconsConstants";
import { useIsMobile } from 'app/hooks';

interface IProps {
	placeholder?: string,
	setFilter: (value: string) => void,
}

const CitySearchInputComponent = ({placeholder = '', setFilter}: IProps) => {
	const [isFocused, setIsFocused] = useState(false);
	const isMobile = useIsMobile();
	const inputClassName = classNames(
		'city-search-input',
		{'city-search-input--focused': isFocused},
	);

	const toggleFocus = () => {
		setIsFocused(!isFocused);
	};

	const handleChange = ({target}) => {
		setFilter(target.value);
	};

	return (
		<div className={inputClassName}>
			<input
				className="city-search-input__input-area"
				placeholder={placeholder}
				onFocus={toggleFocus}
				onBlur={toggleFocus}
				onChange={handleChange}
				autoFocus={!isMobile}
			/>
			<div className="city-search-input__icon">
				{searchIcon}
			</div>
		</div>
	);
};

export default CitySearchInputComponent;
