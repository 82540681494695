import { handleActions } from 'redux-actions';
import * as actions from 'app/constants/AppConstants';
import { IDailySeancesReducer } from 'app/types/dailySeancesReducer';

const initialState: IDailySeancesReducer = {
	dailySeances: [],
	dailyReleases: [],
	error: {
		code: null,
	},
	isFetching: true,
};

export default handleActions({
	[actions.SET_DAILY_SEANCES_IS_FETCHING]: (state) => ({
		...state,
		isFetching: true,
		error: {
			code: null,
		},
	}),
	[actions.SET_DAILY_SEANCES]: (state, {payload}) => ({
		...state,
		...payload,
		isFetching: false,
	}),
	[actions.GET_DAILY_SEANCES_FAIL]: (state, {payload: {code}}) => ({
		...state,
		error: {
			...state.error,
			code,
		},
		isFetching: false,
	}),
}, initialState);
