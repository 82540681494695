import * as actions from 'app/constants/EmailResendConstanst';
import { handleActions } from 'redux-actions';
import { IEmailResendReducer } from 'app/types/emailResendReducer';

const countdownStart = Number(localStorage.getItem('emailResendCountdownStart') || 0);

const initialState: IEmailResendReducer = {
	status: null,
	isFetching: false,
	countdownStart: countdownStart ? countdownStart : null,
	sentToEmail: null,
};

export default handleActions({
	[actions.SET_EMAIL_RESEND_STATUS]: (state, {payload: {status}}) => ({
		...state,
		status,
	}),
	[actions.SET_IS_EMAIL_RESEND_FETCHING]: (state, {payload: {isFetching}}) => ({
		...state,
		isFetching,
	}),
	[actions.SET_EMAIL_RESEND_COUNTDOWN_START]: (state, {payload: {countdownStart}}) => {
		localStorage.setItem('emailResendCountdownStart', countdownStart.toString());

		return {
			...state,
			countdownStart,
		};
	},
	[actions.RESET_EMAIL_RESEND_COUNTDOWN_START]: (state) => {
		localStorage.removeItem('emailResendCountdownStart');

		return ({
			...state,
			countdownStart: null,
		});
	},
	[actions.SET_EMAIL_RESEND_SENT_TO_EMAIL]: (state, {payload: {sentToEmail}}) => ({
		...state,
		sentToEmail,
	}),
	[actions.RESET_EMAIL_RESEND_SENT_TO_EMAIL]: (state) => ({
		...state,
		sentToEmail: null,
	}),
}, initialState);
