import { ConnectedProps, connect } from 'react-redux';
import { LawDocumentsCheckbox } from 'app/components/documents/LawDocumentsCheckbox';
import { showPopup } from 'app/actions/AppActions';

const connector = connect(null, {showPopup});

export type CinemaLawDocumentsProps = ConnectedProps<typeof connector> & {
	cinemaID: number;
	classes?: string;
	name?: string;
	isChecked: boolean;
	onChange:(event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default connector(LawDocumentsCheckbox);
