import React, { FunctionComponent, memo } from "react";
import { Qualifiers } from "app/types/releases";
import ReleaseQualifiers from "app/components/ReleaseQualifiers";
import ProcultureLabel from 'app/components/release_item/ProcultureLabel';
import { checkHasNeedShowReleaseBadges, checkIsProCultureRelease } from "app/selectors/Helpers";

interface IProps {
	classes: string;
	qualifiers: Qualifiers[];
	withProCultureBadge?: boolean;
	isReleasePage?: boolean;
	wrapperElement?: FunctionComponent<{className?: string; classes?: string}> | keyof JSX.IntrinsicElements;
};

const ReleaseQualifiersWrapper = ({qualifiers, withProCultureBadge, classes, isReleasePage, wrapperElement = "div"}: IProps) => {
	const isProCultureRelease = checkIsProCultureRelease({all_qualifiers: qualifiers});
	const isVisible = checkHasNeedShowReleaseBadges(qualifiers) || (isProCultureRelease && withProCultureBadge);

	return isVisible ? (
		React.createElement(
			wrapperElement,
			{
				classes,
				className: classes
			},
			(
				<>
					<ReleaseQualifiers qualifiers={qualifiers} isReleasePage={isReleasePage}/>
					{withProCultureBadge && isProCultureRelease ? <ProcultureLabel /> : null}
				</>
			)
		)
	) : null;
}

export default memo(ReleaseQualifiersWrapper);
