import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/PromoConstants';

const initialState = {
	promos: [],
	promoItem: {
		id: null
	},
	error: {},
	isFetching: false
};

export default handleActions({
	[actions.SET_PROMO_IS_FETCHING]: state => ({
		...state,
		isFetching: true
	}),

	[actions.GET_PROMOS_SUCCESS]: (state, {payload}) => ({
		...state,
		promos: payload,
		isFetching: false
	}),

	[actions.GET_PROMOS_FAIL]: (state, {payload}) => ({
		...state,
		isFetching: false,
		error: payload.error
	}),

	[actions.GET_PROMO_ITEM_SUCCESS]: (state, {payload}) => ({
		...state,
		promoItem: payload.promoItem,
		isFetching: false
	}),
	[actions.RESET_PROMOS]: () => initialState
}, initialState);
