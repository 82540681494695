import { handleActions } from 'redux-actions';
import * as actions from 'app/constants/AppConstants';
import { IAppReducer } from 'app/types/appReducer';

const getInitialState = (): IAppReducer => ({
	..._.propertyOf(window.kinosite)('reducer'),
	aboutCinema: '',
	severalCinemasAboutData: [],
	halls: [],
	alert: null,
	branding: {},
	contacts: {
		phones: [],
		addresses: {},
		socials: {},
		apps: {},
	},
	error: {},
	country: null,
	isFetching: false,
	isMobile: false,
	popupOptions: {},
	selectedDay: null,
	hasSmartBanner: true,
	isSemiblind: !!localStorage.getItem('colorTheme'),
	fontSize: parseInt(localStorage.getItem('fontSize') || '16', 10),
	allCities: [],
	currentCityId: Number(localStorage.getItem('currentCityId')) || null,
	allCinemas: [],
	allContacts: [],
	cityFilter: '',
	isUserAuth: false,
	isWidgetInKioskMode: false,
	modules: {},
	countUnreadNotifications: 0,
});

export default handleActions({
	[actions.GET_APP_DATA_REQUEST]: state => ({
		...state,
		isFetching: true,
	}),
	[actions.GET_APP_DATA_SUCCESS]: (state, {
		payload: { cinema, alert, allCities = [], allCinemas = [], allContacts = [], branding, modules, country },
	}) => ({
		...state,
		alert,
		allCities,
		allCinemas,
		allContacts,
		cinema: {
			...state.cinema,
			...cinema,
		},
		branding,
		modules,
		isFetching: false,
		error: {
			code: null,
		},
		country,
	}),
	[actions.SET_APP_ERROR]: (state, { payload: { code } }) => ({
		...state,
		isFetching: false,
		error: {
			code,
		},
	}),
	[actions.SET_ABOUT_CINEMA]: (state, { payload: { aboutCinema } }) => ({
		...state,
		aboutCinema,
	}),
	[actions.SET_SEVERAL_CINEMAS_ABOUT_DATA]: (state, { payload: { severalCinemasAboutData } }) => ({
		...state,
		severalCinemasAboutData,
	}),
	[actions.SET_CONTACTS]: (state, { payload }) => ({
		...state,
		contacts: {
			...state.contacts,
			...payload.contacts,
		},
	}),
	[actions.SET_HALLS]: (state, { payload }) => ({
		...state,
		halls: payload.halls,
	}),
	[actions.RESET_CURRENT_CINEMA_INFO]: (state) => {
		const { aboutCinema, contacts } = getInitialState();

		return {
			...state,
			aboutCinema,
			contacts,
		};
	},
	[actions.SHOW_POPUP]: (state, { payload }) => ({
		...state,
		popupOptions: payload.popupOptions,
	}),
	[actions.HIDE_POPUP]: (state) => ({
		...state,
		popupOptions: getInitialState().popupOptions,
	}),
	[actions.SET_SELECTED_DAY]: (state, action) => ({
		...state,
		selectedDay: action.payload.selectedDay,
	}),
	[actions.SET_IS_MOBILE]: (state, action) => ({
		...state,
		isMobile: action.payload,
	}),
	[actions.CLOSE_SMART_BANNER]: (state) => ({
		...state,
		hasSmartBanner: false,
	}),
	[actions.SET_SEMIBLIND]: (state, { payload }) => ({
		...state,
		isSemiblind: payload,
	}),
	[actions.SET_FONT_SIZE]: (state, { payload }) => ({
		...state,
		fontSize: payload,
	}),
	[actions.SET_NEW_CURRENT_CINEMA_DATA]: (state, { payload }) => ({
		...state,
		cinema: {
			...state.cinema,
			...payload.cinema,
		},
		alert: payload.alert,
	}),
	[actions.SET_CITY_FILTER]: (state, { payload: { cityFilter } }) => ({
		...state,
		cityFilter,
	}),
	[actions.SET_CURRENT_CITY_ID]: (state, { payload: { newId } }) => ({
		...state,
		currentCityId: newId,
	}),
	[actions.SET_IS_USER_AUTH]: (state, { payload: { isUserAuth } }) => ({
		...state,
		isUserAuth,
	}),
	[actions.SET_IS_WIDGET_IN_KIOSK_MODE]: (state, { payload: { isWidgetInKioskMode } }) => ({
		...state,
		isWidgetInKioskMode,
	}),
	[actions.SET_COUNT_UNREAD_NOTIFICATIONS]: (state, { payload: { countUnreadNotifications } }) => ({
		...state,
		countUnreadNotifications,
	}),
}, getInitialState());
