export default function getReleaseQualifiersContants() {
	return ({
		isPremiere: i18n.t('Premiere'),
		isClosedShow: i18n.t('ClosedShow'),
		isStellarPremiere: i18n.t('StellarPremiere'),
		isSpecialShow: i18n.t('SpecialShow'),
		isProculture: i18n.t('PushkinCard'),
		isForKids: i18n.t('ForKids'),
	});
}
