import { ConnectedProps, connect } from 'react-redux';
import {
	getAppData,
	getAboutCinemaRequest,
	setIsMobile,
	showPopup,
	hidePopup,
	getFondKinoAppData,
	updateCurrentCinema,
	setCountUnreadNotifications,
	resetCurrentCinemaInfo, setCurrentCityId, setIsUserAuth, setIsWidgetInKioskMode,
} from 'app/actions/AppActions';
import { getAdvertisements, resetAdvertisements } from 'app/actions/AdvertisementsActions';
import { getPromos, resetPromos } from 'app/actions/PromoActions';
import { getNews, resetNews } from 'app/actions/NewsActions';
import { getCustomPagesRequest, resetPages } from 'app/actions/MenuActions';
import AppComponent from 'app/components/AppComponent';
import {
	getCinema,
	getMenuLinks,
	getIsSemiblind,
	getCurrentCityId,
	isSmartBannerShown,
	getAlert,
	getCurrentCinemaId,
	getIsSeveralCinemasInCity,
	getBranding,
	getIsMonitor,
	getIsKinokassa, getCurrentCinemas,
	getAllCities,
	getWithNewDesign,
} from 'app/selectors/AppSelectors';
import { hasAdvertisementsList } from 'app/selectors/AdvertisementsSelectors';
import { getOffset } from 'app/selectors/NewsSelectors';
import { getQualifiers } from 'app/actions/AfishaActions';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => ({
	alert: getAlert(state),
	cinema: getCinema(state),
	currentCinemaId: getCurrentCinemaId(state),
	cities: getAllCities(state),
	isSmartBannerShown: isSmartBannerShown(state),
	menuLinks: getMenuLinks(state),
	isSemiblind: getIsSemiblind(state),
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
	currentCinemas: getCurrentCinemas(state),
	isKinokassa: getIsKinokassa(state),
	cityId: getCurrentCityId(state),
	offsetNews: getOffset(state),
	branding: getBranding(state),
	isMonitor: getIsMonitor(state),
	withNewDesign: getWithNewDesign(state),
	hasAdvertisementsList: hasAdvertisementsList(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	getAboutCinema: (cinemaID: number | null) => getAboutCinemaRequest(cinemaID),
	getAdvertisements: (cinemaID: number | null, cityID?: number) => getAdvertisements(cinemaID, cityID),
	getAppData: (cinemaID: number) => getAppData(cinemaID),
	getCustomPagesRequest: (cinemaID: number | null, cityID?: number) => getCustomPagesRequest(cinemaID, cityID),
	updateCurrentCinema: (cinemaID: number | null, cityID: number) => updateCurrentCinema(cinemaID, cityID),
	resetAdvertisements,
	handleHidePopup: hidePopup,
	setIsMobile,
	showPopup,
	getFondKinoAppData,
	resetCurrentCinemaInfo,
	resetPages,
	getPromos: (cinemaID: number | null, cityID?: number) => getPromos(cinemaID, cityID),
	resetPromos,
	getNews: (
		cinemaID: number | null,
		cityID: number,
		offset: number,
		params?: {isOtherNews?: boolean, isVip?: boolean, isImax?: boolean}
	) => getNews(cinemaID, cityID, offset, params),
	resetNews,
	setCurrentCityId,
	setIsUserAuth,
	setIsWidgetInKioskMode,
	getQualifiers,
	setCountUnreadNotifications,
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AppComponentProps = ConnectedProps<typeof connector>;

export default connector(AppComponent);
