import React, { useCallback, useEffect } from 'react';
import ReleaseScheduleComponent from 'app/components/ReleaseScheduleComponent';
import Spinner from 'app/components/SpinnerComponent';
import { changePageTitle } from 'app/actions/AppActions';
import { scrollToTop, getDateFromSearch } from 'app/selectors/Helpers';
import { useHistory, useParams } from 'react-router-dom';
import Flex from 'app/ui/Flex/Flex';
import { usePrevious } from 'app/hooks';
import FiltersWrapper from "app/modules/filters/components/FiltersWrapper";
import {FiltersPage} from "app/modules/filters/types";

import {
	getIsFetching,
	getReleaseError,
} from 'app/selectors/ReleaseSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDay } from 'app/actions/AppActions';
import { getSeances } from 'app/actions/ReleaseItemActions';
import { getRelease, setReleaseError } from 'app/actions/ReleaseActions';
import { setCurrentFiltersPage } from 'app/modules/filters/redux/actions';
import { IRelease } from 'app/types/releases';

interface IProps {
	release: IRelease;
	seanceDates: string[];
	cityId: number;
}

function ReleasePage({release, seanceDates, cityId}: IProps) {
	const dispatch = useDispatch();
	const history = useHistory();

	const { modifier, id: currentReleaseID } = useParams<{id: string, modifier?: string}>();

	const isFetching = useSelector(getIsFetching);
	const hasReleaseError = !_.isEmpty(useSelector(getReleaseError));

	const prevRelease = usePrevious(release);
	const prevReleaseID = usePrevious(currentReleaseID);

	const {
		id,
		rambler_id: ramblerReleaseId,
		cinema_start_date: cinemaStartDate,
		russia_start_date: russiaStartDate,
	} = release;

	useEffect(() => {
		scrollToTop();
	}, []);

	useEffect(() => {
		const { search } = history.location;

		dispatch(setCurrentFiltersPage(FiltersPage.RELEASE));

		if (_.isEmpty(release)) {
			dispatch(getRelease(currentReleaseID, cityId));
		}

		if (search) {
			const date = getDateFromSearch(search);

			if (date) {
				dispatch(updateSelectedDay(moment(date)));
			}
		}
	}, []);

	useEffect(() => {
		if (prevRelease !== release) {
			if (release.title) {
				changePageTitle(release.title);
			}
		}

		if (prevReleaseID !== currentReleaseID) {
			dispatch(getRelease(currentReleaseID, cityId));
		}

		if (hasReleaseError) {
			history.replace('/');
			dispatch(setReleaseError({}));
		}
	}, [currentReleaseID, release, hasReleaseError]);

	const handleChangeDate = useCallback((date) => {
		const releaseId = parseInt(currentReleaseID, 10);

		dispatch(getSeances(releaseId, date, cityId));
	}, [currentReleaseID, cityId]);

	return (
		!isFetching && id ? (
			<div className="page">
				<FiltersWrapper onDateChange={handleChangeDate} seanceDates={seanceDates}/>
				<Flex classes="release release-page" justifyContent="center">
					<Flex classes="kassa-container">
						{seanceDates.length
							? (
								<ReleaseScheduleComponent
									releaseId={parseInt(currentReleaseID, 10)}
									ramblerReleaseId={ramblerReleaseId}
									seanceDates={seanceDates}
									modifier={modifier}
								/>
							) : (
								<div className="release__soon-date">
									{i18n.t('AtCinemaFrom', {
										date: moment(cinemaStartDate || russiaStartDate).format('D MMMM YYYY'),
									})}
								</div>
							)
						}
					</Flex>
				</Flex>
			</div>
		) : <Spinner />
	);
}

export default ReleasePage;
