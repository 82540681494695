import React, { memo } from 'react';

const NotificationsCounter = ({countUnreadNotifications}) => (
	 countUnreadNotifications
		 ? (
			<div className="counter text text--x-small">
				{countUnreadNotifications}
			</div>
		) : null
);

export default memo(NotificationsCounter);
