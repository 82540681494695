import ReleaseItemComponent from 'app/components/ReleaseItemComponent';
import { MdKeyboardArrowLeft } from '@react-icons/all-files/md/MdKeyboardArrowLeft';
import { MdKeyboardArrowRight } from '@react-icons/all-files/md/MdKeyboardArrowRight';
import React, { useEffect, useRef, MouseEvent } from 'react';
import { IRelease } from 'app/types/releases';
import * as style from 'css/grouped-releases.module.scss';

const GroupOfReleases = ({
	groupKey,
	releasesOfGroup,
	page,
	isImax,
	isVip,
} : {
	page: string;
	groupKey: string;
	releasesOfGroup: IRelease[];
	isImax?: boolean;
	isVip?: boolean;
}) => {
	const daysList = useRef<HTMLDivElement>(null);
	const leftScrollRef = useRef<HTMLDivElement>(null);
	const rightScrollRef = useRef<HTMLDivElement>(null);

	const handleListScroll = () => {
		if (!daysList.current || !leftScrollRef.current || !rightScrollRef.current) return;

		const {scrollLeft, scrollWidth, clientWidth} = daysList.current;

		if (scrollWidth === clientWidth) {
			leftScrollRef.current?.classList.add("g-hide");
			rightScrollRef.current?.classList.add("g-hide");

			return;
		}

		switch (true) {
			case scrollLeft < 10:
				leftScrollRef.current?.classList.add("g-hide");
				break;
			case scrollLeft + clientWidth > scrollWidth - 10:
				rightScrollRef.current?.classList.add("g-hide");
				break;
			default:
				leftScrollRef.current?.classList.remove("g-hide");
				rightScrollRef.current?.classList.remove("g-hide");
				break;
		}
	};

	useEffect(() => {
		handleListScroll();
	}, []);

	const handleScrollButtonClick = (event: MouseEvent<HTMLDivElement>) => {
		const direction = Number(event.currentTarget.dataset.direction)

		if (daysList.current) {
			daysList.current.scrollLeft += daysList.current.clientWidth * 0.8 * direction;
		}
	}

	return (
		<>
			<div className={style.groupTitle}>{i18n.t(`ReleaseGroups.${groupKey}`)}</div>
			<div className={style.groupContent}>
				<div ref={leftScrollRef} className={style.releasesScrollButton}>
					<div
						className={style.releasesScrollButtonIconWrapper}
						onClick={handleScrollButtonClick}
						data-direction="-1"
					>
						<MdKeyboardArrowLeft/>
					</div>
				</div>
				<div
					ref={daysList}
					className={`${style.releasesContainerGrouped} releases-container`}
					onScroll={handleListScroll}
				>
					{
						releasesOfGroup.map((release, index) =>
							<ReleaseItemComponent release={release} key={index} page={page} isImax={isImax} isVip={isVip} />,
						)
					}
				</div>
				<div ref={rightScrollRef} className={[style.releasesScrollButton, style.releasesScrollButtonRight].join(" ")}>
					<div
						className={style.releasesScrollButtonIconWrapper}
						onClick={handleScrollButtonClick}
						data-direction="1"
					>
						<MdKeyboardArrowRight/>
					</div>
				</div>
			</div>
		</>
	)
}

export default GroupOfReleases;
