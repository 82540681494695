import { createSelector } from 'reselect';
import { getReleaseId } from 'app/selectors/ReleaseSelectors';
import { isMobile, getSoonPageIsEnabled } from 'app/selectors/AppSelectors';
import { MOBILE_POSTERS_AMOUNT, DESKTOP_POSTERS_AMOUNT } from 'app/constants/AppConstants';
import { IReduxState } from 'app/types/state';
import { getCurrentSortType, getFiltersOfReleases } from 'app/modules/filters/redux/selectors';
import { filterReleases, sortReleases } from 'app/selectors/Helpers';

export const getAllReleasesSoon = (state: IReduxState) => state.releasesSoonReducer.releasesSoon;
export const getIsFetching = (state: IReduxState) => state.releasesSoonReducer.isFetching;
export const getGenresOfSoonReleases = (state: IReduxState) => state.releasesSoonReducer.genres;
export const getQuailfiersOfSoonReleases = (state: IReduxState) => state.releasesSoonReducer.all_qualifiers;
export const getFormatsOfSoonReleases = (state: IReduxState) => state.releasesSoonReducer.formats;
const getCurrentReleasesSoon = createSelector(
	[getAllReleasesSoon, getReleaseId],
	(releasesSoon, releaseId) => _.reject(releasesSoon, (release) =>
	release.id === releaseId),
);

export const getReleasesSoonSelector = state => {
	const postersAmount = isMobile() ? MOBILE_POSTERS_AMOUNT : DESKTOP_POSTERS_AMOUNT;

	return getSoonPageIsEnabled(state)
		? _.first(getCurrentReleasesSoon(state), postersAmount)
		: getCurrentReleasesSoon(state);
};

const getFilteredSoonReleases = createSelector(
	[getAllReleasesSoon, getFiltersOfReleases],
	(releases, filters) => filterReleases(releases, filters)
)

export const getSortedAndFilteredSoonReleases = createSelector(
	[getFilteredSoonReleases, getCurrentSortType],
	sortReleases
)

