import { connect } from 'react-redux';
import WidgetOverlay from 'app/components/WidgetOverlayComponent';
import { getCinemasForRambler, getIsKinokassa, getIsWidgetDisabled } from 'app/selectors/AppSelectors';
import { IReduxState } from 'app/types/state';

const mapStateToProps = (state: IReduxState) => ({
	cinemasForRambler: getCinemasForRambler(state),
	isWidgetDisabled: getIsWidgetDisabled(state),
	isKinokassa: getIsKinokassa(state),
});

export type WidgetOverlayProps = ReturnType<typeof mapStateToProps> & {
	cinemaId: number,
	releaseId?: number,
	seanceId?: string,
	startTime?: string,
	ramblerReleaseId?: number | null,
	page: string,
	isSoldOut: boolean,
	doNotSendAnalytics?: boolean
};

export default connect(mapStateToProps)(WidgetOverlay);
