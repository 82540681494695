import { createSelector } from 'reselect';
import { IReduxState } from 'app/types/state';
import { FILTER_PANEL_ELEMENTS, IFilterValue, FiltersPage } from 'app/modules/filters/types';
import { getCurrentPage, getCurrentSortType, getFiltersOfReleases } from 'app/modules/filters/redux/selectors';
import { getAllReleasesSoon, getFormatsOfSoonReleases, getGenresOfSoonReleases, getQuailfiersOfSoonReleases } from 'app/selectors/ReleasesSoonSelectors';
import { filterReleases, getReleaseAllQualifiersMap, checkIsProCultureRelease, sortReleases } from 'app/selectors/Helpers';
import { GenresIDSforAfishaGroups, GroupedAfishaType, GroupsOfAfishaReleases, IRelease, Qualifiers } from "app/types/releases";

export const getHasAfishaData = (state: IReduxState) => !!state.afishaReducer.afisha;
export const getAfishaSelector = (state: IReduxState) => state.afishaReducer.afisha || [];
export const getSeanceDisplaySettings = (state: IReduxState) => state.afishaReducer.seanceDisplaySettings;
export const getIsFetching = (state: IReduxState) =>
	state.afishaReducer.isAfishaFetching || state.afishaReducer.isAfishaInfoFetching;
export const getSeanceDates = (state: IReduxState) => state.afishaReducer.calendar.seance_dates || [];
export const getAfishaErrorCode = (state: IReduxState) => state.afishaReducer.error.code;
const getQualifiers = (state: IReduxState) => state.afishaReducer.all_qualifiers;
const getReleasesGenres = (state: IReduxState) => state.afishaReducer.genres;
const getAllFormats = (state: IReduxState) => state.afishaReducer.formats;
const getAllQualifiers = (state: IReduxState) => state.afishaReducer.all_qualifiers;

export const getProCultureAfisha = createSelector(
	[getAfishaSelector],
	(afisha) => {
		return afisha.filter(checkIsProCultureRelease);
	},
);

const addReleaseToGroup = (
	groupedAfisha: GroupedAfishaType,
	groupKey: GroupsOfAfishaReleases,
	release: IRelease,
	hasBeenReleaseGroupedRef: { value: boolean }
) => {
	groupedAfisha[groupKey].push(release);
	hasBeenReleaseGroupedRef.value = true;
}

const getGroupedAfisha = createSelector(
	[getAfishaSelector],
	(releases) => {
		const groupedAfisha: Record<GroupsOfAfishaReleases, IRelease[]> = {
			premiere: [],
			family: [],
			comedy: [],
			children: [],
			horrorAndThrillers: [],
			action: [],
			drama: [],
			notGrouped: []
		}

		const genresToGroupsMap: Partial<Record<GenresIDSforAfishaGroups, GroupsOfAfishaReleases>> = {
			[GenresIDSforAfishaGroups.FAMILY]: "family",
			[GenresIDSforAfishaGroups.COMEDY]: "comedy",
			[GenresIDSforAfishaGroups.ACTION]: "action",
			[GenresIDSforAfishaGroups.DRAMA]: "drama"
		};

		const hasBeenReleaseGroupedRef = { value: false };

		releases.forEach((release) => {
			const allQualifiers = getReleaseAllQualifiersMap(release.all_qualifiers);

			if (allQualifiers.isPremiere) addReleaseToGroup(groupedAfisha, "premiere", release, hasBeenReleaseGroupedRef);

			release.genres.forEach(({id}) => {
				if (genresToGroupsMap[id]) addReleaseToGroup(groupedAfisha, genresToGroupsMap[id], release, hasBeenReleaseGroupedRef);
				if (
					id === GenresIDSforAfishaGroups.FEAR ||
					id === GenresIDSforAfishaGroups.TRILLER
				) addReleaseToGroup(groupedAfisha, "horrorAndThrillers", release, hasBeenReleaseGroupedRef);
			})

			if (release.age_rating === "0+" || release.age_rating === "6+") addReleaseToGroup(groupedAfisha, "children", release, hasBeenReleaseGroupedRef);

			if (!hasBeenReleaseGroupedRef.value) {
				groupedAfisha.notGrouped.push(release);
			}
		});

		return groupedAfisha;
	}
)

const getGroupedFilteredAfisha = createSelector(
	[getGroupedAfisha, getFiltersOfReleases],
	(afisha, filters) => (
		Object.entries(afisha).reduce<typeof afisha>(
			(acc, [groupKey, releasesOfGroup]) => {
				acc[groupKey] = filterReleases(releasesOfGroup, filters);

				return acc;
			}, {} as any
		)
	)
)

export const getGroupedSortedAndFilteredAfisha = createSelector(
	[getGroupedFilteredAfisha, getCurrentSortType],
	(afisha, currentSortType) => (
		Object.entries(afisha).reduce<typeof afisha>(
			(acc, [groupKey, releasesOfGroup]) => {
				acc[groupKey] = sortReleases(releasesOfGroup, currentSortType);

				return acc;
			}, {} as any
		)
	)
)

export const getFilteredAfishaLength = createSelector(
	[getGroupedFilteredAfisha],
	(afisha) => (
		Object.values(afisha).reduce((acc, releases) => acc + releases.length, 0)
	)
)

const getFilteredAfisha = createSelector(
	[getAfishaSelector, getFiltersOfReleases],
	(releases, filters) => filterReleases(releases, filters)
)

export const getSortedAndFilteredAfisha = createSelector(
	[getFilteredAfisha, getCurrentSortType],
	sortReleases
)

export const getCurrentAfishaSelector = (isProCulture?: boolean) => isProCulture ? getProCultureAfisha : getAfishaSelector;

export const getAfishaToday = createSelector(
	getAfishaSelector,
	(afisha) => _.filter(afisha, (release) => !!release.seances.length),
);

export const hasProCultureSeanses = createSelector(
	[getQualifiers],
	(qualifiers) => !!qualifiers.includes(Qualifiers.pro_culture),
);

const getFiltersDataOfCurrentPage = createSelector(
	[
		getCurrentPage,
		getAfishaSelector, getAllReleasesSoon,
		getAllFormats, getAllQualifiers,
		getFormatsOfSoonReleases, getQuailfiersOfSoonReleases,
		getReleasesGenres, getGenresOfSoonReleases
	],
	(
		page,
		releasesOfAfisha, releasesOfSoonPage,
		formats, qualifiers,
		formatsSoon, qualifiersSoon,
		genres, genresSoon,
	) => (
		page === FiltersPage.AFISHA ? {
			releases: releasesOfAfisha,
			formats,
			qualifiers,
			genres: genres.filter(({id}) => !GenresIDSforAfishaGroups[id])
		} : {
			releases: releasesOfSoonPage,
			formats: formatsSoon,
			qualifiers: qualifiersSoon,
			genres: genresSoon
		}
	)
)

export const getAgeRatings = createSelector(
	[getFiltersDataOfCurrentPage],
	({releases}) => {
		const tempRecord: Record<string, boolean> = {};
		const uniqueRatings: Array<IFilterValue & {numericRating: number}> = [];

		releases.forEach(({age_rating}) => {
			if (!tempRecord[age_rating] && age_rating) {
				const numericRating = parseInt(age_rating.replace('+', ''), 10);
				tempRecord[age_rating] = true;

				uniqueRatings.push({
					filterKey: FILTER_PANEL_ELEMENTS.AGE,
					title: age_rating,
					id: age_rating,
					numericRating
				});
			}
		});

		return uniqueRatings.sort((a, b) => a.numericRating - b.numericRating);
	}
);

export const getReleasesFeatures = createSelector(
	[getFiltersDataOfCurrentPage],
	({formats, qualifiers}) => {
		const transformedFormats = formats.map<IFilterValue>(({id, title}) => {
			return {
				id: String(id),
				title,
				filterKey: FILTER_PANEL_ELEMENTS.FEATURES,
			}
		});

		const transformedQualifiers = qualifiers.reduce((acc, qualifier) => {
			if (![Qualifiers.pro_culture, Qualifiers.for_kids, Qualifiers.custom].includes(qualifier)) {
				acc.push({
					id: qualifier,
					title: i18n.t(`Qualifiers.${qualifier}`),
					filterKey: FILTER_PANEL_ELEMENTS.FEATURES,
				});
			}

			return acc
		}, [] as typeof transformedFormats)

		return transformedFormats.concat(transformedQualifiers);
	}
);

export const getMappedReleasesGenres = createSelector(
	[getFiltersDataOfCurrentPage],
	({genres}) => genres.map<IFilterValue>(({id, title}) => (
		{
			id: String(id),
			title,
			filterKey: FILTER_PANEL_ELEMENTS.GENRE,
		}
	))
);
