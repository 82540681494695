import { IValidation } from "app/types/refund";
import { LIMIT_ORDER_NUMBER } from "app/constants/AppConstants";

/* eslint-disable no-useless-escape */
export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEXP = /^(\+7|8)(9\d{9})$/;

export const VALIDATION = (): {[key: string]: IValidation[]} => ({
	contacts: [
		{
			isValid: (value: string) => !!value,
			message: i18n.t('FieldMustBeFilled'),
		},
		{
			isValid: (value: string) => EMAIL_REGEXP.test(value) || PHONE_REGEXP.test(value),
			message: i18n.t('EnterCorrectEmailOrPhone'),
		},
	],
	order: [
		{
			isValid: (value: string) => !!value,
			message: i18n.t('FieldMustBeFilled'),
		},
		{
			isValid: (value: string) => value.length === LIMIT_ORDER_NUMBER,
			message: i18n.t('EnterCorrectOrder'),
		},
	],
	actualEmail: [
		{
			isValid: (value: string) => !!value,
			message: i18n.t('FieldMustBeFilled'),
		},
		{
			isValid: (value: string) => EMAIL_REGEXP.test(value),
			message: i18n.t('EnterCorrectEmail'),
		},
	],
});
