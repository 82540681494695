export interface IEmailResendReducer {
	status: EmailResendStatus | null;
	isFetching: boolean;
	countdownStart: number | null;
	sentToEmail: string | null;
}

export const enum EmailResendStatus {
	ResentSuccessfully = 1,
	NotFound,
	Found,
}
