import cn from 'classnames';
import { memo } from 'react';

interface IProps {
	text: string | JSX.Element;
	title: string;
	className?: string;
	iconSrc?: string;
	children?: JSX.Element;
}

function Alert({ text, title, className, iconSrc, children }: IProps) {
	return (
		<section className={cn('alert', className)}>
			{iconSrc && <img src={iconSrc} width={96} height={96} alt="Alert icon" className="alert__icon" />}

			{title && <h3 className="alert__title">{title}</h3>}

			<p className="alert__text">
				{text}
			</p>

			{children && <div className="alert__content">{children}</div>}
		</section>
	);
}

export default memo(Alert);
