import {connect} from 'react-redux';
import {
	getCinema,
	getAddresses,
	getContactsPhones,
	getIsSeveralCinemasInCity,
} from 'app/selectors/AppSelectors';
import Contacts from 'app/components/contacts/Contacts';

const mapStateToProps = (state, {hasTitle}) => ({
	hasTitle,
	cinema: getCinema(state),
	phones: getContactsPhones(state),
	addresses: getAddresses(state),
	isSeveralCinemasInCity: getIsSeveralCinemasInCity(state),
});

export type ContactsProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Contacts);