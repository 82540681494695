import React, { useRef } from 'react';
import { Link, NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

const linkTypes = {
	plain: 'a',
	navLink: NavLink,
	routerLink: Link,
};

interface IProps extends RouteComponentProps {
	className?: string;
	href?: string;
	to?: string;
	target?: string;
	rel?: string;
	style?: object;
	children?: React.ReactNode;
	type?: 'plain' | 'routerLink' | 'navLink';
	onClick?: (event: React.SyntheticEvent) => void;
	tabIndex?: number;
	activeClassName?: string;
	exact?: boolean;
}

const KeydownEnterLinkComponent = ({
	history,
	children,
	type = 'routerLink',
	onClick = _.noop,
	...props
}: IProps) => {
	const isPlainLink = type === 'plain';
	const linkRef = isPlainLink ? useRef<HTMLAnchorElement>(null) : null;
	const linkProps = _.omit(props, 'match', 'staticContext', 'location');

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.keyCode === 13) {
			onClick(event);

			return isPlainLink
				? linkRef?.current?.click()
				: history.push((event.target as HTMLAnchorElement).pathname);
		}
	};

	if (isPlainLink) {
		linkProps.ref = linkRef;
	}

	return React.createElement(
		linkTypes[type],
		{
			onKeyDown: handleKeyDown,
			onClick,
			...linkProps,
		},
		children,
	);
};

export default withRouter(KeydownEnterLinkComponent);
