import { createAction } from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actionsTypes from 'app/constants/AppConstants';
import { KINOKASSA_API_URL, CITY_ID } from 'app/constants/SettingsConstants';
import { ReduxTypes } from 'app/store/redux';

const getAfishaRequest = createAction(
	actionsTypes.GET_AFISHA_REQUEST,
);

const getAfishaInfoRequest = createAction(
	actionsTypes.GET_AFISHA_INFO_REQUEST,
);

const getAfishaSuccess = createAction(
	actionsTypes.GET_AFISHA_SUCCESS,
	payload => payload,
);

const getAfishaInfoSuccess = createAction(
	actionsTypes.GET_AFISHA_INFO_SUCCESS,
	payload => payload,
);

export const setAfishaError = createAction(
	actionsTypes.SET_AFISHA_ERROR,
	code => ({ code }),
);

export const setSeanceDisplaySettings = createAction(
	actionsTypes.SET_SEANCE_DISPLAY_SETTINGS,
	payload => payload,
);

export const setQualifiers = createAction(
	actionsTypes.SET_QUALIFIERS,
	payload => payload,
);

export const getQualifiers = (date, cityId = CITY_ID): ReduxTypes.ThunkResult<void> => async (dispatch) => {
	dispatch(getAfishaRequest());

	try {
		if (cityId && date) {
			const { all_qualifiers } = await httpRequestPromise(
				`${KINOKASSA_API_URL}/api/v2/release/playbill?city_id=${cityId}&date=${date}`, 'GET');

			dispatch(setQualifiers(all_qualifiers));
		}
	} catch (error) {
		dispatch(setAfishaError(actionsTypes.INTERNAL_SERVICE_ERROR));
	}
};

export const getAfisha = (date, cityId = CITY_ID, params): ReduxTypes.ThunkResult<void> => async (dispatch) => {
	dispatch(getAfishaRequest());

	try {
		if (cityId && date) {
			const {
				countries, formats, genres, persons, releases: afisha, all_qualifiers
			} = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/release/playbill?city_id=${cityId}&date=${date}
					${params && params.isKinokassa && params.hasSeveralCinemas ? '&no_seances=true' : ''}
					${params && params.isImax ? '&is_imax=true' : ''}
					${params && params.isVip ? '&is_vip=true' : ''}
					${params && params.isNotMovie ? '&qualifiers=alternative' : ''}
				`, 'GET');

			if (!Object.values(_.omit(params, ['hasSeveralCinemas', 'isKinokassa'])).some(Boolean)) {
				dispatch(setQualifiers(all_qualifiers));
			};
			dispatch(getAfishaSuccess({ afisha, countries, formats, genres, persons }));
		}
	} catch (error) {
		dispatch(setAfishaError(actionsTypes.INTERNAL_SERVICE_ERROR));
	}
};

export const getAfishaInfo = (cityId = CITY_ID, cinemaId?: string): ReduxTypes.ThunkResult<void> => async (dispatch) => {
	dispatch(getAfishaInfoRequest());

	try {
		if (cityId) {
			const requestUrl = new URL("/api/v2/release/playbill/info", KINOKASSA_API_URL);

			requestUrl.searchParams.append("city_id", `${cityId}`);
			cinemaId && requestUrl.searchParams.append("cinema_id", cinemaId);

			const response = await httpRequestPromise(requestUrl, 'GET');
			const { calendar, cinemas, discounts, seance_display_settings: seanceDisplaySettings } = response;

			dispatch(getAfishaInfoSuccess({ calendar, cinemas, discounts, seanceDisplaySettings }));
		}
	} catch (error) {
		dispatch(setAfishaError(actionsTypes.INTERNAL_SERVICE_ERROR));
	}
};
