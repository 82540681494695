import { memo } from 'react';
import * as style from 'css/release-item-details.module.scss';
import Flex from 'app/ui/Flex/Flex';
import { IRelease } from 'app/types/releases';
import { convertMinutesToString, getTitles } from 'app/selectors/Helpers';
import ReleaseBadge from 'app/components/release_item/ReleaseBadge';

interface IProps {
	release: IRelease;
}

function ReleaseCharacteristics({release}: IProps) {
	const {
		russia_start_date,
		cinema_start_date,
		age_rating, countries,
		genres, duration, rating,
		directors = [],
		cast
	} = release;
	const directorsString = getTitles(directors);
	const filteredCast = cast ? _.chain(cast).pluck('title').uniq().value().join(', ') : [];

	return (
		<Flex classes={style.releaseCharacteristics} direction="column">
			{russia_start_date || cinema_start_date ? (
				<div className={style.releaseCharacteristicsItem}>
					<span className="text text--size-12">{i18n.t('Premiere')}</span>
					{moment(cinema_start_date || russia_start_date).format('D MMMM YYYY')}
				</div>
			) : null}

			{age_rating ? (
				<div className={style.releaseCharacteristicsItem}>
					<span className="text text--size-12">{i18n.t('Возрастное ограничение')}</span>
					{age_rating}
				</div>
			) : null}

			{countries.length ? (
				<div className={style.releaseCharacteristicsItem}>
					<span className="text text--size-12">{i18n.t('Country')}</span>
					{getTitles(countries)}
				</div>
			) : null}

			{genres.length ? (
				<div className={style.releaseCharacteristicsItem}>
					<span className="text text--size-12">{i18n.t('Жанры')}</span>
					<Flex classes={style.releaseCharacteristicsGenre}>
						{genres.map(({title, id}) => (
							<ReleaseBadge title={title} key={id} />
						))}
					</Flex>
				</div>
			) : null}

			{duration ? (
				<div className={style.releaseCharacteristicsItem}>
					<span className="text text--size-12">{i18n.t('Timing')}</span>
					{convertMinutesToString(duration)}
				</div>
			) : null}

			{!_.isEmpty(rating) ? (
				<Flex classes={style.releaseCharacteristicsItem} gap="16px">
					{rating.kinopoisk
						? (
							<div className={style.releaseCharacteristicsItem}>
								<span className="text text--size-12">{i18n.t('Kinopoisk')}</span>
								{rating.kinopoisk}
							</div>
						) : null}
					{rating.imdb
						? (
							<div className={style.releaseCharacteristicsItem}>
								<span className="text text--size-12">IMDb</span>
								{rating.imdb}
							</div>
						) : null}
				</Flex>
			) : null}

			{directorsString ? (
				<div className={style.releaseCharacteristicsItem}>
					<span className="text text--size-12">{i18n.t('Director')}</span>
					{directorsString}
				</div>
			) : null}

			{cast.length ? (
				<div className={style.releaseCharacteristicsItem}>
					<span className="text text--size-12">{i18n.t('Cast')}</span>
					{filteredCast}
				</div>
			) : null}
		</Flex>
	)
}

export default memo(ReleaseCharacteristics);