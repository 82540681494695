/* eslint-disable max-len */
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import Popup from 'app/components/PopupComponent';
import { AVRORA_CINEMA_ID } from 'app/constants/CinemaConstants';
import { closeIcon } from 'app/constants/IconsConstants';
import { setYaReachGoal } from 'app/selectors/Helpers';
import Textarea from 'app/ui/Textarea';
import React, { Component } from 'react';
import { Portal } from 'react-portal';

interface IProps {
	addFeedback: (cinemaID: number, text: {text: string}) => void;
	cinemaId: number;
	isMonitor: boolean;
	cityTitle: string;
}

export default class FeedbackComponent extends Component<IProps> {
	state = {
		isOpen: false,
		isSuccess: false,
		text: {
			text: '',
			isValid: true,
		},
	};

	getTitle = () => {
		const {cinemaId} = this.props;
		if (cinemaId === AVRORA_CINEMA_ID) {
			return i18n.t('Feedback');
		}
		return i18n.t('WriteReview');
	}

	openPopup = () => {
		setYaReachGoal('open-write-review');
		this.setState({isOpen: true});
	}

	hidePopup = () => this.setState({isOpen: false, isSuccess: false})

	handleChangeFeedback = text => this.setState({text: {text, isValid: this.validateText(text)}})

	validateText = text => text.length > 0

	handleClick = () => {
		const {text} = this.state;
		const {addFeedback, cinemaId, cityTitle, isMonitor} = this.props;
		const isValid = this.validateText(text.text);

		this.setState({
			isSuccess: isValid,
			text: {text: text.text, isValid: isValid},
		});

		if (isValid) {
			setYaReachGoal('send-review');
			addFeedback(cinemaId, {
				text: isMonitor ? `${i18n.t('City')}: ${cityTitle}\n${text.text}` : text.text,
			});
		}
	}

	addMoreFeedback = () => this.setState({isSuccess: false, text: {text: '', isValid: true}})

	renderDescription = () => {
		const {isMonitor, cinemaId} = this.props;
		if (isMonitor) {
			return (
				<React.Fragment>
					Оцените качество сервиса сети кинотеатров&nbsp;«Монитор»,
					оставьте отзыв о нашей работе или&nbsp;задайте&nbsp;вопрос.
					<br />
					Спасибо, что помогаете нам становиться лучше.
				</React.Fragment>
			);
		}
		if (cinemaId === AVRORA_CINEMA_ID) {
			return (
				<React.Fragment>
					Делитесь впечатлениями от похода&nbsp;в&nbsp;наш&nbsp;кинотеатр
					и направляйте коммерческие предложения (заявки&nbsp;на&nbsp;аренду помещения).
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				{i18n.t('ShareYourExperience')} {i18n.t('of')}&nbsp;{i18n.t('visit')} {i18n.t('at')}&nbsp;{i18n.t('our')} {i18n.t('cinema')}.&nbsp;
				{i18n.t('HelpUsToImprovePerfomance')}.
			</React.Fragment>
		);
	}

	renderBody = () => {
		const {isMonitor} = this.props;
		const {text, isSuccess} = this.state;

		return (
			<>
				{isSuccess
					? <div className="popup__content__body">
						<div className="feedback__success">
							<img src="/img/badge/feedback/check.svg" alt="check svg" className="feedback__success__img" />
							<div className="feedback__success__text">
								{isMonitor
									? <React.Fragment>
										Отзыв отправлен! <br />
										Сеть кинотеатров «Монитор» благодарит вас за ваше внимание и<br />
										оставленные на сайте отзывы и комментарии.<br />
										Мы не оставим вас без внимания, а в случае указания<br />
										контактных данных гарантируем обратную связь.<br />
									</React.Fragment>
									: i18n.t('FeedbackSent')
								}
							</div>
							<div className="feedback__success__text feedback__success__text--click">
								<span className="feedback__more-click" onClick={this.addMoreFeedback}>
									{i18n.t('SendOneMore')}
								</span>
							</div>
						</div>
					</div>
					: <div className="popup__content__body">
						<div className="feedback__description">
							{this.renderDescription()}
						</div>
						<div className="feedback__box-form">
							<div className="feedback__box-form__label">{i18n.t('MessageText')}*</div>
							<Textarea
								value={text.text}
								isValid={text.isValid}
								onChange={this.handleChangeFeedback}
								placeholder={i18n.t('YourFeedback')}
								width="100%"
							/>
						</div>
					</div>
				}
			</>
		);
	}

	renderBottom = () => {
		const {isSuccess} = this.state;

		return !isSuccess &&
			<div className="popup__content__bottom">
				<div className="g-mobile-hide">
					<KeydownEnterButton
						className="button button--secondary text--medium"
						onClick={this.hidePopup}
					>
						{i18n.t('Cancel')}
					</KeydownEnterButton>
				</div>
				<KeydownEnterButton
					className="button button--primary text--medium"
					onClick={this.handleClick}
				>
					{i18n.t('Send')}
				</KeydownEnterButton>
			</div>
	}

	renderContent = () => (
		<div className="popup__content">
			<div className="popup__content__body">
				<div>
					<div className="popup__content__header">
						{this.getTitle()}
						<div className="popup__exit" onClick={this.hidePopup}>
							{closeIcon}
						</div>
					</div>
					{this.renderBody()}
					{this.renderBottom()}
				</div>
			</div>
		</div>
	);

	render() {
		const {isOpen} = this.state;

		return (
			<div className="feedback">
				<KeydownEnterButton className="feedback__item" onClick={this.openPopup}>
					{this.getTitle()}
				</KeydownEnterButton>
				<Portal>
					<Popup
						hidePopup={this.hidePopup}
						withCloseButton={false}
						popupContentNode={isOpen ? this.renderContent() : null}
					/>
				</Portal>
			</div>
		);
	}
}
