import React from 'react';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import WidgetOverlay from 'app/containers/WidgetOverlayContainer';
import Counter from 'app/containers/NotificationsCounterContainer';
import { loginIcon } from "app/constants/IconsConstants";

interface IProps {
	cinemaID: number;
	isUserAuth: boolean;
	isAccountEnabled: boolean;
	isWidgetInKioskMode: boolean;
	withIcon?: boolean;
}

function LoginButton({ cinemaID, isUserAuth, isAccountEnabled, isWidgetInKioskMode, withIcon = false }: IProps) {
	if (!isAccountEnabled || isWidgetInKioskMode) {
		return null;
	}

	return (
		<div className="login-btn-wrapper">
			<WidgetOverlay
				cinemaId={cinemaID}
				page={isUserAuth ? 'profile-button' : 'login-button'}
				isSoldOut={false}
				doNotSendAnalytics
			/>
			{!isUserAuth
				? (
					<KeydownEnterButton className="button button--accent auth-btn" onClick={_.noop}>
						{withIcon ? loginIcon : null}
						{i18n.t('Login')}
					</KeydownEnterButton>
				)
				: (
					<KeydownEnterButton className="login-btn" onClick={_.noop}>
						{/* TODO: поменять img на {userIcon} для редизайна */}
						<img src="/img/user.svg" alt="Профиль" width={25} height={25} />
						<Counter />
					</KeydownEnterButton>
				)}
		</div>
	);
}

export default LoginButton;
