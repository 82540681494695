import React from 'react';
import PropTypes from 'prop-types';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {parsePhone, setYaReachGoal} from 'app/selectors/Helpers';
import {connect} from 'react-redux';
import {getIsArman3D} from 'app/selectors/AppSelectors';

const FondKinoPhoneItem = ({value, comment, isArman3d}) => (
	<KeydownEnterLink
		type="plain"
		className="header__text"
		href={`${!isArman3d ? 'tel:' : 'https://wa.me/'}${parsePhone(value)}`}
		onClick={() => setYaReachGoal('phone-click')}
	>
		{comment && <span className="header__text-title">{comment}</span>}
		<span className="header__text-addition">{value}</span>
	</KeydownEnterLink>
);

FondKinoPhoneItem.propTypes = {
	value: PropTypes.string,
	comment: PropTypes.string,
	isArman3d: PropTypes.bool
};

export default connect(state => ({isArman3d: getIsArman3D(state)}))(FondKinoPhoneItem);
