import { createSelector } from 'reselect';
import { getTitles } from 'app/selectors/Helpers';
import { IReduxState } from "app/types/state";

export const getSeancesOfReleaseItem = (state: IReduxState) => state.releaseItemReducer.seances;
export const getIsFetchingReleaseItemSeances = (state: IReduxState) => state.releaseItemReducer.isFetching;

export const getFilteredSeancesOfReleaseItem = createSelector(
	[getSeancesOfReleaseItem, (_, modifier: string | undefined) => modifier],
	(seances, modifier) => {
		if (!modifier) return seances;

		return seances.filter(seance => {
			if (modifier === 'imax') return getTitles(seance.formats).includes('IMAX');
			if (modifier === 'vip') return seance.hall?.is_vip;

			return true;
		});
	},
);
