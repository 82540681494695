import { connect } from 'react-redux';
import {
	getCinema, getCinemasModules, getIsUserAuth, getIsWidgetInKioskMode
} from 'app/selectors/AppSelectors';
import LoginButton from 'app/components/LoginButton';

const mapStateToProps = state => ({
	cinemaID: getCinema(state).id,
	isUserAuth: getIsUserAuth(state),
	isAccountEnabled: getCinemasModules(state).account_enabled,
	isWidgetInKioskMode: getIsWidgetInKioskMode(state),
});

export default connect(mapStateToProps)(LoginButton);
