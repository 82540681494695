import { memo } from 'react';
import * as style from 'css/release-badge.module.scss';
import cn from 'classnames';
import { BadgeType } from 'app/components/release_item/types';

interface IProps {
	title: string;
	type?: BadgeType;
}

function ReleaseBadge({title, type}: IProps) {
	const classes = cn(`text--size-12 ${style.releaseBadge}`, {
		[style.badgeOpacityAccent]: type === BadgeType.accent,
		[style.badgeOpacityWhite]: type === BadgeType.white,
	});

	return (
		<div className={classes}>
			{title}
		</div>
	)
}

export default memo(ReleaseBadge);