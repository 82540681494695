import React, {Component} from 'react';
import PropTypes from 'prop-types';

export const isEnterPress = (event) => event.keyCode === 13;
export const isEscPress = (event) => event.keyCode === 27;

export class ClickOnKeyPressWrap extends Component {
	static propTypes = {
		children: PropTypes.node.isRequired,
		clickCondition: PropTypes.func.isRequired
	};

	static defaultProps = {
		clickCondition: isEnterPress
	};

	componentDidMount() {
		window.addEventListener('keyup', this.handleKeyPress);
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.handleKeyPress);
	}

	handleKeyPress = (event) => {
		if (this.props.clickCondition(event)) {
			this.node.click();
		}
	}

	render() {
		return React.cloneElement(this.props.children, {ref: node => { this.node = node; }});
	}
}
