import React from 'react';
import { setYaReachGoal, getIsWidgetDisabledInSpecificCinema } from 'app/selectors/Helpers';
import { RAMBLER_WIDGET_KEY } from 'app/constants/SettingsConstants';
import { WidgetOverlayProps } from 'app/containers/WidgetOverlayContainer';

const WidgetOverlay = ({
	cinemaId,
	releaseId,
	seanceId,
	startTime,
	ramblerReleaseId,
	page,
	cinemasForRambler,
	isWidgetDisabled,
	isSoldOut,
	isKinokassa,
	doNotSendAnalytics
}: WidgetOverlayProps) => {
	const handleClick = (event) => {
		event.stopPropagation();

		if (isWidgetDisabled || isSoldOut) return;

		if (RAMBLER_WIDGET_KEY) {
			if (!window.ticketManager) return;

			const currentCinema = _.findWhere(cinemasForRambler, { id: cinemaId });

			if (currentCinema?.rambler_id && ramblerReleaseId && seanceId) {
				const { rambler_external: ramblerExternal, rambler_id: ramblerId } = currentCinema;
				const time = moment.parseZone(startTime).format('DD-MM-YYYY-HHmm');

				if (ramblerExternal) {
					window.ticketManager.hallPlanV2(ramblerId, ramblerReleaseId, time);
				} else {
					window.ticketManager.sourceSession(RAMBLER_WIDGET_KEY, seanceId, ramblerId);
				}
			}
		}

		if (!doNotSendAnalytics) {
			setYaReachGoal('seance-click', {
				section: page,
				source: 'Сайт'
			});
		}
	};

	return (
		<div
			tabIndex={0}
			className="widget-overlay"
			onClick={handleClick}
			data-action={
				RAMBLER_WIDGET_KEY
					|| isKinokassa
					|| (isWidgetDisabled || isSoldOut)
					|| getIsWidgetDisabledInSpecificCinema(cinemaId)
					? '' : 'open-widget'}
			data-release-id={releaseId}
			data-seance-id={seanceId}
			data-current-cinema-id={cinemaId}
			data-kinosite-section={page}
		/>
	);
};

export default WidgetOverlay;
