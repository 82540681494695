import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Button extends React.PureComponent {
	static propTypes = {
		onClick: PropTypes.func,
		isDisabled: PropTypes.bool,
		kind: PropTypes.oneOf(['secondary', 'primary', 'info', 'transparent']),
		children: PropTypes.node,
		type: PropTypes.string,
		classes: PropTypes.string
	};

	static defaultProps = {
		onClick: null,
		isDisabled: false,
		kind: 'secondary',
		type: 'button',
		classes: null,
		children: null
	};

	onClick = (event) => {
		const {onClick} = this.props;
		if (_.isFunction(onClick)) {
			onClick(event);
		}
	};

	render() {
		const {
			isDisabled,
			type,
			kind,
			children,
			classes
		} = this.props;

		const btnClass = classNames('button', classes, {
			[`button--${kind}`]: kind,
			'disabled': isDisabled
		});

		return (
			<button
				className={btnClass}
				onClick={isDisabled ? null : this.onClick}
				type={type}
			>
				{children}
			</button>
		);
	}
}
