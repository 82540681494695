import {CSSProperties, memo, useCallback, useRef, useState} from 'react';
import Flex from 'app/ui/Flex/Flex';
import { setYaReachGoal } from 'app/selectors/Helpers';
import { useSelector } from 'react-redux';
import { getCinema } from 'app/selectors/AppSelectors';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';


interface IThumbnail {
	thumbnail: string,
	original: string,
}

interface IProps {
	items: IThumbnail[],
	releaseID: number,
	releaseTitle: string
}

function ReleaseTrailer({items, releaseID, releaseTitle}: IProps) { 
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isShowPlay, setIsShowPlay] = useState(true);
	const cityTitle = useSelector(getCinema).city;

	const handleClickTrailer = useCallback((event) => {
		event.preventDefault();

		if (videoRef?.current?.paused) {
			setIsShowPlay(false);

			try {
				videoRef?.current?.play();
			} catch(error) {
				throw error;
			}
		} else {
			setIsShowPlay(true);

			try {
				videoRef?.current?.pause();
			} catch(error) {
				throw error;
			}
		}

		setYaReachGoal('open-trailer', { 
			movie: releaseTitle,
			release_id: releaseID,
			city: cityTitle,
		});
	}, [videoRef, releaseTitle, releaseID, cityTitle]);

	const renderSlide = (props) => {
		return props.original
			? (
				<div onClick={handleClickTrailer} className="release-trailer-item">
					<>
						<video poster={props.thumbnail} width="775" controls muted playsInline ref={videoRef}>
							<source src={props.original} type="video/mp4" />
						</video>
						{isShowPlay ? <div className="release-trailer-play">
							<img src="/img/play.svg" width={30} height={34} />
						</div> : null}
					</>
				</div>
			) : (
				<div className="release-trailer-item">
					<img src={props.thumbnail} alt="скриншот" />
				</div>
			)
	};

	const renderThumb = (props) => (
		<div
			className="release-trailer-tubs-item"
			style={{'--trailer-tubs-url': `url(${props.thumbnail})`} as CSSProperties}
		/>
	)

	return (
		<Flex direction="column" classes="release-slider">
			<ImageGallery 
				items={items}
				showFullscreenButton={false}
				showPlayButton={false}
				lazyLoad
				infinite={false}
				renderItem={renderSlide}
				renderThumbInner={renderThumb}
				preventDefaultTouchmoveEvent
			/>
		</Flex>
	)
}

export default memo(ReleaseTrailer);
