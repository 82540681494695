import { NEVELSK_CINEMA_ID } from 'app/constants/CinemaConstants';
import { WorkHours } from 'app/types/common';
import React from 'react';

interface IProps {
	time: WorkHours,
	cinemaID: number | null,
	openingHours: string | null
}

function WorkTime({time, cinemaID, openingHours}: IProps) {
	const {
		open_time: open,
		close_time: close,
		weekend_open: weekendOpen,
		weekend_close: weekendClose,
	} = time || {};
	const isDifferentWorkTimeOnWeekend = open !== weekendOpen || close !== weekendClose;
	const customSchedule = (cinemaID && cinemaID === NEVELSK_CINEMA_ID)
		? `${i18n.t('Days.Short.Thur')} - ${i18n.t('Days.Short.Sun')}`
		: i18n.t('Daily');

	return (
		<>
			{openingHours && openingHours.trim()
				? (
					<>{openingHours.split('\n').map(line => (<span key={line}>{line}</span>))}</>
				)
				: (
					<>
						<span>{customSchedule} {i18n.t('from')} {open} {i18n.t('until')} {close}</span>
						{isDifferentWorkTimeOnWeekend && (
							<span>{i18n.t('AtWeekend')} {i18n.t('from')} {weekendOpen} {i18n.t('until')} {weekendClose}</span>
						)}
					</>
				)
			}
		</>
	);
};

export default WorkTime;