import {setYaReachGoal} from 'app/selectors/Helpers';
import { IMenu } from 'app/types/common';
import classNames from 'classnames';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';

interface IProps {
	link: IMenu,
	className: string,
	onClick: () => void;
}

const MenuItem = ({link, className, onClick}: IProps) => {
	const handleClick = (event) => {
		const link = event.target.getAttribute('href');
		const {isCustomCinemaLink, linkTitle} = event.target.dataset;

		if (link === '/') {
			setYaReachGoal('afisha-click', null, false);
		} else if (link === '/cinema') {
			setYaReachGoal('cinema-click', null, false);
		}

		if (isCustomCinemaLink) {
			setYaReachGoal('open-section', {section: linkTitle});
		}

		onClick();
	};

	return (
		<KeydownEnterLink
			type="navLink"
			to={link.href}
			exact
			activeClassName="main-nav__item--active"
			className={classNames(`${className} text text--inter`)}
			data-is-custom-cinema-link={link.isCustomCinemaLink}
			data-link-title={link.sub && link.sub.length ? link.sub[0].title : link.title}
			onClick={handleClick}
		>
			<>{link.title}</>
		</KeydownEnterLink>
	);
}

export default MenuItem;
