import {connect} from 'react-redux';
import {getAdvertisementsList} from 'app/selectors/AdvertisementsSelectors';
import {setAdvertisements} from 'app/actions/AdvertisementsActions';
import AdvertisementsComponent from 'app/components/AdvertisementsComponent';
import {showPopup, hidePopup} from 'app/actions/AppActions';

const mapStateToProps = state => ({
	advertisements: getAdvertisementsList(state)
});

export default connect(mapStateToProps, {setAdvertisements, showPopup, hidePopup})(AdvertisementsComponent);
