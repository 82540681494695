import { PaymentCard } from "app/types/common";

export const currencySymbols = {
	RUB: '₽',
	KGS: 'с',
	KZT: '₸',
	UZS: 'сум',
	TJS: 'с.',
};

export const russianDefaultPaymentCards = [PaymentCard.MIR, PaymentCard.VISA, PaymentCard.MASTERCARD, PaymentCard.MAESTRO, PaymentCard.SBP, PaymentCard.SBERPAY];
export const russianPaymentCardsNarrowedSet = [PaymentCard.MIR, PaymentCard.VISA, PaymentCard.MASTERCARD, PaymentCard.MAESTRO];
export const kazakhstanDefaultPaymentCards = [PaymentCard.VISA, PaymentCard.MASTERCARD, PaymentCard.MAESTRO];
export const kazakhstanPaymentCardsWithKaspi = [PaymentCard.VISA, PaymentCard.MASTERCARD, PaymentCard.MAESTRO, PaymentCard.KASPI];
export const uzbekistanDefaultPaymentCards = [PaymentCard.VISA, PaymentCard.MASTERCARD, PaymentCard.MAESTRO];