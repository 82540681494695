import { Cinema } from "app/types/common";
import { useEffect } from "react";

function ApplicationRedirectPage({appLinks}: {appLinks: Cinema['app']}) {
	const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
	const appLink = isIOS ? appLinks.appstore : appLinks.googleplay;

	useEffect(() => {
		if (appLink) {
			window.location.href = appLink;
		}
	}, [appLink]);

	return null;
}

export default ApplicationRedirectPage;