import React from 'react';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {setYaReachGoal, parsePhone} from 'app/selectors/Helpers';

interface IProps {
	phoneNumber: string,
	comment: string,
	isPhoneList?: boolean,
}

const PhoneNumber = React.memo(({phoneNumber, comment, isPhoneList = false}: IProps) => (
	<div className={`phone-number text text--size-12 ${isPhoneList ? ' text--black' : ''}`}>
		{!!comment &&
			<div className={`text text--${isPhoneList ? 'comment' : 'addition'}`}>
				{comment}:
			</div>
			}
		<KeydownEnterLink
			type="plain"
			data-icon="phone"
			href={`tel:${parsePhone(phoneNumber)}`}
			onClick={() => setYaReachGoal('phone-click')}
		>
			{phoneNumber}
		</KeydownEnterLink>
	</div>
));

export default PhoneNumber;
