import { IReduxState } from 'app/types/state';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorBoundary from 'app/components/ErrorBoundary';
import { getAppErrorCode, getCurrentCityTitle, getAddresses } from 'app/selectors/AppSelectors';
import { setAppError } from 'app/actions/AppActions';

const mapStateToProps = (state: IReduxState) => ({
	errorCode: getAppErrorCode(state),
	currentCityTitle: getCurrentCityTitle(state),
	address: getAddresses(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	setError: (errorCode) => setAppError(errorCode),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
