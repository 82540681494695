import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
	FILTER_PANEL_ELEMENTS,
	FilterValueType,
	MULTISELECTABLE_FILTERS,
	FiltersPage
} from "../types";
import { DatePicker } from "../components/DatePicker";
import FilterPanelElement from "app/modules/filters/components/FilterPanelElement";
import classnames from 'classnames';
import { DesktopFilterPopup } from "app/modules/filters/components/DesktopFilterPopup";
import { ToggleWithText } from "app/modules/filters/components/ToggleWithText";
import { calendarIcon, filtersIcon } from "app/constants/IconsConstants"; 
import PopupComponent from "app/components/PopupComponent";
import { getActiveElementId, getCurrentPage, getCurrentSortType, getPushkinCardFilterValue } from "../redux/selectors";
import { getCurrentFilterPanelElements, getSelectedDay, isMobileSelector } from "app/selectors/AppSelectors";
import { resetFilters, setActiveElementId, updateFilterValue } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPopup } from "app/modules/filters/helpers";

type IProps = {
	seanceDates?: string[];
	isDisabled?: boolean;
	onDateChange?: (date: string) => void;
};

export const Filters = ({
	seanceDates,
	isDisabled,
	onDateChange,
} : IProps) => {
	const dispatch = useDispatch();

	const activeElementId = useSelector(getActiveElementId);
	const hasNeedPushkinCard = useSelector(getPushkinCardFilterValue);
	const currentSortType = useSelector(getCurrentSortType);
	const selectedDay = useSelector(getSelectedDay);
	const page = useSelector(getCurrentPage);
	const currentFilters = useSelector(getCurrentFilterPanelElements);
	const isMobile = useSelector(isMobileSelector);

	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		dispatch(resetFilters([FILTER_PANEL_ELEMENTS.CINEMA]));
	}, [selectedDay])

	useEffect(() => {
		dispatch(resetFilters(Object.values(FILTER_PANEL_ELEMENTS)));
	}, [page])

	const handleToggleChange = useCallback(() => {
		dispatch(updateFilterValue(FILTER_PANEL_ELEMENTS.PUSHKIN_CARD, {id: !hasNeedPushkinCard}, FilterValueType.MAIN))
	}, [hasNeedPushkinCard]);

	const hidePopup = useCallback(() => {
		dispatch(setActiveElementId());
	}, []);

	const currentPopup = useMemo(
		() => getCurrentPopup(activeElementId, seanceDates, onDateChange),
		[activeElementId, seanceDates, onDateChange]
	);

	return (
		<div className={classnames("filter-panel-wrapper container", {
			"filter-panel-wrapper--active": activeElementId,
			"filters-container": page === FiltersPage.RELEASE,
		})}>
			{activeElementId && !isMobile && <div onClick={hidePopup} className="popup-overlay" style={{zIndex: -1}}/>}
			<div ref={containerRef} className={classnames("filter-panel", {
				"filter-panel--mobile": isMobile,
				"filter-panel--disabled": isDisabled,
			})}>
				{currentFilters.map((key) => {
					switch (true) {
						case key === FILTER_PANEL_ELEMENTS.CALENDAR_MOBILE:
							return (
								<FilterPanelElement
									key={key}
									title={selectedDay?.format("D MMM") || ""}
									id={key}
									activeElementId={activeElementId}
									icon={calendarIcon}
								/>
							)
						case key === FILTER_PANEL_ELEMENTS.MOBILE_ALL_FILTERS:
							return (
								<FilterPanelElement
									key={key}
									title={i18n.t("Filters")}
									id={key}
									activeElementId={activeElementId}
									icon={filtersIcon}
								/>
							)
						case key === FILTER_PANEL_ELEMENTS.CALENDAR && page !== FiltersPage.SOON:
							return (
								<DatePicker
									key={key}
									seanceDates={seanceDates}
									onDateChange={onDateChange}
								/>
							)
						case key === FILTER_PANEL_ELEMENTS.PUSHKIN_CARD:
							return (
								<ToggleWithText
									key={key}
									title={i18n.t("PushkinCard")}
									isActive={hasNeedPushkinCard}
									onChange={handleToggleChange}
								/>
							)
						case key === FILTER_PANEL_ELEMENTS.SORTING:
							return (
								<FilterPanelElement
									title={i18n.t(`SortType.${currentSortType}`)}
									id={key}
									activeElementId={activeElementId}
									placeholder={i18n.t("SortType.DEFAULT")}
								/>
							)
						case MULTISELECTABLE_FILTERS.includes(key as any):
							return (
								<FilterPanelElement
									key={key}
									title={i18n.t(`FiltersTitles.${key}`)}
									id={key}
									activeElementId={activeElementId}
								/>
							)
						default:
							break;
					}
				})}
			</div>
			{
				isMobile ? (
					<PopupComponent
						withCloseButton
						mobileTitle={currentPopup.title}
						popupContentNode={currentPopup.content}
						mobileViewBreakpoint={Infinity}
						hidePopup={hidePopup}
					/>
				) : (
					activeElementId && <DesktopFilterPopup
						activeElementId={activeElementId}
						containerRef={containerRef}
						pupupContent={currentPopup.content}
					/>
				)
			}
		</div>
	)
}
