import classnames from 'classnames';

export const ToggleWithText = ({
	title,
	isActive,
	onChange
}: {
	title: string;
	isActive: boolean;
	onChange: () => void;
}) => {
	return (
		<label className={classnames("toggle", {
			"toggle--active": isActive
		})}>
			<div className="toggle__slider-container">
				<span className="toggle__slider"/>
			</div>
			<span className="toggle__title">{title}</span>
			<input
				type="checkbox"
				onChange={onChange}
				checked={isActive}
				className={"toggle__input"}
				role="switch"
			/>
		</label>
	)
}