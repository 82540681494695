import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withRouter} from 'react-router-dom';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {setYaReachGoal} from 'app/selectors/Helpers';

const PromoListItemComponent = ({
	item,
	prefix,
	others = false,
	match: {params},
	page
}) => {
	const handleAnalyticsClick = () => {
		setYaReachGoal('open-choice', {section: page});
	};

	const withCinemaTitles = !params.cinemaId && item.cinemaTitles;

	return (
		<KeydownEnterLink
			to={params.cinemaId ? `${prefix}/cinemas/${params.cinemaId}/promo/${item.id}` : `${prefix}/promo/${item.id}`}
			data-title={item.title}
			className={classNames('promo-item', {'promo-item__others': others})}
			style={{height: `${withCinemaTitles && '356px'}`}}
			onClick={handleAnalyticsClick}
		>
			<div className="promo-item__poster">
				<div className="promo-item__poster-height" />
				<img src={item.img} className="promo-item__poster-img" alt={i18n.t('PromoPoster')} />
			</div>
			<div
				className="promo-item__info"
				style={{height: `${withCinemaTitles && '180px'}`}}
			>
				<div className="promo-item-description">
					{withCinemaTitles &&
						<div className="promo-item-description__cinemas">
							{item.cinemaTitles}
						</div>}
					<div
						className={classNames('promo-item-description__title', {
							'promo-item-description__title-others': others
						})}
					>
						{item.title}
					</div>
					<div className="promo-item-description__period">{item.description}</div>
				</div>
			</div>
		</KeydownEnterLink>
	);
};

PromoListItemComponent.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
		cinemaTitles: PropTypes.string,
		description: PropTypes.string,
		img: PropTypes.string
	}).isRequired,
	others: PropTypes.bool,
	prefix: PropTypes.string.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			cinemaId: PropTypes.string
		})
	}),
	page: PropTypes.string
};

export default withRouter(PromoListItemComponent);
