import React, {useState, useCallback} from 'react';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import CitiesListSelectPopup from 'app/components/CitySelect/CitiesListSelectPopup';
import {arrowIconDownGray, pinIcon} from 'app/constants/IconsConstants';
import classNames from 'classnames';
import { Cinema } from 'app/types/common';

interface IProps {
	isDesktopView: boolean,
	showPopup: (content: {popupContentNode: JSX.Element}) => void,
	hasCurrentCityId: boolean,
	currentCityTitle: string,
	cinemasForCurrentCity: Cinema[],
}

const CitySelectComponent = ({
	isDesktopView,
	showPopup,
	hasCurrentCityId,
	currentCityTitle,
}: IProps) => {
	const [isCityCheckWrapperOpen, setIsOpen] = useState(!hasCurrentCityId);

	// TODO: вернуть для нового попапа
	// const cinemaTitle = cinemasForCurrentCity[0].title;
	const cityCheckWrapperClassName = classNames('city-select__city-check', 'city-check-wrapper', {
		'city-select__city-check--mobile': !isDesktopView,
		'city-select__city-check--open': isCityCheckWrapperOpen,
	});
	const citySelectClasses = classNames('city-select__current-city', {
		'city-select__current-city--mobile': !isDesktopView
	});

	const closeCityCheckWrapper = (event) => {
		event.stopPropagation();

		setIsOpen(false);
	};

	const handleClick = useCallback((event) => {
		event.stopPropagation();

		if (isCityCheckWrapperOpen) {
			setIsOpen(false);
		}

		showPopup({
			popupContentNode: <CitiesListSelectPopup className="city-select__popup-content" isOpen={true} />,
		});
	}, []);

	return (
		<div className="city-select city-select--new" onClick={handleClick}>
			<KeydownEnterButton className="city-select__btn-toggle" onClick={_.noop}>	
				<span className="city-select__icon">{pinIcon}</span>
				<div className="city-select__current-city-info">
					<div className={citySelectClasses}>{currentCityTitle}</div>
					{/* <div className="city-select__title">
						«{cinemaTitle}»
					</div> */}
				</div>
				<span className="city-select__icon">{arrowIconDownGray}</span>
			</KeydownEnterButton>
			<div className={cityCheckWrapperClassName}>
				<h4 className="city-check-wrapper__question-title">
					{i18n.t('YourCity')} <span>{currentCityTitle}</span>?
				</h4>
				<div className="city-check-wrapper__buttons-section">
					<KeydownEnterButton
						className="custom-button"
						onClick={closeCityCheckWrapper}
					>
						{i18n.t('Correct')}
					</KeydownEnterButton>
					<KeydownEnterButton
						className="custom-button custom-button--secondary city-check-wrapper__btn-other-city"
						onClick={handleClick}
					>
						{i18n.t('Another', {count: 1})}
					</KeydownEnterButton>
				</div>
			</div>
		</div>
	);
};

export default CitySelectComponent;
