import { IReduxState } from "app/types/state";
import { createSelector } from "reselect";
import { FILTER_PANEL_ELEMENTS, SortTypes, IFilterValue, MULTISELECTABLE_FILTERS, IFiltersState } from "../types";

export const getFilters = (state: IReduxState) => state.filtersReducer.selectedValues;
export const getLocalFilters = (state: IReduxState) => state.filtersReducer.localSelectedValues;
export const getActiveElementId = (state: IReduxState) => state.filtersReducer.acriveElementId;
export const getGenreFilterValues = (state: IReduxState) => state.filtersReducer.selectedValues.genres;
export const getAgeRatingsFilterValues = (state: IReduxState) => state.filtersReducer.selectedValues.ageRatings;
export const getFeaturesFilterValues = (state: IReduxState) => state.filtersReducer.selectedValues.features;
export const getCinemasFilterValues = (state: IReduxState) => state.filtersReducer.selectedValues.cinemas;
export const getPushkinCardFilterValue = (state: IReduxState) => state.filtersReducer.selectedValues.pushkinCard;
export const getPushkinCardLocalFilterValue = (state: IReduxState) => state.filtersReducer.localSelectedValues.pushkinCard;
export const getCurrentSortType = (state: IReduxState) => state.filtersReducer.selectedValues.sortType;
export const getCurrentPage = (state: IReduxState) => state.filtersReducer.page;

export const getSortTypesLabels = () => (Object.values(SortTypes)).map((id) => ({
	title: i18n.t(`SortType.${id}`),
	id,
	filterKey: FILTER_PANEL_ELEMENTS.SORTING
} as IFilterValue));

export const getFiltersOfReleases = createSelector(
	[getGenreFilterValues, getAgeRatingsFilterValues, getFeaturesFilterValues, getPushkinCardFilterValue],
	(genres, ageRatings, features, hasNeedPushkinCard) => ({
		[FILTER_PANEL_ELEMENTS.GENRE]: genres,
		[FILTER_PANEL_ELEMENTS.AGE]: ageRatings,
		[FILTER_PANEL_ELEMENTS.FEATURES]: features,
		[FILTER_PANEL_ELEMENTS.PUSHKIN_CARD]: hasNeedPushkinCard
	})
);

export const getSelectedFiltersToDisplaying = createSelector(
	[getFilters],
	(filters) => {
		let result: Array<{
			title: string;
			filterKey: keyof IFiltersState["selectedValues"];
			id: string | boolean;
		}> = _.compact([
			filters.pushkinCard && {
				title: i18n.t("PushkinCard"),
				filterKey: FILTER_PANEL_ELEMENTS.PUSHKIN_CARD,
				id: !filters.pushkinCard
			}
		]);

		MULTISELECTABLE_FILTERS.forEach((filterKey) => {
			const filterRecord = filters[filterKey];

			if (filterRecord) {
				result = result.concat(Object.values(filterRecord));
			}
		});

		return result;
	}
);