import React, {memo, useMemo} from 'react';
import classNames from 'classnames';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import {setYaReachGoal} from 'app/selectors/Helpers';
import {
	instagramIcon,
	facebookIcon,
	odnoklassnikiIcon,
	twitterIcon,
	vkontakteIcon,
	youtubeIcon,
	telegramIcon,
	instagramIconColor,
	facebookIconColor,
	odnoklassnikiIconColor,
	twitterIconColor,
	vkontakteIconColor,
	youtubeIconColor,
	telegramIconColor,
} from 'app/constants/IconsConstants';
import { Socials } from 'app/types/common';

interface IProps {
	className: string,
	linksData: Socials,
	isColorIcons?: boolean,
	showTitles?: boolean,
};

const SocialLinks = ({
	className: socialLinksClassName,
	linksData: {
		facebook,
		instagram,
		twitter,
		vk,
		ok,
		youtube,
		telegram,
	},
	isColorIcons = false,
	showTitles = true,
}: IProps) => {
	const socialLinks = useMemo(() => [
		{
			id: 'inst',
			href: instagram,
			icon: isColorIcons ? instagramIconColor : instagramIcon,
			dataIcon: 'inst',
			title: 'Instagram',
		},
		{
			id: 'fb',
			href: facebook,
			icon: isColorIcons ? facebookIconColor : facebookIcon,
			dataIcon: 'fb',
			title: 'Facebook',
		},
		{
			id: 'vk',
			href: vk,
			icon: isColorIcons ? vkontakteIconColor : vkontakteIcon,
			dataIcon: 'vk',
			title: i18n.t('Social.VK'),
		},
		{
			id: 'tw',
			icon: isColorIcons ? twitterIconColor : twitterIcon,
			href: twitter,
			title: 'Twitter',
		},
		{
			id: 'odnkl',
			icon: isColorIcons ? odnoklassnikiIconColor : odnoklassnikiIcon,
			href: ok,
			title: i18n.t('Social.Odnoklassniki'),
		},
		{
			id: 'ytb',
			icon: isColorIcons ? youtubeIconColor : youtubeIcon,
			href: youtube,
			title: 'Youtube',
		},
		{
			id: 'tg',
			icon: isColorIcons ? telegramIconColor : telegramIcon,
			href: telegram,
			title: 'Telegram',
		},
	], [isColorIcons]);

	const handleClick = (event) => {
		const {icon} = event.currentTarget.dataset;

		setYaReachGoal(`${icon}-click`);
	};

	const linkItemClassName = classNames(`${socialLinksClassName}-item`, {
		'social-icon--hover-opacity': !showTitles,
	});

	return (
		<div className={socialLinksClassName}>
			{socialLinks.map(({id, href, icon, dataIcon, title}) => (
				href && (
					<KeydownEnterLink
						key={id}
						type="plain"
						href={href}
						className={linkItemClassName}
						target="_blank"
						rel="noopener noreferrer"
						data-icon={dataIcon || ''}
						onClick={dataIcon ? handleClick : _.noop}
					>
						<>
							{icon}
							{showTitles && <span>{title}</span>}
						</>
					</KeydownEnterLink>
				)
			))
			}
		</div>
	);
};

export default memo(SocialLinks);