import classnames from 'classnames';
import { FilterPanelElementsType } from "../types";
import { arrowIconDownGray } from 'app/constants/IconsConstants';
import { memo } from 'react';
import { setActiveElementId } from "../redux/actions";
import { useDispatch } from 'react-redux';

const FilterPanelElement = ({
	title,
	id,
	activeElementId,
	placeholder,
	icon,
}: {
	title: string;
	id: FilterPanelElementsType;
	activeElementId: FilterPanelElementsType | null;
	placeholder?: string;
	icon?: React.ReactNode;
}) => {
	const dispatch = useDispatch();

	const isActive = id === activeElementId;

	return (
		<div
			data-filter-id={id}
			onClick={() => { dispatch(setActiveElementId(isActive ? null : id)); }}
			className={classnames("filter-panel-element", {
				"filter-panel-element--active": isActive,
			})}
		>
			{placeholder ? (
				<span className='text-with-placeholder'>
					<span className='text-with-placeholder__text'>{title}</span>
					<span className='text-with-placeholder__placeholder'>{placeholder}</span>
				</span>
			) : <span>{title}</span>}
			{icon || arrowIconDownGray}
		</div>
	)
}

export default memo(FilterPanelElement);