// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export var white = "#fff";
export var black = "#000";
export var gray = "#666";
export var red = "#fc0d1b";
export var gray10 = "#f9fafb";
export var gray20 = "#f2f2f5";
export var gray30 = "#e7e7ec";
export var gray40 = "#d9d9df";
export var gray50 = "#c7c7cd";
export var gray60 = "#b0b0b6";
export var gray70 = "#939499";
export var gray80 = "#727276";
export var gray90 = "#4c4c4f";
export var gray95 = "#3c3c43";
export var gray100 = "#262626";
export var gray105 = "#939393";
export var accent = "--accent";
export default ___CSS_LOADER_EXPORT___;
