import {connect} from 'react-redux';
import {getAfisha} from 'app/actions/AfishaActions';
import {getNews, resetNews} from 'app/actions/NewsActions';
import {getAfishaToday} from 'app/selectors/AfishaSelectors';
import {getCurrentCinemaId, getCurrentCityId, getRoutePrefix} from 'app/selectors/AppSelectors';
import {getIsFetching, getNewsSelector, getTotalCount, getOffset} from 'app/selectors/NewsSelectors';
import NewsComponent from 'app/components/NewsComponent';
import {withRouter} from 'react-router-dom';

const mapStateToProps = state => ({
	cinemaId: getCurrentCinemaId(state),
	cityId: getCurrentCityId(state),
	isFetching: getIsFetching(state),
	afisha: getAfishaToday(state),
	news: getNewsSelector(state),
	totalCountNews: getTotalCount(state),
	offsetNews: getOffset(state),
	prefix: getRoutePrefix(state)
});

export default connect(mapStateToProps, {getAfisha, getNews, resetNews})(withRouter(NewsComponent));
