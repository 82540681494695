import { ReduxTypes } from "app/store/redux";
import {
	FILTER_PANEL_ELEMENTS,
	FilterValueType,
	IFiltersState,
	MULTISELECTABLE_FILTERS,
	SortTypes
} from "app/modules/filters/types";
import * as constants from "app/modules/filters/redux/constants";
import * as actions from "app/modules/filters/redux/actions";

const initialState: IFiltersState = {
	selectedValues: {
		[FILTER_PANEL_ELEMENTS.AGE]: null,
		[FILTER_PANEL_ELEMENTS.FEATURES]: null,
		[FILTER_PANEL_ELEMENTS.GENRE]: null,
		[FILTER_PANEL_ELEMENTS.CINEMA]: null,
		[FILTER_PANEL_ELEMENTS.PUSHKIN_CARD]: false,
		[FILTER_PANEL_ELEMENTS.SORTING]: SortTypes.DEFAULT,
	},
	localSelectedValues: {
		[FILTER_PANEL_ELEMENTS.AGE]: null,
		[FILTER_PANEL_ELEMENTS.FEATURES]: null,
		[FILTER_PANEL_ELEMENTS.GENRE]: null,
		[FILTER_PANEL_ELEMENTS.CINEMA]: null,
		[FILTER_PANEL_ELEMENTS.PUSHKIN_CARD]: false,
		[FILTER_PANEL_ELEMENTS.SORTING]: SortTypes.DEFAULT,
	},
	page: null,
	acriveElementId: null
};

export default function (
	state: IFiltersState = initialState,
	action: ReturnType<ReduxTypes.InferValueTypes<typeof actions>>
): IFiltersState {
	switch (action.type) {
		case constants.SET_ACTIVE_ELEMENT_ID: {
			return {
				...state,
				acriveElementId: action.payload,
				localSelectedValues: state.selectedValues
			}
		}
		case constants.SET_CURRENT_PAGE: {
			return {
				...state,
				page: action.payload
			}
		}
		case constants.APPLY_FILTERS: {
			const {originFilter, filtersKeys} = action.payload;

			let originField: "localSelectedValues" | "selectedValues";
			let targetField: typeof originField;

			if (originFilter === FilterValueType.LOCAL) {
				originField = "localSelectedValues"
				targetField = "selectedValues"
			} else {
				targetField = "localSelectedValues"
				originField = "selectedValues"
			}

			const newValues = {...state[targetField]};
			filtersKeys.forEach((filterKey) => {
				if (Object.hasOwn(state[originField], filterKey)) {
					newValues[filterKey] = state[originField][filterKey]
				}
			});

			return {
				...state,
				[targetField]: newValues
			}
		}
		case constants.UPDATE_FILTER_VALUE: {
			const {filterKey, value, valueType, maxValuesCount} = action.payload;
			const stateField = valueType === FilterValueType.LOCAL ? "localSelectedValues" : "selectedValues";

			let newFilterValue: any = value?.id || value;

			if (value && MULTISELECTABLE_FILTERS.includes(filterKey as typeof MULTISELECTABLE_FILTERS[number])) {
				newFilterValue = {
					...(state[stateField][filterKey] as IFiltersState["selectedValues"]["ageRatings"])
				}

				if (Object.hasOwn(newFilterValue, value.id)) {
					delete newFilterValue[value.id];

					if (Object.keys(newFilterValue).length === 0) {
						newFilterValue = null;
					}
				} else if (maxValuesCount === Object.keys(newFilterValue).length + 1) {
					newFilterValue = null;
				} else {
					newFilterValue[value.id] = value
				}
			}

			if (filterKey === FILTER_PANEL_ELEMENTS.PUSHKIN_CARD) {
				newFilterValue = !state[stateField].pushkinCard
			}

			return {
				...state,
				[stateField]: {
					...state[stateField],
					[filterKey]: newFilterValue
				}
			}
		}
		case constants.RESET_FILTERS: {
			const {filtersKeys, type} = action.payload;
			const filterField = type === FilterValueType.LOCAL ? "localSelectedValues" : "selectedValues"

			return {
				...state,
				[filterField]: {
					...state[filterField],
					..._.pick(initialState[filterField], filtersKeys)
				}
			}
		}
		default: return state;
	}
}
