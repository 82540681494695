import React, {lazy, memo, useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import parse from 'html-react-parser';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import classNames from 'classnames';

import Menu from 'app/containers/MenuContainer';
import Header from 'app/containers/HeaderContainer';
import FondKinoHeader from 'app/containers/FondKinoHeaderContainer';
import FooterContainer from 'app/containers/FooterContainer';
import PopupContainer from 'app/containers/PopupContainer';
import {MOBILE_MAX_SIZE, __DEV__, TABLET_MAX_SIZE} from 'app/constants/AppConstants';
import {getBusinessDay, isFondKino} from 'app/selectors/AppSelectors';
import {formatMoment, loadComponent, setYaReachGoal} from 'app/selectors/Helpers';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import {VREMYAKINO_CINEMA_ID} from 'app/constants/CinemaConstants';
import {AppRoutes} from 'app/components/AppRoutes';
import isNumeric from 'validator/es/lib/isNumeric';
import {getAnalyticsRequest, appendYaBase} from 'app/actions/AnalyticsActions';
import Refund from 'app/containers/ResendEmailPopupContainer';
import { AppComponentProps } from 'app/containers/AppContainer';
import { useMobileViewBreakPoint, usePrevious } from 'app/hooks';
import { Alert } from 'app/types/common';

const Advertisements = loadComponent(lazy(() => import('app/containers/AdvertisementsContainer')));
const ViewContainer = loadComponent(lazy(() => import('app/containers/ViewContainer')));
const SmartBannerContainer = loadComponent(lazy(() => import('app/containers/SmartBannerContainer')));

const location = window.location;
const isPreview = location.search === '?preview';
const isRefund = location.pathname.includes('/refund');

export default memo(function AppComponent({
	alert, cinema, currentCinemaId, cityId,
	getAboutCinema,
	getAdvertisements,
	resetAdvertisements,
	getAppData,
	handleHidePopup, setCurrentCityId,
	isSmartBannerShown,
	menuLinks, setIsMobile,
	showPopup, isSemiblind,
	isSeveralCinemasInCity,
	currentCinemas, isKinokassa,
	getFondKinoAppData,
	updateCurrentCinema,
	getCustomPagesRequest,
	resetCurrentCinemaInfo,
	resetPages, getPromos,
	resetPromos, getNews,
	resetNews, offsetNews,
	isMonitor, setIsUserAuth,
	setIsWidgetInKioskMode,
	branding, cities,
	getQualifiers, setCountUnreadNotifications,
	withNewDesign,
	hasAdvertisementsList,
}: AppComponentProps) {
	const prevBranding = usePrevious(branding);
	const prevAlert = usePrevious(alert);
	const prevCurrentCinemas = usePrevious(currentCinemas);
	const prevCityId = usePrevious({cityId});
	const prevCurrentCinemaId = usePrevious({currentCinemaId});

	const backgroundRef = useRef<HTMLAnchorElement | null>(null);

	const isTablet = useMobileViewBreakPoint(TABLET_MAX_SIZE);
	const {isMobileView} = useMobileViewBreakPoint(MOBILE_MAX_SIZE);

	const brandingEnabled = !_.isEmpty(branding) && branding.enabled;

	useEffect(() => {
		const pathCityId = location.pathname.split('/')[1];
		const isUserAuth = localStorage.getItem('isUserAuth');
		const isWidgetInKioskMode = localStorage.getItem('isWidgetInKioskMode');

		if (typeof isUserAuth === 'string') {
			setIsUserAuth(JSON.parse(isUserAuth));
		}

		if (typeof isWidgetInKioskMode === 'string') {
			setIsWidgetInKioskMode(JSON.parse(isWidgetInKioskMode));
		}

		if (pathCityId && isNumeric(pathCityId)) {
			setCurrentCityId(+pathCityId);
		}

		if (!__DEV__ && !isKinokassa) {
			appendYaBase();
		}
	}, []);

	useEffect(() => {
		window.addEventListener('message', setAuthStatus);
		window.addEventListener('message', setCounterNotifications);
		window.addEventListener('message', setWidgetKioskModeStatus);
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', setAuthStatus);
			window.removeEventListener('message', setWidgetKioskModeStatus);
			window.removeEventListener('message', setCounterNotifications);
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	useEffect(() => {
		setIsMobile(isMobileView);
	}, [isMobileView]);

	useLayoutEffect(() => {
		if (prevBranding !== branding && !_.isEmpty(branding) && branding.enabled) {
			if (branding?.bg_color) document.body.style.backgroundColor = branding.bg_color;

			if (backgroundRef.current) {
				backgroundRef.current['style']["backgroundImage"] = `url(${branding.img_url})`
			}
		}
	}, [branding]);

	useEffect(() => {
		if (alert && prevAlert !== alert && !cinema.isKinokassa) {
			const popupContentNode = getPopupContentNode(alert);

			showPopup({
				popupContentNode,
				withCloseButton: false
			});
		}
	}, [alert, cinema]);

	useEffect(() => {
		if (!__DEV__ && !isKinokassa &&
			((!prevCurrentCinemas?.length && currentCinemas.length) || (prevCityId?.cityId && cityId !== prevCityId?.cityId))
		) {
			const currentCinemasIds = currentCinemas.map((cinemaObj) => cinemaObj.id);

			getAnalyticsRequest(currentCinemasIds);
		}
	}, [isKinokassa, currentCinemas, cityId]);

	useEffect(() => {
		const request = !currentCinemaId
			? getFondKinoAppData() as unknown as ReturnType<ReturnType<typeof getFondKinoAppData>>
			: getAppData(currentCinemaId) as unknown as ReturnType<ReturnType<typeof getAppData>>;

		request.then((data) => {
			if (!data) return;

			const {cityId} = data;

			handleGeneralRequests(cityId);
		});
	}, []);

	useEffect(() => {
		if ((prevCityId?.cityId && cityId !== prevCityId.cityId) ||
			(prevCurrentCinemaId?.currentCinemaId && currentCinemaId !== prevCurrentCinemaId?.currentCinemaId)
		) {
			resetCurrentCinemaInfo();
			resetAdvertisements();
			resetPages();
			resetPromos();
			resetNews();
			updateCurrentCinema(currentCinemaId, cityId);
			handleGeneralRequests(cityId);
		}
	}, [cityId, currentCinemaId]);

	const handleGeneralRequests = useCallback((cityId: number) => {
		const afishaRoutes = ['/', `/${cityId}`, `/${cityId}/`];

		if (!afishaRoutes.includes(location.pathname)) {
			const today = formatMoment(getBusinessDay());

			getQualifiers(today, cityId);
		};

		getPromos(currentCinemaId, cityId);
		getNews(currentCinemaId, cityId, offsetNews);
		getCustomPagesRequest(currentCinemaId, cityId);

		if (!isKinokassa) {
			getAdvertisements(currentCinemaId, cityId);
		}

		if (currentCinemaId) {
			getAboutCinema(currentCinemaId);
		}
	}, [currentCinemaId, offsetNews]);

	function setAuthStatus({data: {isUserLoggedIn}}) {
		if (isUserLoggedIn !== undefined && isUserLoggedIn !== null) {
			localStorage.setItem('isUserAuth', isUserLoggedIn);
			setIsUserAuth(JSON.parse(isUserLoggedIn));
		}
	}

	function setWidgetKioskModeStatus({data: {isKioskMode}}) {
		if (isKioskMode !== undefined) {
			localStorage.setItem('isWidgetInKioskMode', isKioskMode);
			setIsWidgetInKioskMode(isKioskMode);
		}
	}

	function setCounterNotifications({data: {countUnreadNotifications}}: {data: {countUnreadNotifications: string}}) {
		if (countUnreadNotifications) {
			localStorage.setItem('countUnreadNotifications', countUnreadNotifications);
			setCountUnreadNotifications(JSON.parse(countUnreadNotifications));
		}
	}

	function getPopupContentNode({title, subtitle}: Alert) {
		return (
			<div className="popup__content">
				<div className="popup__content__header">
					{title}
				</div>
				<div className="popup__notification">
					{parse(subtitle)}
				</div>
				<div className="popup__content__bottom" style={{justifyContent: 'center'}}>
					<KeydownEnterButton
						tabIndex={1}
						className="button button--accent"
						onClick={handleHidePopup}
					>
						{i18n.t('Close')}
					</KeydownEnterButton>
				</div>
			</div>
		);
	};

	function handleMessage({data: {reachGoal, params}}) {
		if (reachGoal) {
			setYaReachGoal(reachGoal, params || {});
		}
	};

	return (
		<BrowserRouter>
			<div
				className={classNames('app', {
					'app--with-branding': brandingEnabled && !isSemiblind
				})}
			>
				{brandingEnabled && !isRefund &&
					<a
						className={classNames('branding g-mobile-hide', {
							'branding--hide': isSemiblind
						})}
						ref={backgroundRef}
						href={branding.link || '#'}
						target={branding.new_tab ? '_blank' : '_self'}
						rel="noreferrer"
					/>
				}
				{isSemiblind && <ViewContainer />}
				{isRefund
					? (
						<Switch>
							<Route component={Refund} />
						</Switch>
					) : (
						<div className="wrapper">
							{!isPreview && isSmartBannerShown && (!isFondKino || isMonitor) && <SmartBannerContainer />}
							{withNewDesign
								? !isTablet.isMobileView ? <Header.Kinokassa /> : <Header.Mobile />
								: isFondKino || cinema.id === VREMYAKINO_CINEMA_ID ? <FondKinoHeader /> : <Header.Main />
							}
							{!withNewDesign && hasAdvertisementsList && !isSemiblind ? <Advertisements /> : null}
							{!withNewDesign ? <Menu.Navigation links={menuLinks} /> : null}
							<AppRoutes
								cinema={cinema}
								isSeveralCinemasInCity={isSeveralCinemasInCity}
								isMonitor={isMonitor}
								cities={cities}
								cityId={cityId}
								isKinokassa={isKinokassa}
							/>
							{withNewDesign ? <FooterContainer.Kinokassa /> : <FooterContainer.Main />}
						</div>
					)
				}
				<PopupContainer />
			</div>
		</BrowserRouter>
	);
});
