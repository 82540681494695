import {createAction} from 'redux-actions';
import httpRequestPromise from 'app/httpRequestPromise';
import * as actions from 'app/constants/AppConstants';
import {KINOKASSA_API_URL} from 'app/constants/SettingsConstants';

const getNewsRequest = createAction(
	actions.GET_NEWS_REQUEST,
);

const getNewsSuccess = createAction(
	actions.GET_NEWS_SUCCESS,
	(news, totalCount, offset) => ({news, totalCount, offset}),
);

const getNewsFail = createAction(
	actions.GET_NEWS_FAIL,
	error => ({error}),
);

const getNewsItemRequest = createAction(
	actions.GET_NEWS_ITEM_REQUEST,
);

const getNewsItemSuccess = createAction(
	actions.GET_NEWS_ITEM_SUCCESS,
	newsItem => ({newsItem}),
);

const getNewsItemFail = createAction(
	actions.GET_NEWS_ITEM_FAIL,
	error => ({error}),
);

export const resetNews = createAction(
	actions.RESET_NEWS,
);

export const getNews = (cinemaId, cityId, offset, params) => (dispatch) => {
	if (!offset) {
		dispatch(getNewsRequest());
	}

	return httpRequestPromise(`
		${KINOKASSA_API_URL}/api/v2/articles?
		${cinemaId ? `cinema_id=${cinemaId}` : `city_id=${cityId}`}
		&offset=${offset}
		&limit=${params && params.isOtherNews ? actions.COUNT_OTHER_NEWS_PAGE : ''}
		${params && params.isImax ? '&is_imax=true' : ''}
		${params && params.isVip ? '&is_vip=true' : ''}
	`, 'GET')
		.then((response) => {
			const {articles, total_count: totalCount} = response;

			dispatch(getNewsSuccess(
				articles,
				totalCount,
				offset,
			));
		})
		.catch(error => dispatch(getNewsFail(error)));
};

export const getNewsItem = (newsId) => (dispatch) => {
	dispatch(getNewsItemRequest());

	return httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/article/${newsId}`, 'GET')
		.then((response) => {
			dispatch(getNewsItemSuccess(response));
		})
		.catch(error => dispatch(getNewsItemFail(error)));
};
