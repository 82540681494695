import { setSeanceDisplaySettings } from 'app/actions/AfishaActions';
import * as actionsTypes from 'app/constants/AppConstants';
import { KINOKASSA_API_URL } from 'app/constants/SettingsConstants';
import httpRequestPromise from 'app/httpRequestPromise';
import { createAction } from 'redux-actions';

const getReleaseRequest = createAction(
	actionsTypes.GET_RELEASE_REQUEST,
);

const getReleaseSuccess = createAction(
	actionsTypes.GET_RELEASE_SUCCESS,
	payload => (payload),
);

export const setReleaseError = createAction(
	actionsTypes.SET_RELEASE_ERROR,
	error => ({ error }),
);

export const resetReleaseItemState = createAction(
	actionsTypes.RESET_RELEASE_ITEM_STATE,
);

export const getRelease = (releaseId, cityId) => async (dispatch) => {
	dispatch(getReleaseRequest());

	try {
		if (releaseId && cityId) {
			const {
				release, cinemas, calendar, seance_display_settings: seanceDisplaySettings
			} = await httpRequestPromise(`${KINOKASSA_API_URL}/api/v2/release/${releaseId}?city_id=${cityId}`, 'GET');

			dispatch(setSeanceDisplaySettings({ seanceDisplaySettings }));
			dispatch(getReleaseSuccess({ release, cinemas, calendar }));
		}
	} catch (error) {
		dispatch(setReleaseError(error));
	}
};
