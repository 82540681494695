import React from 'react';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Spinner from 'app/components/SpinnerComponent';
import {AdvertisementsPropType} from 'app/constants/AppConstants';
import {setYaReachGoal} from 'app/selectors/Helpers';
import {closeIcon} from 'app/constants/IconsConstants';

export default class AdvertisementsComponent extends React.Component {
	static propTypes = {
		advertisements: AdvertisementsPropType,
		setAdvertisements: PropTypes.func.isRequired,
		showPopup: PropTypes.func.isRequired,
		hidePopup: PropTypes.func.isRequired
	}
	state = {
		loaded: false,
		currentAdvertisementIndex: 0
	}

	componentDidMount() {
		const {advertisements} = this.props;
		if (advertisements.length) {
			setYaReachGoal(
				'banner-show',
				{
					bannerID: advertisements[0].id,
					bannerTitle: advertisements[0].title
				},
			);
		}
	}

	componentDidUpdate(_prevProps, prevState) {
		const {loaded} = this.state;

		if (prevState.loaded !== loaded) {
			this.props.setAdvertisements({isLoaded: loaded});
		}
	}

	handleImageClick = (items) => {
		const current = this._imageGallery.getCurrentIndex();
		const image = items[current];
		if (image.url !== null) {
			window.open(image.url);
		}
	}

	handleSlideChange = (index) => {
		const {advertisements} = this.props;

		setYaReachGoal(
			'banner-show',
			{
				bannerID: advertisements[index].id,
				bannerTitle: advertisements[index].title
			},
		);

		this.setState({
			currentAdvertisementIndex: index
		});
	}

	handleAboutAdvertiserClick = () => {
		const {advertisements, showPopup, hidePopup} = this.props;
		const {currentAdvertisementIndex} = this.state;
		this._imageGallery.pause();

		const onClosePopup = () => {
			this._imageGallery.play();
			hidePopup();
		};

		showPopup({
			popupContentNode: (
				<div className="advertisements-popup">
					<div className="advertisements-popup__header">
						<h5 className="advertisements-popup__title">{i18n.t('InformationAboutAdvertiser')}</h5>
						<div
							className="popup__exit" onClick={onClosePopup}
						>
							{closeIcon}
						</div>
					</div>
					<div className="advertisements-popup__content">
						{parse(advertisements[currentAdvertisementIndex].advertiser)}
					</div>
				</div>
			),
			withCloseButton: false,
			onClosePopup
		});
	}

	renderAboutAdvertiserLink = () => {
		const {advertisements} = this.props;
		const {currentAdvertisementIndex} = this.state;

		return advertisements[currentAdvertisementIndex] && advertisements[currentAdvertisementIndex].advertiser ? (
			<button
				className="slider__about-advertiser-link" onClick={this.handleAboutAdvertiserClick}
			>
				<span>{i18n.t('Advertisement')}</span>
				<img src="/img/checkmark-warning.svg" alt="checkmark" />
			</button>
		) : null;
	}

	render() {
		const {advertisements} = this.props;
		const {loaded} = this.state;
		const style = loaded ? '' : 'hidden';

		return (
			<div className="slider">
				{ loaded ? this.renderAboutAdvertiserLink() : <Spinner banners /> }
				<ImageGallery
					/* eslint-disable-next-line id-length */
					ref={i => this._imageGallery = i}
					items={advertisements}
					showFullscreenButton={false}
					showPlayButton={false}
					showThumbnails={false}
					showBullets={advertisements.length > 1}
					autoPlay
					slideInterval={10000}
					lazyLoad
					preventDefaultTouchmoveEvent
					defaultImage={'/img/placeholder.svg'}
					onImageLoad={() => this.setState({loaded: true})}
					onClick={() => this.handleImageClick(advertisements)}
					additionalClass={style}
					onSlide={this.handleSlideChange}
				/>
			</div>
		);
	}
}
