import {createSelector} from 'reselect';
import {getCurrentCinemas} from 'app/selectors/AppSelectors';

export const getIsFetching = state => state.promoReducer.isFetching;
export const getPromosList = state => state.promoReducer.promos;
export const getPromoItemContent = state => state.promoReducer.promoItem;

export const getPromosWithCinemas = createSelector(
	[getPromosList, getCurrentCinemas],
	(promos, cinemas) => cinemas.length > 1
		? _.map(promos, promo => ({
			...promo,
			cinemaTitles: _.reduce(cinemas, (memo, cinema) => {
				if (_.contains(promo.cinema_ids, cinema.id)) {
					memo.push(cinema.title);
				}

				return memo;
			}, []).join(', ')
		}))
		: promos,
);
