import { useSelector } from 'react-redux';
import { getFilteredAfishaLength, getGroupedSortedAndFilteredAfisha } from 'app/selectors/AfishaSelectors';
import { getSelectedFiltersToDisplaying } from 'app/modules/filters/redux/selectors';
import AfishaErrorContainer from 'app/containers/errors/AfishaErrorContainer';
import FiltersWrapper from 'app/modules/filters/components/FiltersWrapper';
import NoSeancesComponent from 'app/components/NoSeancesComponent';
import SpinnerComponent from 'app/components/SpinnerComponent';
import AfishaAdditionalSections from 'app/components/afisha/AfishaAdditionalSections';
import React from 'react';
import GroupOfReleases from 'app/components/afisha/GroupOfReleases';
import { NoSeancesReason } from 'app/types/seances';

const GroupedAfishaViewComponent = ({
	page,
	isFetching,
	seanceDates,
	isImax,
	isVip,
	handleChangeDate,
}: {
	page: string;
	isFetching: boolean;
	seanceDates: string[];
	isImax: boolean | undefined;
	isVip: boolean | undefined;
	handleChangeDate: (date: string) => void;
}) => {

	const afisha = useSelector(getGroupedSortedAndFilteredAfisha);
	const isFiltersActive  = useSelector(getSelectedFiltersToDisplaying).length;
	const afishaLength = useSelector(getFilteredAfishaLength);

	return (
		<AfishaErrorContainer>
			<div className="page">
				<FiltersWrapper
					onDateChange={handleChangeDate}
					seanceDates={seanceDates}
					isDisabled={isFetching}
				/>
				<div className="releases-list">
					{
						!isFetching ? (
							<div className="container">
								{
									afishaLength > 0 ? (
										Object.entries(afisha).map(([groupKey, releasesOfGroup]) => releasesOfGroup.length ? (
											<GroupOfReleases
												key={groupKey}
												groupKey={groupKey}
												isImax={isImax}
												isVip={isVip}
												page={page}
												releasesOfGroup={releasesOfGroup}
											/>
										) : null)
									) : <NoSeancesComponent
											type={
												isFiltersActive
													? NoSeancesReason.NO_SEANCES_FOR_SELECTED_FILTERS
													: NoSeancesReason.DEFAULT
											}
										/>
								}
							</div>
						) : <SpinnerComponent />
					}
				</div>
				<AfishaAdditionalSections page={page} />
			</div>
		</AfishaErrorContainer>
	)
};

export default GroupedAfishaViewComponent;