import { IMenu } from "app/types/common";
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import Menu from 'app/containers/MenuContainer';
import CitySelect from 'app/containers/CitySelect/CitySelectContainer';
import ReleaseItem from 'app/components/release_item/ReleaseItem';
import {CSSProperties, useState} from "react";
import {useLocation} from "react-router";
import cn from 'classnames';

interface IProps {
	isCitySelectShown: boolean,
	menuLinks: IMenu[],
	hasAdvertisementsList: boolean,
	isSemiblind: boolean,
}

function MobileHeader({ isCitySelectShown, menuLinks, hasAdvertisementsList, isSemiblind }: IProps) {
	const [poster, setPoster] = useState('');
	const isReleasePage = useLocation().pathname.includes('release');
	const isNeedAdvertisements = !isReleasePage && hasAdvertisementsList && !isSemiblind;

	return (
		<header
			id="header"
			className={cn('header-mobile kassa-container', {'header-mobile--full': isReleasePage || isNeedAdvertisements})}
			style={{'--poster-url': `url(${poster})`} as CSSProperties}
		>
			<div className="header-mobile__logo">
				<KeydownEnterLink
					type="plain"
					href="/"
					className="header__logo"
					rel="noopener noreferrer"
				>
					<img className="header__logo-img" src="/img/logo.svg" alt={i18n.t('Logo')} />
				</KeydownEnterLink>
				<Menu.Mobile links={menuLinks} />
			</div>
			{isCitySelectShown && <CitySelect.Kinokassa isDesktopView={false} />}
			{isReleasePage ? <ReleaseItem setPoster={(poster) => setPoster(poster)} /> : null}
		</header>
	)
}

export default MobileHeader;
