import {connect} from 'react-redux';
import FeedbackComponent from 'app/components/FeedbackComponent';
import {addFeedback} from 'app/actions/FeedbackActions';
import {CINEMA_ID} from 'app/constants/SettingsConstants';
import {getCinema, getCurrentCityTitle, getIsMonitor} from 'app/selectors/AppSelectors';

const mapStateToProps = (state) => ({
	cinemaId: CINEMA_ID || getCinema(state).id,
	isMonitor: getIsMonitor(state),
	cityTitle: getCurrentCityTitle(state)
});

export default connect(mapStateToProps, {addFeedback})(FeedbackComponent);
