import React, {useEffect, useRef, useState, CSSProperties} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import { IMenu } from 'app/types/common';
import classNames from 'classnames';
import MenuItem from 'app/components/header/MenuItem';
import FeedbackContainer from 'app/containers/FeedbackContainer';
import { Portal } from 'react-portal';
import { useSelector } from 'react-redux';
import { getWithNewDesign } from 'app/selectors/AppSelectors';

const amountVisibleMenuItem = 4;

interface IProps {
	location: {
		pathname: string,
	},
	links: IMenu[],
	feedback: boolean,
	isSeveralCinemasInCity: boolean,
}

function Menu({links, feedback}: IProps & RouteComponentProps) {
	const withNewDesign = useSelector(getWithNewDesign);
	const menuList = useRef<HTMLDivElement>(null);
	const burgerRef = useRef<HTMLDivElement>(null);
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [positionMenu, setPositionMenu] = useState<{x?: string, y?: string}>({});

	const isShowBurger = links.length > amountVisibleMenuItem;

	useEffect(() => {
		const handleClose = (event) => {
			if (isOpenMenu && menuList.current && !menuList.current.contains(event.target)) {
				setIsOpenMenu(false);
			}
		};

		document.addEventListener('onclick', handleClose);
		document.addEventListener('scroll', handleClose);

		return () => {
			document.removeEventListener('onclick', handleClose);
			document.removeEventListener('scroll', handleClose);
		};
	}, [isOpenMenu]);

	useEffect(() => {
		if (burgerRef.current) {
			const {bottom, right} = burgerRef.current?.getBoundingClientRect();
			const MENU_WIDTH = 280;
			const MARGIN_SIZE = 8;

			setPositionMenu({
				x: `${right - MENU_WIDTH}px`,
				y: `${bottom + MARGIN_SIZE}px`,
			});
		}
	}, [isShowBurger, isOpenMenu]);

	const toggleMenu = () => setIsOpenMenu(prev => !prev);
	const handleCloseMenu = () => setIsOpenMenu(false);

	const renderAdditionalMenu = (links: IMenu[], positionMenu) => {
		return (
			<div style={{'--posX': positionMenu.x, '--posY': positionMenu.y} as CSSProperties} className={classNames('main-nav__sub popover', {
				'popover--open': isOpenMenu,
			})}>
				{links.slice(amountVisibleMenuItem).map((link) => {
					return link.sub && link.sub.length
						? (
							<div className="main-nav__group-item" key={_.uniqueId(link.href)}>
								<span className="text text--size-12 text--inner main-nav__group-title">
									{link.title}
								</span>
								<MenuItem
									link={link}
									onClick={handleCloseMenu}
									className="main-nav__sub-item main-nav__sub-item--group"
								/>
							</div>
						)
						: <MenuItem link={link} key={_.uniqueId(link.href)} className="main-nav__sub-item" onClick={handleCloseMenu} />
				})}
				{feedback && !withNewDesign && <FeedbackContainer />}
			</div>
		);
	};

	return (
		<nav className="main-nav menu--navigation">
			{isOpenMenu && <div className="popup-overlay" onClick={handleCloseMenu} />}
			<div className="menu-container" ref={menuList}>
				{links.slice(0, amountVisibleMenuItem).map((link, index) => (
					<MenuItem link={link} key={index} className="main-nav__item" onClick={handleCloseMenu}/>
				))}
				{isShowBurger ? (
						<div className="main-nav__additional" ref={burgerRef}>
							<button className={classNames('burger-button', {'burger-button--open': isOpenMenu})} onClick={toggleMenu}>
								<span></span>
							</button>
							{isOpenMenu
								? (
									<Portal>
										{renderAdditionalMenu(links, positionMenu)}
									</Portal>
								) : null}
						</div>
					) : null}
			</div>
		</nav>
	);
}

export default withRouter(Menu);
