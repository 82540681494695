import React, {Fragment, lazy} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import CitySelect from 'app/containers/CitySelect/CitySelectContainer';
import Feedback from 'app/containers/FeedbackContainer';
import SocialLinksComponent from 'app/components/SocialLinksComponent';
import FondKinoPhonesComponent from 'app/components/FondKino/FondKinoPhonesComponent';
import {setYaReachGoal, loadComponent} from 'app/selectors/Helpers';
import {
	AddressesPropTypes,
	CinemaPropType,
	PhonesPropTypes,
	SocialsPropTypes,
	MONITOR_SUPPORT_EMAIL,
	KINOMIR_SUPPORT_EMAIL,
} from 'app/constants/AppConstants';
import LoginButton from 'app/containers/LoginButtonContainer';
import RefundButton from 'app/components/refund/RefundButton';

const LanguageSelector = loadComponent(lazy(() => import('app/components/LanguageSelector')));

class FondKinoHeaderComponent extends React.Component {
	static propTypes = {
		cinema: CinemaPropType.isRequired,
		isMobile: PropTypes.bool.isRequired,
		addresses: AddressesPropTypes.isRequired,
		phones: PhonesPropTypes.isRequired,
		socials: SocialsPropTypes.isRequired,
		isSemiblind: PropTypes.bool.isRequired,
		setSemiblind: PropTypes.func.isRequired,
		setFontSize: PropTypes.func.isRequired,
		isSeveralCinemasInCity: PropTypes.bool.isRequired,
		isCitySelectShown: PropTypes.bool.isRequired,
		isFeedbackEnabled: PropTypes.bool.isRequired,
		currentCityId: PropTypes.number.isRequired,
		history: PropTypes.shape({
			push: PropTypes.func.isRequired
		}).isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired
		}).isRequired,
		isMonitor: PropTypes.bool.isRequired,
		isCharlie: PropTypes.bool.isRequired,
		isKinomir: PropTypes.bool.isRequired,
		cityTitle: PropTypes.string.isRequired
	}

	state = {
		openMenu: false,
		isOpenedPhoneMenu: false,
	}

	componentDidUpdate(prevProps) {
		const {history, currentCityId, location, isMonitor} = this.props;

		if (prevProps.currentCityId !== currentCityId && location.pathname !== `/${!isMonitor ? currentCityId : ''}`) {
			history.push('/');
		}
	}

	handleClickMenu = (event) => {
		if (event.currentTarget.dataset.sub) {
			event.preventDefault();
			event.stopPropagation();

			const isSubMenu = event.currentTarget.classList.contains('header-menu-sub__link');
			const element = isSubMenu ? event.target.closest('.header-menu__link') : event.currentTarget;

			if (element.classList.contains('header-menu__link--active')) {
				element.classList.remove('header-menu__link--active');
			} else {
				element.classList.add('header-menu__link--active');
			}
		} else {
			this.setState({
				openMenu: !this.state.openMenu
			});
		}
	}

	handleIconClick = (event) => {
		const {icon} = event.currentTarget.dataset;

		setYaReachGoal(`${icon}-click`);
	}

	handleSemiblindClick = () => {
		const {setSemiblind, setFontSize} = this.props;

		localStorage.setItem('colorTheme', 'blackOnWhite');
		localStorage.setItem('showImages', 'imageOn');
		localStorage.setItem('fontSize', '16');
		localStorage.setItem('fontType', 'noSerif');
		localStorage.setItem('lineHeight', 'line_1_5');
		localStorage.setItem('letterSpacing', 'letter-spacing1');

		setSemiblind(true);
		setFontSize(16);
	};

	renderCity = () => (
		<div className="header__city">
			<div className="header__text">
				<span className="header__text-title">{i18n.t('City')}</span>
				<span className="header__text-addition">{this.props.cityTitle}</span>
			</div>
		</div>
	)

	renderAddress = () => {
		const {cinema: {address}, addresses: {place, maps}} = this.props;

		return (
			<div className="header__address">
				<KeydownEnterLink
					type="plain"
					className="header__link"
					href={maps}
					target="_blank"
					rel="noopener noreferrer"
					data-icon="maps"
					onClick={this.handleIconClick}
				>
					<div className="header__text">
						{place && <span className="header__text-title">{place}</span>}
						<span className="header__text-addition">{address}</span>
					</div>
				</KeydownEnterLink>
			</div>
		);
	}

	renderSocials = () => {
		const {socials} = this.props;

		return !_.isEmpty(socials) && (
			<SocialLinksComponent
				className="header__social"
				linksData={socials}
			/>
		);
	}

	renderSupportEmail = () => {
		const email = this.props.isMonitor ? MONITOR_SUPPORT_EMAIL : KINOMIR_SUPPORT_EMAIL;

		return (
			<div className="header__email">
				<KeydownEnterLink
					type="plain"
					className="header__link"
					href={`mailto:${email}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="header__text">
						<span className="header__text-title">{i18n.t('TechnicalSupportLong')}</span>
						<span className="header__text-addition">{email}</span>
					</div>
				</KeydownEnterLink>
			</div>
		);
	}

	render() {
		const {openMenu} = this.state;
		const {
			isMobile,
			isFeedbackEnabled,
			isSemiblind,
			isSeveralCinemasInCity,
			isCitySelectShown,
			phones,
			isMonitor,
			isCharlie,
			isKinomir,
		} = this.props;
		const monitorOrKinomir = isMonitor || isKinomir;

		const menuIconClass = classNames('header-menu__icon', {
			'header-menu__icon--active': openMenu
		});
		const menuNavigationClass = classNames('header-menu__navigation', {
			'header-menu__navigation--open': openMenu
		});

		return (
			<header className="header" id="header">
				<div className="container header__container">
					<KeydownEnterLink
						type="plain"
						href="/"
						className="header__logo"
						rel="noopener noreferrer"
					>
						<img className="header__logo-img" src="/img/logo.svg" alt={i18n.t('Logo')} />
					</KeydownEnterLink>
					{isCitySelectShown && monitorOrKinomir && <CitySelect.Default isDesktopView={!isMobile} />}
					{!isSeveralCinemasInCity && (
						<>
							{this.renderAddress()}
							{!isMobile && !monitorOrKinomir && <FondKinoPhonesComponent phones={phones} />}
							{!isMobile && monitorOrKinomir && this.renderSupportEmail()}
							{this.renderSocials()}
						</>
					)}
					{isSeveralCinemasInCity && !isMobile && isKinomir && this.renderSupportEmail()}
					{isSeveralCinemasInCity && (monitorOrKinomir || isCharlie) && this.renderSocials()}
					{!isMobile &&
						<div className="feedback__item__container">
							<RefundButton linkClassName="feedback__item return_ticket__button"/>
							<LanguageSelector />
						</div>
					}
					{isCitySelectShown && !monitorOrKinomir && <CitySelect.Default isDesktopView={!isMobile} />}
					<div className="login-wrapper">
						{isMobile ? <LoginButton /> : null}
						<KeydownEnterButton
							className="header__menu-toggle-btn header-menu"
							onClick={this.handleClickMenu}
						>
							<span className={menuIconClass} />
						</KeydownEnterButton>
					</div>
				</div>
				<div id="headerMenu" className={menuNavigationClass}>
					{this.renderCity()}
					{!isSeveralCinemasInCity && (
						<>
							{this.renderAddress()}
							<FondKinoPhonesComponent phones={phones} />
						</>
					)}
					{isFeedbackEnabled && <Feedback />}
					<div className="feedback__item__container">
						<RefundButton
							classNamesOfContainers={["feedback"]}
							linkClassName="feedback__item return_ticket__button"
						/>
						<LanguageSelector />
					</div>
					{this.renderSocials()}
					{!isSemiblind && (
						<div className="header__view">
							<KeydownEnterButton
								className="view-button"
								onClick={this.handleSemiblindClick}
							>
								{i18n.t('VersionForTheVisuallyImpaired')}
							</KeydownEnterButton>
						</div>
					)}
				</div>
			</header>
		);
	}
}

export default withRouter(FondKinoHeaderComponent);
