import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/MenuConstants';

const initialState = {
	customPages: [],
	pageContent: {},
	isFetching: true
};

export default handleActions({
	[actions.SET_PAGE_IS_FETCHING]: state => ({
		...state,
		isFetching: true
	}),
	[actions.SET_CUSTOM_PAGES]: (state, {payload}) => ({
		...state,
		customPages: payload
	}),
	[actions.SET_CUSTOM_PAGE_CONTENT]: (state, {payload}) => ({
		...state,
		pageContent: payload,
		isFetching: false
	}),
	[actions.RESET_PAGES]: () => initialState
}, initialState);
