import React, { useEffect, useState } from "react";
import { FilterPanelElementsType } from "../types";
import classnames from 'classnames';

export const DesktopFilterPopup = ({
	activeElementId,
	pupupContent,
	containerRef,
	title,
} : {
	activeElementId: FilterPanelElementsType;
	containerRef: React.MutableRefObject<HTMLDivElement | null>;
	pupupContent: React.ReactNode;
	title?: string | null;
}) => {
	const [popupStyles, setPopupStyles] = useState<{right?: number; left?:number, maxWidth?: number}>({});

	useEffect(() => {
		const currentFilter = containerRef.current?.querySelector<HTMLElement>(`[data-filter-id=${activeElementId}]`);

		if (containerRef.current && currentFilter) {
			const containerRect = containerRef.current.getBoundingClientRect();
			const elementRect = currentFilter.getBoundingClientRect();

			const left = elementRect.left - containerRect.left;
			const right = containerRect.right - elementRect.right;

			setPopupStyles(right < left ? {
				right: right + containerRef.current.offsetLeft,
				maxWidth: left + currentFilter.offsetWidth
			} : {
				left: left + containerRef.current.offsetLeft,
				maxWidth: right + currentFilter.offsetWidth
			});
		}
	}, [activeElementId]);

	return (
		<div
			className={classnames("filter-popup", {
				"filter-popup--hidden": !popupStyles.left && !popupStyles.right
			})}
			onClick={(e) => e.stopPropagation()}
			style={popupStyles}
		>
			{title && <div className="filter-popup__title">{title}</div>}
			{pupupContent}
		</div>
	)
}