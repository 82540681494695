import { IInitialForm, IValidation } from "app/types/refund";
import { EMAIL_REGEXP, VALIDATION } from "app/constants/refund";
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'app/constants/AppConstants';
import { parsePhone } from "app/selectors/Helpers";

export const getErrorFields = (form: IInitialForm<string>) => Object.keys(form).reduce((
	memo: { [key: string]: IValidation<boolean>[] },
	field: string,
) => {
	if (!VALIDATION()[field]) return {};

	VALIDATION()[field].forEach(validation => {
		const isValidField = validation.isValid(form[field]);

		if (!isValidField) {
			const errorItem = {
				isValid: validation.isValid(form[field]),
				message: validation.message,
			};

			memo[field] = memo.hasOwnProperty(field)
				? [...memo[field], errorItem]
				: [errorItem];
		}
	});

	return memo;
}, {});

export const getTextByNotFound = (value: string) => {
	const isEmail = value && EMAIL_REGEXP.test(value);
	const subtext = isEmail ? 'по номеру телефона' : 'по адресу электронной почты';
	const typeContact = isEmail ? 'email' : 'телефон';

	return (
		<>
			{i18n.t('WeDoNotFoundTicketsByAddress')}.<br />
			{i18n.t('CheckContacts', { typeContact })}<br />
			{i18n.t('FindTicketsByContacts', { subtext })}.
		</>
	);
};

export const getTextBySend = (value: string) => (
	<>
		{i18n.t('TicketsAreSentTo')} {value}.<br />
		{i18n.t('IfTicketsAreMissingCheckSpam')}<br />
		{i18n.t('or')} {i18n.t('contactTechnicalSupport')}:&nbsp;
		<a href={`tel:${parsePhone(SUPPORT_PHONE)}`}>{SUPPORT_PHONE}</a>&nbsp;
		{i18n.t('additionalShort')}. 220<br />
		{i18n.t('or')}&nbsp;
		<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
	</>
);

export const transformGosuslugiScript = (script: string) => script.replace(/\x3C[/]?script>/gi, "");