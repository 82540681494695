import advertisementsReducer from 'app/reducers/advertisementsReducer';
import afishaReducer from 'app/reducers/afishaReducer';
import appReducer from 'app/reducers/appReducer';
import dailySeancesReducer from 'app/reducers/dailySeancesReducer';
import emailResendReducer from 'app/reducers/emailResendReducer';
import feedbackReducer from 'app/reducers/feedbackReducer';
import menuReducer from 'app/reducers/menuReducer';
import newsReducer from 'app/reducers/newsReducer';
import promoReducer from 'app/reducers/promoReducer';
import releaseItemReducer from 'app/reducers/releaseItemReducer';
import releaseReducer from 'app/reducers/releaseReducer';
import releasesSoonReducer from 'app/reducers/releasesSoonReducer';
import filtersReducer from 'app/modules/filters/redux/filtersReducer';
import logger from 'app/store/logger';
import { applyMiddleware, combineReducers, legacy_createStore } from 'redux';
import thunk from 'redux-thunk';

const middlewares = [
	thunk
];

if (process.env.NODE_ENV !== 'production') {
	middlewares.push(logger);
}

const rootReducer = combineReducers({
	appReducer,
	advertisementsReducer,
	afishaReducer,
	dailySeancesReducer,
	releasesSoonReducer,
	releaseReducer,
	releaseItemReducer,
	newsReducer,
	feedbackReducer,
	menuReducer,
	promoReducer,
	emailResendReducer,
	filtersReducer
});

function configureStore(initialState = {}) {
	return legacy_createStore(
		rootReducer,
		initialState,
		applyMiddleware(...middlewares),
	);
}

export default configureStore();
