import { closeIcon } from 'app/constants/IconsConstants';
import classnames from 'classnames';

interface IProps<T> {
	title?: string;
	size?: "small" | "normal";
	classes?: string;
	isActive?: boolean;
	withCloseIcon?: boolean;
	value: T;
	onClick: (value: T) => void;
}

export function FilterValue<T> ({
	title,
	classes,
	isActive,
	withCloseIcon,
	onClick,
	size,
	value,
} : IProps<T>) {
	return (
		<div
			className={classnames("filter-value", {
				[`filter-value--${size}`]: size,
				"filter-value--active": isActive,
				[`${classes}`]: classes
			})}
			onClick={() => onClick(value)}
		>
			<span className='filter-value__title'>{title}</span>
			{withCloseIcon && closeIcon}
		</div>
	)
}