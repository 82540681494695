import React, { useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import ReleasesSoon from 'app/containers/ReleasesSoonContainer';
import ReleaseScheduleComponent from 'app/components/ReleaseScheduleComponent';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import Spinner from 'app/components/SpinnerComponent';
import {changePageTitle} from 'app/actions/AppActions';
import {getTitles, getDateFromSearch, setYaReachGoal, scrollToTop} from 'app/selectors/Helpers';
import { useHistory, useParams } from 'react-router-dom';
import ReleasePoster from 'app/components/release_item/ReleasePoster';
import { useDispatch, useSelector } from 'react-redux';

import {getRelease, setReleaseError} from 'app/actions/ReleaseActions';
import {showPopup, updateSelectedDay} from 'app/actions/AppActions';
import {
	getIsFetching,
	getReleaseError,
} from 'app/selectors/ReleaseSelectors';
import {getCinema, getRoutePrefix, isMobileSelector} from 'app/selectors/AppSelectors';
import { IRelease } from 'app/types/releases';
import ReleaseQualifiersWrapper from 'app/components/ReleaseQualifiersWrapper';

interface IProps {
	release: IRelease;
	seanceDates: string[];
	cityId: number;
}

const ReleasePageComponent = ({release, seanceDates, cityId}: IProps) => {
	const dispatch = useDispatch();
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isShowPlay, setIsShowPlay] = useState(true);

	const history = useHistory();
	const {id: currentReleaseID, modifier} = useParams<{id: string, modifier?: string}>();

	const {search} = history.location;

	const isFetching = useSelector(getIsFetching);
	const cityTitle = useSelector(getCinema).city;
	const prefix = useSelector(getRoutePrefix);
	const hasReleaseError = !_.isEmpty(useSelector(getReleaseError));
	const isMobile = useSelector(isMobileSelector);
	const {
		id, rambler_id: ramblerReleaseId,
		title, age_rating: ageRating,
		genres, countries, cast,
		directors = [], trailer, rating,
		description, description_html: descriptionHTML, 
		duration, year, poster,
		cinema_start_date: cinemaStartDate,
		russia_start_date: russiaStartDate,
		all_qualifiers,
	} = release;

	useEffect(() => {
		if (search) {
			const date = getDateFromSearch(search);

			if (date) {
				dispatch(updateSelectedDay(moment(date)));
			}
		}

		scrollToTop();
	}, []);

	useEffect(() => {
		if (title) {
			changePageTitle(title);
		}
	}, [title]);

	useEffect(() => {
		dispatch(getRelease(currentReleaseID, cityId));
	}, [currentReleaseID, cityId]);

	useEffect(() => {
		if (hasReleaseError) {
			history.replace('/');
			dispatch(setReleaseError({}));
		}
	}, [hasReleaseError]);

	const playTrailer = useCallback((event) => {
		event.preventDefault();

		if (videoRef?.current?.paused) {
			setIsShowPlay(false);

			try {
				videoRef?.current?.play();
			} catch(error) {
				throw error;
			}
		} else {
			setIsShowPlay(true);

			try {
				videoRef?.current?.pause();
			} catch(error) {
				throw error;
			}
		}
	}, [videoRef]);

	const handleClickTrailer = useCallback((event) => {
		event.preventDefault();

		const popupContentNode = (
			<video poster={trailer?.thumbnail} width="775" controls autoPlay playsInline ref={videoRef}>
				<source src={trailer?.preview || trailer?.hd} type="video/mp4" />
			</video>);

		dispatch(showPopup({
			popupContentNode,
			withCloseButton: true,
		}));

		playTrailer(event);

		setYaReachGoal('open-trailer', {
			movie: title,
			release_id: id,
			city: cityTitle,
		});
	}, [videoRef, title, id, cityTitle]);

	const directorsString = getTitles(directors);
	const filteredCast = cast ? _.chain(cast).pluck('title').uniq().value().join(', ') : [];

	return (
		!isFetching && id ? (
			<div className="main">
				<div className="breadcrumbs">
					<div className="breadcrumbs-container container">
						<KeydownEnterLink
							to={`${prefix}/${modifier ? `${modifier}/afisha` : ''}`}
							className="breadcrumbs__link"
						>
							{i18n.t('SeancesSchedule')}
						</KeydownEnterLink>
						<span className="breadcrumbs__divider">»</span>
						<span className="breadcrumbs__page">{title}</span>
					</div>
				</div>
				<div className="release">
					<div className="container release-container">
						<div className="release-left">
							{!isMobile ? <ReleasePoster
								poster={poster}
								qualifiers={all_qualifiers}
								isShowAgeRating={!!ageRating}
								ageRating={ageRating}
								withBadges
							/> : null}
							{rating &&
								<div className="release-rating">
									{rating.kinopoisk &&
										<div className="release-rating__item">
											<div className="release-rating__title">{i18n.t('Kinopoisk')}</div>
											<div className="release-rating__count">{(rating.kinopoisk).toFixed(2)}</div>
										</div>
									}
									{rating.imdb &&
										<div className="release-rating__item">
											<div className="release-rating__title">IMDB</div>
											<div className="release-rating__count">{(rating.imdb).toFixed(2)}</div>
										</div>
									}
								</div>
							}
						</div>
						<div className="release-right">
							{isMobile ? <ReleasePoster poster={poster}/> : null}
							<div className="release-description">
								{!!genres.length && <div className="release__genre">{getTitles(genres)}</div>}
								{title && <div className="release__title">{title}</div>}
								<ReleaseQualifiersWrapper
									classes='release__badge release__badge--mobile'
									qualifiers={all_qualifiers}
									withProCultureBadge
								/>
								{ageRating && <div className="release-description__age">{ageRating}</div>}
							</div>
							{seanceDates.length ?
								<ReleaseScheduleComponent
									releaseId={parseInt(currentReleaseID, 10)}
									ramblerReleaseId={ramblerReleaseId}
									seanceDates={seanceDates}
									modifier={modifier}
								/>
								:
									<div className="release__soon-date">
										{i18n.t('AtCinemaFrom', {
											date: moment(cinemaStartDate || russiaStartDate).format('D MMMM YYYY'),
										})}
									</div>
							}
							{trailer?.preview || trailer?.hd
								? (
									<div>
										<h2 className="releases-list__title g-desktop-hide">{i18n.t('Trailer')}</h2>
										<div className="release-trailer-list">
											<div onClick={handleClickTrailer} className="release-trailer-item">
												<>
													<video poster={trailer?.thumbnail} width="775" muted>
														<source src={trailer?.preview || trailer?.hd} type="video/mp4" />
													</video>
													{isShowPlay ? <div className="release-trailer-play">
														<img src="/img/play.svg" width={30} height={34} />
													</div> : null}
												</>
											</div> 
										</div>
									</div>
								) : null}
							<h2 className="releases-list__title g-desktop-hide">{i18n.t('AboutMovie')}</h2>
							<div className="release__info">
								{directorsString &&
									<div className="release__info-item">
										<span className="release__info-item__name">{i18n.t('Director')}</span>
										<span className="release__info-item__description">
											{directorsString}
										</span>
									</div>
								}
								{!!cast.length &&
									<div className="release__info-item">
										<span className="release__info-item__name">{i18n.t('Cast')}</span>
										<span className="release__info-item__description">
											{filteredCast}
										</span>
									</div>
								}
								{duration &&
									<div className="release__info-item">
										<span className="release__info-item__name">{i18n.t('Timing')}</span>
										<span className="release__info-item__description">{duration} {i18n.t('min')}.</span>
									</div>
								}
								{!!countries.length &&
									<div className="release__info-item">
										<span className="release__info-item__name">{i18n.t('Country')}</span>
										<span className="release__info-item__description">{getTitles(countries)}</span>
									</div>
								}
								{year &&
									<div className="release__info-item">
										<span className="release__info-item__name">{i18n.t('Year')}</span>
										<span className="release__info-item__description">{year}</span>
									</div>
								}
							</div>
							<div className="release__text">
								<div className="release__info-item__name g-desktop-hide">{i18n.t('Description')}</div>
								{descriptionHTML ? parse(descriptionHTML) : description}
							</div>
						</div>
					</div>
				</div>
				<ReleasesSoon page="release" />
			</div>
		) : <Spinner />
	);
}

export default ReleasePageComponent;
