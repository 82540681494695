import React, {useState} from 'react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import KeydownEnterButton from 'app/components/KeydownEnterButtonComponent';
import FondKinoPhoneItem from 'app/components/FondKino/FondKinoPhoneItemComponent';
import {arrowIconDownGray} from 'app/constants/IconsConstants';
import {PhonesPropTypes} from 'app/constants/AppConstants';

const FondKinoPhonesComponent = ({phones}) => {
	const [isPhonesListOpen, toggleOpen] = useState(false);

	if (!phones.length) {
		return null;
	}

	if (phones.length === 1) {
		return (
			<div className="header__phones-wrapper">
				<FondKinoPhoneItem value={phones[0].value} comment={phones[0].comment} />
			</div>
		);
	}

	const [firstPhone, ...otherPhones] = phones;
	const {value: firstPhoneValue, comment: firstPhoneComment} = firstPhone;
	const phonesListClassName = classNames('header__phones-select-list', {
		'header__phones-select-list--open': isPhonesListOpen
	});

	const togglePhonesList = () => {
		toggleOpen(!isPhonesListOpen);
	};

	const handlePhonesListOutsideClick = () => {
		if (isPhonesListOpen) {
			toggleOpen(false);
		}
	};

	return (
		<OutsideClickHandler onOutsideClick={handlePhonesListOutsideClick}>
			<div className="header__phones-wrapper">
				<FondKinoPhoneItem value={firstPhoneValue} comment={firstPhoneComment} />
				<KeydownEnterButton
					className="header__phone-arrow"
					onClick={togglePhonesList}
				>
					{arrowIconDownGray}
				</KeydownEnterButton>
				<div className={phonesListClassName}>
					{otherPhones.map(({comment, value}, index) => (
						<FondKinoPhoneItem key={index} value={value} comment={comment} />
					))}
				</div>
			</div>
		</OutsideClickHandler>
	);
};

FondKinoPhonesComponent.propTypes = {
	phones: PhonesPropTypes.isRequired
};

export default FondKinoPhonesComponent;
