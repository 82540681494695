import React from 'react';
import classNames from 'classnames'; 
import CitiesListSelect from 'app/containers/CitySelect/CitiesListSelectContainer';

interface IProps {
	className?: string;
	isOpen: boolean;
}

const CitiesListSelectPopup = ({className = '', isOpen}: IProps) => (
	<div className={classNames('popup__content', className, {'popup__content--open': isOpen})}>
		<CitiesListSelect />
	</div>
);

export default CitiesListSelectPopup;
