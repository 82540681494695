import { memo, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { transformGosuslugiScript } from 'app/utils/utils';
import { gosuslugiOnAfishaData } from 'app/constants/AppConstants';

interface IProps {
	pathname: string
}

const GosuslugiAfishaComponent = ({
	pathname
}: IProps) => {
	const [template, setTemplate] = useState('');

	useEffect(() => {
		const style = document.createElement("style");

		style.innerHTML = `
			.main--gosuslugi-afisha {
				min-height: 375px;
			}

			.bf-104__decor {
				backface-visibility: hidden;
			}
		`;

		document.body.appendChild(style);

		const gosuslugiBaseScript = document.createElement('script');
		const gosuslugiSecondScript = document.createElement('script');
		const gosuslugiThirdScript = document.createElement('script');

		const { getScript, getTemplate, gosuslugiID } = gosuslugiOnAfishaData || {};

		getTemplate?.().then((module) => {
			setTemplate(module.default);
		}).then(() => {
			getScript?.().then((module) => {
				gosuslugiBaseScript.src = "https://pos.gosuslugi.ru/bin/script.min.js";
				document.body.appendChild(gosuslugiBaseScript);

				gosuslugiSecondScript.innerHTML = transformGosuslugiScript(module.default);
				document.body.appendChild(gosuslugiSecondScript);

				gosuslugiBaseScript.onload = () => {
					gosuslugiThirdScript.innerHTML = `Widget("https://pos.gosuslugi.ru/form", ${gosuslugiID});`;
					document.body.appendChild(gosuslugiThirdScript);
				}
			})
		})

		return () => {
			[
				gosuslugiBaseScript,
				gosuslugiSecondScript,
				gosuslugiThirdScript
			].forEach((currentScript) => {
				if (document.body.contains(currentScript)) {
					document.body.removeChild(currentScript);
				}
			});

			document.body.removeChild(style);

			window.removeEventListener('resize', (window as any).gosuslugiChangePosBannerOnResize)
		}
	}, []);

	if (pathname === '/afisha' || pathname === '/') {
		return (
			<div className="main main--gosuslugi-afisha">
				<div className="container content">
					{parse(template)}
				</div>
			</div>
		);
	};

	return null;
};

export default memo(GosuslugiAfishaComponent);