import {handleActions} from 'redux-actions';
import * as actions from 'app/constants/AppConstants';

const initialState = {
	news: [],
	newsItem: {
		id: null
	},
	totalCount: 0,
	offset: 0,
	error: {},
	isFetching: false
};

export default handleActions({
	[actions.GET_NEWS_REQUEST]: state => ({
		...state,
		isFetching: true,
		error: {}
	}),

	[actions.GET_NEWS_SUCCESS]: (state, action) => ({
		...state,
		news: _.uniq([...state.news, ...action.payload.news], 'id'),
		isFetching: false,
		totalCount: action.payload.totalCount,
		offset: action.payload.offset,
		error: {}
	}),

	[actions.GET_NEWS_FAIL]: (state, action) => ({
		...state,
		isFetching: false,
		error: action.payload.error
	}),

	[actions.GET_NEWS_ITEM_REQUEST]: state => ({
		...state,
		isFetching: true,
		error: {}
	}),

	[actions.GET_NEWS_ITEM_SUCCESS]: (state, action) => ({
		...state,
		newsItem: action.payload.newsItem,
		isFetching: false,
		error: {}
	}),

	[actions.GET_NEWS_ITEM_FAIL]: (state, action) => ({
		...state,
		isFetching: false,
		error: action.payload.error
	}),
	[actions.RESET_NEWS]: () => initialState
}, initialState);
