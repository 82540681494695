import { DailySeance, ICinemaInfo, TitleWithId } from "app/types/common";

export interface IRelease {
	id: number,
	rambler_id: number,
	title: string,
	age_rating: string,
	formats: TitleWithId[],
	genres: TitleWithId[],
	countries: TitleWithId[],
	cast: TitleWithId[],
	directors: string[],
	trailer: {
		preview: string,
		thumbnail: string,
		hd: string,
	},
	rating: {
		kinopoisk: number,
		imdb: number,
	},
	description: string,
	year: number,
	poster: string,
	thumbnail: string,
	duration: number,
	qualifiers: string[],
	russia_start_date: string,
	cinema_start_date: string,
	cinema_next_date: string,
	repertoire_start_date: string,
	all_qualifiers: Qualifiers[],
	seances: DailySeance[],
	description_html: string,
	screenshots: string[],
}

export interface IReleaseReducer {
	calendar: {
		seance_dates: string[],
	},
	cinemas: ICinemaInfo[],
	release: IRelease,
	error: Record<string, never>,
	isFetching: boolean,
}

export const enum Qualifiers {
	premiere = 'premiere',
	closed_show = 'closed_show',
	stellar_premiere = 'stellar_premiere',
	special_show = 'special_show',
	for_kids = 'for_kids',
	pro_culture = 'pro_culture',
	alternative = 'alternative',
	custom = 'custom',
}

export type GroupsOfAfishaReleases = 'premiere' | 'family' | 'comedy' | 'children' | 'horrorAndThrillers' | 'action' | 'drama' | 'notGrouped';

export type GroupedAfishaType = Record<GroupsOfAfishaReleases, IRelease[]>;

export enum GenresIDSforAfishaGroups {
	ACTION = 3,
	DRAMA = 9,
	COMEDY = 12,
	FAMILY = 24,
	TRILLER = 27,
	FEAR = 28,
}
