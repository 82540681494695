import React, { SyntheticEvent, memo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import {arrowIconDownWhite} from 'app/constants/IconsConstants';
import KeydownEnterLink from 'app/components/KeydownEnterLinkComponent';
import PhoneNumber from 'app/components/contacts/PhoneNumber';
import {parsePhone} from 'app/selectors/Helpers';
import { Phone } from "app/types/common";
import { ENTER_KEY_CODE } from 'app/constants/AppConstants';

interface IProps {
	oldPhones?: string[],
	newPhones: Phone[],
	isMobileMenu?: boolean,
	withNewDesign: boolean,
	onClick: (event: SyntheticEvent) => void,
}

function Phones({
	oldPhones,
	newPhones,
	isMobileMenu = false,
	withNewDesign,
	onClick
}: IProps) {
	const [isOpenedPhonesMenu, setIsOpenedPhonesMenu] = useState(false);

	const handleClickPhoneMenu = () => {
		setIsOpenedPhonesMenu(prevState => !prevState);
	}

	const handleKeyDownPhoneMenu = (event) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			handleClickPhoneMenu();
		}
	}

	const handlePhoneMenuOutsideClick = () => {
		if (isOpenedPhonesMenu) {
			setIsOpenedPhonesMenu(false);
		}
	}

	const renderOldPhoneNumbers = () => {
		return oldPhones && oldPhones.length ? (
			<div className="phone">
				{_.map(oldPhones, (phone, index) => (
					<KeydownEnterLink
						type="plain"
						href={`tel:${parsePhone(phone)}`}
						key={index}
						data-icon="phone"
						className="text text--link-accent text--size-12"
						onClick={onClick}
					>
						{phone}
					</KeydownEnterLink>
				))}
			</div>
		) : null;
	}

	const renderNewPhoneNumbers = () => {
		return !!newPhones.length && !isMobileMenu ? (
			<OutsideClickHandler onOutsideClick={handlePhoneMenuOutsideClick}>
				<div className="phones__subinfo">
					{!!newPhones[0].value &&
						<PhoneNumber
							phoneNumber={newPhones[0].value}
							comment={newPhones[0].comment}
						/>
					}
					<div
						tabIndex={0}
						className={`${newPhones[1].value || newPhones[2].value ?
							'phones__arrow' : 'phones__list-hide'
						}`}
						onClick={handleClickPhoneMenu}
						onKeyDown={handleKeyDownPhoneMenu}
					>
						{arrowIconDownWhite}
					</div>
				</div>
				<div
					className={`ui-select-content--bottom phones__list${isOpenedPhonesMenu ? '' : '-hide'}`}
				>
					{_.map(newPhones, (phone, key) => (
						!!phone.value &&
							<div key={key} className="ui-select-option">
								<PhoneNumber
									phoneNumber={phone.value}
									comment={phone.comment}
									isPhoneList
								/>
							</div>
					))}
				</div>
			</OutsideClickHandler>
		) : (
			<>
				{_.map(newPhones, (phone, key) => (
					!!phone.value 
						? <PhoneNumber
							key={key}
							phoneNumber={phone.value}
							comment={phone.comment}
						/> : null
				))}
			</>
		);
	}

	return (
		<div className="phones">
			{newPhones.length ? renderNewPhoneNumbers() : renderOldPhoneNumbers()}
			{withNewDesign
				? <span className="text text--size-12 text--gray">{i18n.t('CallAtNoCost')}</span>
				: null}
		</div>
	)
}

export default memo(Phones);
